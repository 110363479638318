import BaseService from '../BaseService/index'

class Auth extends BaseService {
  constructor() {
    super()
    this.init()
  }

  init () {
    this.endPoint = 'api/login'
  }

  async theLogin(params = {}) {
    // https://api.amithyainstitute.com/api/login
    this.endPoint = 'api/login'
    return this.get(params)
  }

  async getYogie() {
    return 'Yogie'
  }

  login (username, password) {
    const a = this.post({
      email: username,
      password: password
    })
    console.log('a',a)
    return a
    
    // return this.post({
    //   client_id: config.credentials.clientID,
    //   client_secret: config.credentials.clientSecret,
    //   grant_type: 'password',
    //   username,
    //   password
    // })
  }

  isLoggedIn() {
    
  }

  logout () {
    this.endPoint = 'api/logout'
    window.localStorage.clear()
    window.location.reload(true)
    this.post({})
    // router.push('/login')
  }
}

export default new Auth()
