import React, {Component} from 'react';

import { Uploader } from 'rsuite';

class UploadFile extends Component{
  render(){
    // http://amithya-institute-api/api/loremUpload
    // const theOpts = {
    //   'Accept': '*/*' 
    // }
    return (
      <>
        {/*
        <Uploader 
          action="http://amithya-institute-api/api/loremUpload" 
        />;
        */}

        <Uploader 
          action="https://api.amithyainstitute.com/api/loremUpload"
        />
      </>
    );
  }
}

export default UploadFile;