import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
// import Appme from './Appme';
import Appyog from './Appyog';
import * as serviceWorker from './serviceWorker';
// import { BrowserRouter } from "react-router-dom";


// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// old
// ReactDOM.render(
//   <React.StrictMode>
//     <Appyog />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
ReactDOM.render(
  <Appyog />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
