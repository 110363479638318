import BaseService from '../BaseService/index'

class SubModules extends BaseService {
  // constructor() {
  //   super()
  // }

   async getSubModules(params = {}) {
     // this.endPoint = 'users'
     this.endPoint = 'api/submodules'
     return this.get(params)
   }

   async createSubModules(data: any) {
     // console.log('createUser ==> data',data)
     // this.endPoint = 'auth/register'
     this.endPoint = 'api/submodules'
     return this.post(data)
   }

   async deleteSubModules(id) {
     // console.log(id)
     // `http://localhost:3000/movies/${movie.id}`;
     this.endPoint = 'api/submodules'
     return this.delete(id)
   }

   async updateSubModules(id: any, data: any) {
     // this.endPoint = 'users'
     this.endPoint = 'api/submodules'
     return this.putOne(id, data)
   }

   // // new
   async getOneSubModules(id: any, params= {}) {
     this.endPoint = `api/submodules`
     return this.getOne(id,params)
   }
}

export default new SubModules()
