import React, { Component } from 'react';
import { 
  Table,
  Grid,
  Row,
  Col,
  Panel,
  Icon,
  IconButton
} from 'rsuite';

import './MyTable.css';

const { 
  Column, 
  HeaderCell, 
  Cell, 
  // Pagination 
} = Table;

class MyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theColumn: [
        {
          "colName": "Id",
          "colDataKey": "id",
          "colWidth": 70,
        },
        {
          "colName": "Id",
          "colDataKey": "id",
          "colWidth": 70,
        },
        {
          "colName": "Id",
          "colDataKey": "id",
          "colWidth": 70,
        },
        {
          "colName": "Id",
          "colDataKey": "id",
          "colWidth": 70,
        },
        {
          "colName": "Id",
          "colDataKey": "id",
          "colWidth": 70,
        },
      ]
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  theClick(id) {
    // this.setState({ show: true });
    console.log('id',id)
    // this.props.parentMethod();
  }

  // bukan ES2015 , kalau pake ini jadi tidak 
  // perlu nge bind di constructor
  // click = () => {
  //   this.props.parentMethod();
  // }

  // Bind in Constructor (ES2015)
  handleDelete(id) {
    // this.setState({ show: true });
    // console.log('id',id)
    // this.props.parentFunc();
    this.props.deleteMethod(id);
    // console.log('cvc')
  }

  handleEdit(id) {
    // this.setState({ show: true });
    // console.log('id',id)
    // this.props.parentFunc();
    this.props.editMethod(id);
    // console.log('cvc')
  }


  render() {
    // console.log('props',this.props)
    return (
      <div className="containerTable">
        <Grid fluid>
          <Row className="">
            <Col xs={24}>
              <Panel header={this.props.title} shaded bordered>
                <Table
                  height={400}
                  // data={this.state.data}
                  data={this.props.fill}
                  // onRowClick={data => {
                  //   console.log(data);
                  // }}
                >
                  <Column width={70} align="center">
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id" />
                  </Column>

                  <Column width={200} >
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email" />
                  </Column>

                  <Column width={200}>
                    <HeaderCell>NIM</HeaderCell>
                    <Cell dataKey="nim" />
                  </Column>
                  
                  <Column width={120}>
                    <HeaderCell>Action</HeaderCell>

                    <Cell>
                      {rowData => {
                        // function handleAction() {
                        //   alert(`id:${rowData.id}`);
                        // }
                        return (
                          <span>
                              <IconButton
                                appearance="ghost"
                                color="violet"
                                icon={
                                  <Icon
                                    className="fill-color" 
                                    icon="pencil" 
                                  />
                                } 
                                placement="left" 
                                size="xs"
                                // onClick={() => {this.theClick(rowData.id)}}
                                // onClick={this.myClick}
                                onClick={() => {this.handleEdit(rowData.id)}}
                                // onClick={() => this.props.parentMethod('Hello from child')}
                              >
                              </IconButton>
                            {/*
                            <a onClick={handleAction}> 
                            </a> */}{' '}|{' '}
                              <IconButton
                                appearance="ghost"
                                color="violet"
                                icon={
                                  <Icon
                                    className="fill-color" 
                                    icon="trash" 
                                  />
                                } 
                                placement="left" 
                                size="xs"
                                onClick={() => {this.handleDelete(rowData.id)}}
                                // onClick={handleAction}
                              >
                              </IconButton>
                            {/*
                            <a onClick={handleAction}>
                            </a>
                            */}
                          </span>
                        )
                      }}
                    </Cell>
                  </Column>
                </Table>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

}

export default MyTable;
