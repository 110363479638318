import React from "react"
import { isAuthenticated } from "./auth"

// import { testa } from "./theAuth/theAuth";
// import find from "lodash/find"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
// import { Grid,Row,Col,Container, 
//   Header, Content, 
//   Nav, Icon, 
//   Navbar, 
//   // Sidebar, 
//   // Footer, Sidenav , 
//   // Dropdown, 
//   // IconButton, Avatar 
// } from 'rsuite';

// import SideMenu from '../SideMenu/SideMenu'

import HelloWorld from './pages/HelloWorld/HelloWorld';
import AppPage from './pages/AppPage/AppPage';
import HalloPage from './pages/HalloPage/HalloPage';
import Login2 from './pages/Login2/Login2';

import Dashboard from './pages/Dashboard/Dashboard';
import About from './pages/About/About';
// import DashboardStudent from './pages/Dashboard/DashboardStudent/DashboardStudent';

import Users from './pages/Users/Users';
import Modules from './pages/Modules/Modules';
import SubModules from './pages/SubModules/SubModules';
import Rooms from './pages/Rooms/Rooms';
import Schedules from './pages/Schedules/Schedules';
import Presences from './pages/Presences/Presences';
import MaterialFile from './pages/MaterialFile/MaterialFile';
import ClassRecord from './pages/ClassRecord/ClassRecord'
import AdminStudentTask from './pages/AdminStudentTask/AdminStudentTask'
import AdminDashboard from './pages/AdminDashboard/AdminDashboard'

import JoinRoom from './pages/JoinRoom/JoinRoom';
import UploadFile from './pages/UploadFile/UploadFile';
import UploadFile2 from './pages/UploadFile2/UploadFile2';
import UploadFile3 from './pages/UploadFile3/UploadFile3';
import ConferenceSchedule from './pages/ConferenceSchedule/ConferenceSchedule';

// student
import StudentPermissionForm from './pages/StudentPermissionForm/StudentPermissionForm';
import StudentSchedules from './pages/StudentSchedules/StudentSchedules';
import StudentUploadTask from './pages/StudentUploadTask/StudentUploadTask';
import StudentSubject from './pages/StudentSubject/StudentSubject';



// import CourseMaterials from './pages/CourseMaterials/CourseMaterials';
// import FileCourses from './pages/FileCourses/FileCourses';

// import NotFound from './pages/NotFound/NotFound';

// import LayoutRsuite from './hoc/LayoutRsuite/LayoutRsuite';

// const PrivateRoute = ({ component: Component, ...rest }) => (

//   <Route

//     {...rest}

//     render = { (props) =>
//       isAuthenticated() ? 
//       (
//         <Component {...props} />
//       ) : 
//       (
//         <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
//       )
//     }
    
//     // render = { ThePrivateRoute }

//   />

// );

// const theRoles = {
//   "superadmin": {
//     "menu": ['/dashboard', '/users', '/rooms'],
//     "default": "/dashboard"
//   },
//   "admin": {
//     "menu": ['/dashboard'],
//     "default": "/dashboard"
//   },
//   "student": {
//     "menu": ['/dashboard'],
//     "default": "/dashboard"
//   },
//   "lecture": {
//     "menu": ['/dashboard'],
//     "default": "/dashboard"
//   },
//   "operator": {
//     "menu": ['/dashboard'],
//     "default": "/dashboard"
//   }
// }

const PrivateRoute = ({ component: Component, role, ...rest }) => {
  // console.log('inside private route => ',role)
  
  // let vvd = "virgil"
  // let roles = {
  //   "superadmin": {
  //     "menu": ['/dashboard', '/users', '/rooms'],
  //     "default": "/dashboard"
  //   },
  //   "admin": {
  //     "menu": ['/dashboard'],
  //     "default": "/dashboard"
  //   },
  //   "student": {
  //     "menu": ['/dashboard'],
  //     "default": "/dashboard"
  //   },
  //   "lecture": {
  //     "menu": ['/dashboard'],
  //     "default": "/dashboard"
  //   },
  //   "operator": {
  //     "menu": ['/dashboard'],
  //     "default": "/dashboard"
  //   }
  // }

  return (
    <Route

      {...rest}

      render = { props => {
          if(isAuthenticated()) {
            // const role_name = localStorage.getItem("role")
            // console.log("role_name",role_name)

            // console.log(props)
            // console.log('inside private route => ',role)
            
            // console.log('inside private route props => ', props)
            // console.log('inside private route props => /// ', theRoles)
            // console.log('inside private route props => /// >>> ', localStorage.getItem("role"))
            

            
            // console.log("role",role)

            // 1. inputan dari props.location.pathname
            // 2. keluar nya role mana saja yang bisa 
            // di masuki link tsb
            // 3. lalu cek apakah role tsb sama dengan props.role
            // 4. jika tidak akan diredirect ke halaman default dari role tsb

            // // props.location.state.user_role
            // let menu = theRoles[role];
            // console.log('menu',menu)
            // let www = find(menu, function(o) {
            //   return o === props.location.pathname
            //   // console.log(o)
            //   // console.log(props.location.pathname)
            // })
            
            // // untuk pertama ga usah masuk sini
            // if(typeof menu !== "undefined") {
            //   console.log('typeof menu === "undefined"')

            //   if(typeof www !== "undefined") {
            //     console.log('typeof www === "undefined"')
            //     console.log('menu.default',menu.default)
            //     // if(menu.hasOwnProperty("default")) {
            //     //   return <Redirect to={{ pathname: menu.default, state: { from: props.location } }} />
            //     // }
            //   }

            // }


            // console.log('====||>>> ',roles[role])

            // if(role === "student" && props.location.pathname === "/users") {
            //   console.log('siniii')
            //   return <Redirect to={{ pathname: "/rooms", state: { from: props.location } }} />
            // }
            // window.location.reload(true)
            // return <Component {...props} user_role={props.location.state.user_role} />;
            return <Component {...props} />;
          } else {
            return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          }
        }

      }
      
      // render = { ThePrivateRoute }

    />
  );
};


const Routes = (props) => {
  // console.log('dipanggil terus??',props)
  // const user_access_token = localStorage.getItem("access_token")
  const user_role = localStorage.getItem("role")
  // console.log('di routes user_role',user_role)

  return (
    <BrowserRouter>
      <Switch>
        
        <Route exact path="/" component={HelloWorld} />
        <Route path="/login" component={Login2} />
        <Route path="/buangan" component={About} />
        <Route path="/conference" component={ConferenceSchedule} />

        <Route path="/upload" component={UploadFile} />
        <Route path="/upload2" component={UploadFile2} />
        <Route path="/upload3" component={UploadFile3} />
      {/*
        <PrivateRoute path="/loginupload" component={UploadFile2} />
      */}

        <PrivateRoute path="/app" component={AppPage} />
        <PrivateRoute path="/halo" component={HalloPage} />

        <PrivateRoute 
          role={user_role}
          path="/dashboard" 
          component={Dashboard} 
        />
        
     {/*
       <PrivateRoute path="/coursematerials" component={CourseMaterials} />
       <PrivateRoute path="/filecourses" component={FileCourses} />
     */}
        <PrivateRoute path="/admindashboard" role={user_role} component={AdminDashboard} />
        <PrivateRoute path="/adminusers" role={user_role} component={Users} />
        <PrivateRoute path="/adminmodules" role={user_role} component={Modules} />
        <PrivateRoute path="/adminrooms" role={user_role} component={Rooms} />
        <PrivateRoute path="/adminschedules" role={user_role} component={Schedules} />
        <PrivateRoute path="/adminsubmodules" role={user_role} component={SubModules} />
        <PrivateRoute path="/admincoursefile" role={user_role} component={MaterialFile} />
        <PrivateRoute path="/adminpresences" role={user_role} component={Presences} />
        <PrivateRoute path="/joinroom" role={user_role} component={JoinRoom} />
        <PrivateRoute path="/classrecords" role={user_role} component={ClassRecord} />
        <PrivateRoute path="/studenttask" role={user_role} component={AdminStudentTask} />
      {/*
      */}

        {/*Student*/}
        <PrivateRoute path="/permissionform" role={user_role} component={StudentPermissionForm} />
        <PrivateRoute path="/schedules" role={user_role} component={StudentSchedules} />
        <PrivateRoute path="/uploadtask" role={user_role} component={StudentUploadTask} />
        <PrivateRoute path="/subject" role={user_role} component={StudentSubject} />
        {/*
        */}

        <Route render={() => <Redirect to="/login"/>}/>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
