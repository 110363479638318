import Http from '../Http/index'
// import config from '@/configs'

// const storage = window.localStorage

class BaseService {
  endPoint: any = ''

  constructor() {
    this.endPoint = ''
  }

  includeDefault(options: Object) {
    const defaultData = {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
        Accept: 'application/json'
      }
    }
    return Object.assign(defaultData, options)
  }

  get(options = {}) {
    const opts = this.includeDefault(options)
    // console.log('opts',opts)
    return Http.get(this.endPoint, opts)
  }

  getOne(id: any, options = {}) {
    // console.log("options",options)
    const opts = this.includeDefault(options)
    // console.log("opts",opts)
    // console.log("this.endPoint",this.endPoint)

    return Http.get(this.endPoint + '/' + id, opts)
  }

  post(data: any, options = {}) {
    // console.log("options",options)
    const opts = this.includeDefault(options)
    // console.log('opts',opts)
    // console.log('data',data)
    // console.log('this.endPoint',this.endPoint)
    return Http.post(this.endPoint, data, opts)
  }

  // postOne(id: any, data: any, options = {}) {
  //   const opts = this.includeDefault(options)

  //   return Http.post(`${this.endPoint}/${id}`, data, opts)
  // }

  // bulkDeleteGlobalSetting(data: any) {
  //   const opts = this.includeDefault(data)
  //   return Http.delete(this.endPoint, opts)
  // }

  // bulkDelete_productList(data: {}) {
  //   const opts = this.includeDefault(data)
  //   return Http.delete(this.endPoint, opts)
  // }

  delete(id: any, options = {}) {
    console.log(id)
    const opts = this.includeDefault(options)

    return Http.delete(this.endPoint + '/' + id, opts)
  }

  // rawDelete(options = {}) {
  //    const opts = this.includeDefault(options)

  //    return Http.delete(this.endPoint, opts) 
  // }

  // put(data: any, options = {}) {
  //   const opts = this.includeDefault(options)

  //   return Http.put(this.endPoint, data, opts)
  // }

  putOne(id: any, data: any, options = {}) {
    const opts = this.includeDefault(options)

    return Http.put(this.endPoint + '/' + id, data, opts)
  }
}

export default BaseService
