import React, {Component} from 'react';
import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './SubModules.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
// import forEach from "lodash/forEach"
import { 
  Panel,
  Button,
  Icon,
  Grid,
  Row,
  Col,
  Modal,
  Input,
  SelectPicker,
  ButtonToolbar,
  // Pagination,
  Breadcrumb,
  Alert,
} from 'rsuite';
import forEach from "lodash/forEach"
import subModules from '../../services/SubModules/index'


class SubModules extends Component{
  constructor(props) {
    super(props);
    this.state = {
      
      modulesParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at"
      },
      modules: [],
      modulesSelected: null,

      subModulesParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at"
      },
      subModulesData: [],
      subModules: [],
      subModulesSelected: null,

      actionItem: "new",
      loadingSimpan: false,

      valueName: "",
      valueDeskripsi: "",

      coursesFilterSelected: null,
      modulesFilterSelected: null,
      submodulesFilterSelected: null,
      submodulesFilterName: "",

      filterCoursesData: [],
      filterModulesData: [],
      filterSubModulesData: [],
      coursesParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at"
      },

      subModulesChild: [],

    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.subModulesSelect = this.subModulesSelect.bind(this);
    this.subModulesSearch = this.subModulesSearch.bind(this);

    this.modulesSelect = this.modulesSelect.bind(this);
    this.modulesSearch = this.modulesSearch.bind(this);

    this.newSubModules = this.newSubModules.bind(this);
    this.simpanHandler = this.simpanHandler.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleDeskripsi = this.handleDeskripsi.bind(this);

    this.searchFilter = this.searchFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);

    this.handleSelectFilterCourses = this.handleSelectFilterCourses.bind(this);
    this.handleChangeFilterCourses = this.handleChangeFilterCourses.bind(this);
    this.handleSearchFilterCourses = this.handleSearchFilterCourses.bind(this);

    this.handleSelectFilterModules = this.handleSelectFilterModules.bind(this);
    this.handleChangeFilterModules = this.handleChangeFilterModules.bind(this);
    this.handleSearchFilterModules = this.handleSearchFilterModules.bind(this);

    this.handleSelectFilterSubModules = this.handleSelectFilterSubModules.bind(this);
    this.handleChangeFilterSubModules = this.handleChangeFilterSubModules.bind(this);
    this.handleSearchFilterSubModules = this.handleSearchFilterSubModules.bind(this);

    this.filterCoursesClean = this.filterCoursesClean.bind(this);
    this.filterModulesClean = this.filterModulesClean.bind(this);
    this.filterSubModulesClean = this.filterSubModulesClean.bind(this);
    this.handleFilterName = this.handleFilterName.bind(this);

    this.handleEditSubModules = this.handleEditSubModules.bind(this);
    this.handleDeleteSubModules = this.handleDeleteSubModules.bind(this);

    this.cekParent = this.cekParent.bind(this);
    this.cekChild = this.cekChild.bind(this);
    this.cekMap = this.cekMap.bind(this);
  }

  open(size) {
    // console.log("this.state.valueCourses",this.state.valueCourses)
    this.setState({
      size,
      show: true,
    });
  }

  close() {
    this.setState({
      show: false,

      modulesSelected: null,
      valueName: "",
      valueDeskripsi: "",
      subModulesSelected: null,
      subModulesSelectedId: null,

      // valueCourseSelected: null,
      // valueName: "",
      // valueDescription: ""
    });
  }

  componentDidMount() {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // check role
    axios.get(configs.baseEndpoint.baseURL+'api/me?include=roles', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)

        const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        const role_name = response.data.included.roles[role_id].attributes.name
        window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // this.getSubModules(this.state.subModulesParam)
  }

  searchModules() {

  }

  searchSubModules() {
    
  }

  getCourses(params) {
    // console.log("getCourses", params)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get courses
    axios.get(configs.baseEndpoint.baseURL+'api/courses', {
      headers: { 'Authorization': tok },
      params: params
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrCourses = []
        forEach(response.data.data, item => {
          let courses = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrCourses.push(courses)
        })
        this.setState({ filterCoursesData : arrCourses })
      } )
      .catch( error => {
        console.log("error",error)

        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  getModules(params) {
    // console.log("getModules", params)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get modules
    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: params
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrModules = []
        forEach(response.data.data, item => {
          let modules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrModules.push(modules)
        })
        this.setState({ modules : arrModules })
      } )
      .catch( error => {
        console.log("error",error)

        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  modulesSelect(value) {
    // console.log("modulesSelect",value)
    this.setState({ modulesSelected : value })
    let subModulesParam = this.state.subModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      "filter[module_id][is]": value
    }
    // console.log("modulesSelect",newSubModulesParam)
    this.getSubModules(newSubModulesParam)
  }

  modulesSearch(value) {
    // console.log("modulesSearch", value)
    let params = this.state.modulesParam
    let paramsUse = {
      ...params,
      "filter[name][like]": value
    }
    this.setState({ modulesParam: paramsUse})
    // console.log("modulesSearch params", paramsUse)
    this.getModules(paramsUse)
  }

  subModulesSelect(value) {
    // console.log("subModulesSelect", value)
    this.setState({ subModulesSelected: value })
    // created_at: "2020-08-04T02:14:52.000000Z"
    // description: "Deskripsi OOPD"
    // id: 3
    // level: null
    // module_id: 1
    // name: "OOPD"
    // parent_id: null
    // updated_at: "2020-08-04T02:19:25.000000Z"
  }

  subModulesSearch(value) {
    console.log("subModulesSearch", value)
  }

  getSubModules(params) {
    console.log("getSubModules",params)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: params
    })
      .then( response => {
        console.log("response sub modules",response)
        // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {
        //   const thesubmodules = {
        //     "created_at": item.attributes.created_at,
        //     "description": item.attributes.description,
        //     "id": item.attributes.id,
        //     "level": item.attributes.level,
        //     "module_id": item.attributes.module_id,
        //     "name": item.attributes.name,
        //     "parent_id": item.attributes.parent_id,
        //     "updated_at": item.attributes.updated_at
        //   }
          let subModules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrSubmodules.push(subModules)
        })
        this.setState({ subModules: arrSubmodules })

        // const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        // const role_name = response.data.included.roles[role_id].attributes.name
        // window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  handleName(value) {
    // console.log("handleName",value)
    this.setState({valueName: value})
  }

  handleDeskripsi(value) {
    this.setState({valueDeskripsi: value})
  }

  newSubModules() {
    this.setState({actionItem: "new"})
    this.open("xs")
  }

  async simpanHandler() {
    this.setState({loadingSimpan: true})

    // console.log("this.state.modulesSelected", this.state.modulesSelected)
    // console.log("this.state.subModulesSelected", this.state.subModulesSelected)
    // console.log("this.state.valueName", this.state.valueName)
    // console.log("this.state.valueDeskripsi", this.state.valueDeskripsi)

    if(this.state.actionItem === "new") {
      try {
        // Block of code to try
        const payload = {
          // for submodules
          "module_id": this.state.modulesSelected,
          "name": this.state.valueName,
          "description": this.state.valueDeskripsi,
          "parent_id": this.state.subModulesSelected,
          // for course_materials
          // "sub_modules_id":
          // "file_path":
          // "file_type": 
        }

        // sent to course_materials table
        // console.log("payload", payload)
        let responseCreate = await subModules.createSubModules(payload)
        if(responseCreate.data.success) {
          Alert.success('Tambah Berhasil.', 5000)
          this.searchFilter()
          this.close()
          // this.getSubModules(this.state.subModulesParam)
        }
      } catch(err) {
        console.log(err)
        // Alert.success('Tambah Berhasil.', 5000)
        Alert.error('Server Error.', 5000)
        // Block of code to handle errors
      }

    // const thesubmodules = {
    //   "created_at": item.attributes.created_at,
    //   "description": item.attributes.description,
    //   "id": item.attributes.id,
    //   "level": item.attributes.level,
    //   "module_id": item.attributes.module_id,
    //   "name": item.attributes.name,
    //   "parent_id": item.attributes.parent_id,
    //   "updated_at": item.attributes.updated_at
    // }

    //   console.log("payload",payload)

    //   let responseCreate = await modules.createModules(payload)
    //   console.log("responseCreate",responseCreate)
    //   if(responseCreate.data.success) {
    //     Alert.success('Tambah Berhasil.', 5000)
    //     this.close()
    //     // this.setState({ paramModules : params })
    //     console.log("this.state.paramModules", this.state.paramModules)
    //     this.getModules(this.state.paramModules)
    //   }
      
    } else {
      console.log("edit")
      try {
        // Block of code to try
        const payload = {
          // for submodules
          "module_id": this.state.modulesSelected,
          "name": this.state.valueName,
          "description": this.state.valueDeskripsi,
          "parent_id": this.state.subModulesSelected,
          // for course_materials
          // "sub_modules_id":
          // "file_path":
          // "file_type": 
        }
        console.log("payload edit => ",payload)
        console.log("this.state.subModulesSelectedId edit => ",this.state.subModulesSelectedId)
        
        let responseUpdate = await subModules.updateSubModules(this.state.subModulesSelectedId, payload)
        console.log("responseUpdate => ",responseUpdate)
        if(responseUpdate.data.success) {
          Alert.success('Edit Berhasil.', 5000)
          this.searchFilter()
          this.close()
          // this.getSubModules(this.state.subModulesParam)
        }
      } catch(err) {
        console.log(err)
        // Alert.success('Tambah Berhasil.', 5000)
        Alert.error('Server Error.', 5000)
        // Block of code to handle errors
      }
      
    }
    this.setState({loadingSimpan: false})
  }

  searchFilter() {
    // console.log("searchFilter")
    // console.log("this.state.coursesFilterSelected",this.state.coursesFilterSelected)
    // console.log("this.state.modulesFilterSelected",this.state.modulesFilterSelected)
    // console.log("this.state.submodulesFilterName", this.state.submodulesFilterName)

    // submodulesFilterSelected

    let subModulesParam = this.state.subModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      // "filter[module_id][is]": this.state.modulesFilterSelected,
      // "filter[parent_id][null]": true,
      "include": "modules"
    }

    if(this.state.modulesFilterSelected !== null) {
      newSubModulesParam["filter[module_id][is]"] = this.state.modulesFilterSelected
    }

    if(this.state.submodulesFilterSelected !== null) {
      newSubModulesParam["filter[id][is]"] = this.state.submodulesFilterSelected
    }

    // this.getSubModules(newSubModulesParam)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: newSubModulesParam
    })
      .then( response => {
        // console.log("response sub modules",response)
        // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {
        
          // check module relation
          let module_id_rel = item.relationships.modules.id
          let module_name = response.data.included.modules[module_id_rel].attributes.name

          let subModules = {
            "created_at": item.attributes.created_at,
            "description": item.attributes.description,
            "id": item.attributes.id,
            "level": item.attributes.level,
            "module_id": item.attributes.module_id,
            "name": item.attributes.name,
            "parent_id": item.attributes.parent_id,
            "updated_at": item.attributes.updated_at,

            "module_name": module_name,
          }
          arrSubmodules.push(subModules)
        })
        this.setState({ subModulesData: arrSubmodules })
        // const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        // const role_name = response.data.included.roles[role_id].attributes.name
        // window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  resetFilter() {
    // console.log("resetFilter")
    this.setState({
      coursesFilterSelected: null,
      modulesFilterSelected: null,
      submodulesFilterSelected: null
    })
  }

  // handle Filter Courses
  handleSelectFilterCourses(value) {
    console.log("handleSelectFilterCourses", value)
    this.setState({ coursesFilterSelected: value })
  }

  handleChangeFilterCourses(value) {
    console.log("handleChangeFilterCourses", value)
  }

  handleSearchFilterCourses(value) {
    // console.log("handleSearchFilterCourses", value)
    // this.state.coursesParam
    let coursesParam = this.state.coursesParam
    let filterCoursesParam = {
      ...coursesParam,
      "filter[name][like]": value
    }
    this.getCourses(filterCoursesParam)
  }

  filterCoursesClean() {
    this.setState({ coursesFilterSelected: null })
  }

  // handle Filter Modules
  handleSelectFilterModules(value) {
    console.log("handleSelectFilterModules", value)
    this.setState({ modulesFilterSelected: value })
  }

  handleChangeFilterModules(value) {
    console.log("handleChangeFilterModules", value)
  }

  handleSearchFilterModules(value) {
    // console.log("handleSearchFilterModules", value)

    let modulesParam = this.state.modulesParam
    let filterModulesParam = {
      ...modulesParam,
      "filter[course_id][is]": this.state.coursesFilterSelected,
      "filter[name][like]": value
    }
    // console.log("filterCoursesParam",filterModulesParam)
    // this.getModules(filterCoursesParam)
    
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get modules
    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: filterModulesParam
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrModules = []
        forEach(response.data.data, item => {
          let modules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrModules.push(modules)
        })
        this.setState({ filterModulesData : arrModules })
      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  filterModulesClean() {
    this.setState({ modulesFilterSelected: null })
  }

  // handle Filter SubModules
  handleSelectFilterSubModules(value) {
    // console.log("handleSelectFilterSubModules", value)
    
    this.setState({ submodulesFilterSelected: value })
  }

  handleChangeFilterSubModules(value) {
    console.log("handleChangeFilterSubModules", value)
  }

  handleSearchFilterSubModules(value) {
    console.log("handleSearchFilterSubModules", value)

    let subModulesParam = this.state.subModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      // "filter[module_id][is]": this.state.modulesFilterSelected,
      // "filter[parent_id][null]": true,
      "filter[name][like]": value,
    }

    if(this.state.modulesFilterSelected !== null) {
      newSubModulesParam["filter[module_id][is]"] = this.state.modulesFilterSelected
    }

    console.log("handleSearchFilterSubModules", newSubModulesParam)

    // this.getSubModules(newSubModulesParam)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: newSubModulesParam
    })
      .then( response => {
        console.log("response sub modules",response)
        // filterSubModulesData
        // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {

          let subModules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrSubmodules.push(subModules)
        })
        this.setState({ filterSubModulesData: arrSubmodules })

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)

        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  filterSubModulesClean() {
    this.setState({ submodulesFilterSelected: null })
  }

  handleFilterName(value) {
    // console.log("handleFilterName", value)
    this.setState({submodulesFilterName: value})
  }

  handleEditSubModules(data) {
    console.log("handleEditSubModules", data)
    console.log("handleEditSubModules Modules => ", this.state.modulesParam)
    console.log("handleEditSubModules this.state.modules => ", this.state.modules)
    
    // subModulesSelectedId

    let modulesParam = this.state.modulesParam
    let filterModulesParam = {
      ...modulesParam,
      "filter[id][is]": data.module_id
    //   "filter[course_id][is]": this.state.coursesFilterSelected,
    //   "filter[name][like]": value
    }
    
    console.log("filterModulesParam",filterModulesParam)
    
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get modules
    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: filterModulesParam
    })
      .then( response => {
        console.log("response.data.data",response.data.data)
        let arrModules = []
        forEach(response.data.data, item => {
          let modules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrModules.push(modules)
        })
        this.setState({ modules : arrModules })
      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // console.log("getSubModules",params)
    // const ac_tok = localStorage.getItem("access_token");
    // const tok = "Bearer "+ac_tok;

    let subModulesParam = this.state.subModulesParam
    let filtersubModulesParam = {
      ...subModulesParam,
      "filter[id][is]": data.parent_id
    //   "filter[course_id][is]": this.state.coursesFilterSelected,
    //   "filter[name][like]": value
    }
    console.log("filtersubModulesParam",filtersubModulesParam)


    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: filtersubModulesParam
    })
      .then( response => {
        console.log("response.data.data sub modules",response.data.data)
        // // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {

          let subModules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrSubmodules.push(subModules)
        })
        this.setState({ subModules: arrSubmodules })

      } )
      .catch( error => {
        console.log("error",error)
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    this.setState({
      actionItem: "edit",
      modulesSelected: data.module_id,
      subModulesSelected: data.parent_id,
      valueName: data.name,
      valueDeskripsi: data.description,
      subModulesSelectedId: data.id,
    })
    this.open("xs")

    // const ac_tok = localStorage.getItem("access_token");
    // const tok = "Bearer "+ac_tok;
  }

  handleDeleteSubModules(data) {
    console.log("handleDeleteSubModules", data)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;
    
    axios.delete(configs.baseEndpoint.baseURL+'/api/submodules/'+data.id, {
      headers: { 'Authorization': tok },
      params: {},
    })
      .then( response => {
        console.log("response.data",response.data)
        // console.log("this.state.materialFiles",this.state.materialFiles)
        
        let subModulesData = this.state.subModulesData

        let arrSubMod = []
        forEach(subModulesData, item => {
          if(item.id !== data.id) {
        //     // file_path: "padamu"
        //     // file_type: null
        //     // id: 7
        //     // sub_modules_id: 1
            arrSubMod.push(item)
          }

        })
        this.setState({ subModulesData : arrSubMod })
        
        if(response.data.success) {

          Alert.success(response.data.message, 5000)
        }

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
      });
  }

  cekParent(data) {
    // console.log("cekParent")
    // console.log("module_id => ",data.module_id)
    // console.log("parent_id => ",data.parent_id)
    // if(data.parent_id !== null) {
    //   console.log("punya parent submodule id => ", data.parent_id)
    // } else {
    //   console.log("tidak punya parent submodule id ")
    // }

    if(data.parent_id === null) {
      // do select modules with where => data.module_id
    } else {
      // do select sub modules with where => 
      // module_id = data.module_id AND
      // id = data.parent_id
      
      let subModulesParam = this.state.subModulesParam
      let newSubModulesParam = {
        ...subModulesParam,
        "filter[module_id][is]": data.module_id,
        // "filter[id][is]": data.parent_id,
        "include": "modules"
      }

      if(data.parent_id !== null) {
        newSubModulesParam["filter[id][is]"] = data.parent_id
      } 
      // // else {
      // //   newSubModulesParam["filter[id][is]"] = data.id
      // // }

      // console.log("newSubModulesParam",newSubModulesParam)

      const ac_tok = localStorage.getItem("access_token");
      const tok = "Bearer "+ac_tok;

      // get submodules
      axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
        headers: { 'Authorization': tok },
        // params: this.state.subModulesParam
        params: newSubModulesParam
      })
        .then( response => {
          console.log("PARENT response sub modules",response)

          // subModules
          // let arrSubmodules = []
          // forEach(response.data.data, item => {
          //   let subModules = {
          //     "created_at": item.attributes.created_at,
          //     "description": item.attributes.description,
          //     "id": item.attributes.id,
          //     "level": item.attributes.level,
          //     "module_id": item.attributes.module_id,
          //     "name": item.attributes.name,
          //     "parent_id": item.attributes.parent_id,
          //     "updated_at": item.attributes.updated_at
          //   }
          //   arrSubmodules.push(subModules)
          // })
          // this.setState({ subModulesChild: arrSubmodules })

        } )
        .catch( error => {
          console.log("error",error)
          Alert.error('Server Error.', 5000)
          // console.log('error',error.response.data)
          // console.log('error',error.response.status)
          // console.log('error',error.response.headers)
          // this.setState({ user : {} })
          // this.setState({ error : true })
          // this.setState({ loadingPage : true })
          // this.setState({ validToken : false })
          if(error.response.status === 401) {
            Auth.logout();
            this.props.history.push('/login');
          }
        });
    }


  }

  cekChild(data) {

    // console.log("cekChild")
    // console.log("module_id => ",data.module_id)
    // console.log("parent_id => ",data.id)

    let subModulesParam = this.state.subModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      "filter[module_id][is]": data.module_id,
      "filter[parent_id][is]": data.id,
    }

    // console.log("newSubModulesParam",newSubModulesParam)

    // if(data.parent_id !== null) {
    //   newSubModulesParam["filter[parent_id][is]"] = data.id
    // }

    // if(this.state.modulesFilterSelected !== null) {
    //   newSubModulesParam["filter[module_id][is]"] = this.state.modulesFilterSelected
    // }

    // if(this.state.submodulesFilterSelected !== null) {
    //   newSubModulesParam["filter[id][is]"] = this.state.submodulesFilterSelected
    // }

    // this.getSubModules(newSubModulesParam)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: newSubModulesParam
    })
      .then( response => {
        console.log("CHILD response sub modules",response)

        // jika tidak ada child nya
        if(response.data.success) {

        } else {
          Alert.error("Tidak Punya Child.", 5000)
        }

        // subModules
        // let arrSubmodules = []
        // forEach(response.data.data, item => {
        //   let subModules = {
        //     "created_at": item.attributes.created_at,
        //     "description": item.attributes.description,
        //     "id": item.attributes.id,
        //     "level": item.attributes.level,
        //     "module_id": item.attributes.module_id,
        //     "name": item.attributes.name,
        //     "parent_id": item.attributes.parent_id,
        //     "updated_at": item.attributes.updated_at
        //   }
        //   arrSubmodules.push(subModules)
        // })
        // this.setState({ subModulesChild: arrSubmodules })

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  cekMap(data) {
    // 1. ada berapa yang parent_id nya NULL
    // 2. setiap dari yg parent_id nya NULL, akan melakukan pencarian
    //    dari hasil keseluruhan yang memiliki parent_id dari masing2
    //    id yang hasil dr no 1
    //    
    console.log("cekMap", data)

    // cari dengan module_id, id yang didapat dari parent_id
    // |4-9
  }

  render () {

    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let items = (
      <Col xs={24}>
        <div className="myCard">
          <Panel shaded >
            <Panel>
              <Grid fluid>
                <Row>
                  <Col xs={24} >
                    <h5>Data Kosong</h5>
                    <h5>Pilih dari filter ...</h5>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Panel>
        </div>
      </Col>
    );

    if(this.state.subModulesData.length > 0) {
      // console.log("subModules ada isi nya",this.state.subModules)

      items = this.state.subModulesData.map((subModules) => 
      //   console.log("modules",modules)
        <Col xs={24} 
          id={`submodules_id_${subModules.id}`} 
          key={subModules.id}
        >
          <div className="myCard">
            <Panel shaded >
              <Panel 
                header={subModules.name} 
                collapsible 
                defaultExpanded={false}
              >
                <Grid fluid>
                  <Row>
                    <Col xs={24} >
                      <div>
                        <p>
                          <b>Id : </b><small>{subModules.id}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Name : </b><small>{subModules.name}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Deskripsi : </b><small>{subModules.description}</small>
                        </p>
                      </div>
                      
                      <div>
                        <p>
                          <b>Dari Module : </b><small>{subModules.module_name}</small>
                        </p>
                      </div>

                      <div>
                        <p>
                          <b>Map : </b><small>{subModules.module_name}</small>
                        </p>
                      </div>
                      
                      <div>
                        <ButtonToolbar>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleEditSubModules(subModules)}}
                          >
                            <Icon icon="sign-in" /> Edit
                          </Button>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleDeleteSubModules(subModules)}}
                          >
                            <Icon icon="sign-in" /> Delete
                          </Button>
                        {/*
                          <Button 
                            color="cyan"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekParent(subModules)}}
                          >
                            Cek Parent
                          </Button>
                          <Button 
                            color="orange"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekChild(subModules)}}
                          >
                            Cek Child
                          </Button>
                          <Button 
                            color="yellow"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekMap(subModules)}}
                          >
                            Cek Map
                          </Button>
                        */}
                        </ButtonToolbar>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel>  
            </Panel>
          </div>
        </Col>
        
      )
    } else {
      console.log("sub modules kosong")
    }

    return (
      <>
        <LayoutRsuite />
        <div className="submodulesContainer">
          <div className="container">
            <div className="jumbotron">
              <Breadcrumb>
                <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
                <Breadcrumb.Item active>{path}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="jumbotron">
              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Action" collapsible bordered defaultExpanded={false}>
                      <Button 
                        color="green" 
                        onClick={() => this.newSubModules('xs')}
                      >
                        <Icon icon='plus-square' />
                      </Button>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Filter" collapsible bordered defaultExpanded={false}>
                      <Grid fluid>
                        <Row className="">
                          <Col xs={16}>
                            <SelectPicker
                              // loading={this.state.loadingSimpan}
                              onClean={this.filterCoursesClean}
                              onSelect={(value) => {this.handleSelectFilterCourses(value)}}
                              onChange={(value) => {this.handleChangeFilterCourses(value)}}
                              onSearch={(value) => {this.handleSearchFilterCourses(value)}}
                              data={this.state.filterCoursesData}
                              style={{ width: 224, marginBottom: 10 }}
                              placeholder="Courses"
                              value={this.state.coursesFilterSelected}
                            />
                          </Col>
                          <Col xs={16}>
                            <SelectPicker
                              onClean={this.filterModulesClean}
                              onSelect={(value) => {this.handleSelectFilterModules(value)}}
                              onChange={(value) => {this.handleChangeFilterModules(value)}}
                              onSearch={(value) => {this.handleSearchFilterModules(value)}}
                              data={this.state.filterModulesData}
                              style={{ width: 224, marginBottom: 10 }}
                              placeholder="Modules"
                              value={this.state.modulesFilterSelected}
                            />
                          </Col>
                          <Col xs={16}>
                            <SelectPicker
                              onClean={this.filterSubModulesClean}
                              onSelect={(value) => {this.handleSelectFilterSubModules(value)}}
                              onChange={(value) => {this.handleChangeFilterSubModules(value)}}
                              onSearch={(value) => {this.handleSearchFilterSubModules(value)}}
                              data={this.state.filterSubModulesData}
                              style={{ width: 224, marginBottom: 10 }}
                              placeholder="Nama Sub Modules"
                              value={this.state.submodulesFilterSelected}
                            />
                          </Col>
                        {/*
                          <Col xs={16}>
                            <Input 
                              value={this.state.submodulesFilterName} 
                              onChange={this.handleFilterName} 
                              style={{ marginBottom: 10 }} 
                              size="md" 
                              placeholder="Nama Sub Modul" 
                            />
                          </Col>
                        */}
                          <Col xs={16}>
                            <Button 
                              color="blue"
                              appearance="primary" 
                              size="xs"
                              onClick={this.searchFilter}
                              style={{ marginBottom: 10 }}
                            >
                              Search
                            </Button>
                          </Col>
                          <Col xs={16}>
                            <Button
                              color="green" 
                              appearance="primary" 
                              size="xs"
                              onClick={this.resetFilter}
                              style={{ marginBottom: 10 }}
                            >
                              Reset
                            </Button>
                          </Col>
                        </Row>
                      </Grid>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
                <Modal.Header>
                  {/*
                  <Modal.Title>{action} Modules</Modal.Title>
                  <Modal.Title>Tambah Sub Modules</Modal.Title>
                  */}
                   <Modal.Title>{this.state.actionItem} Sub Modules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid fluid>
                    <Row className="">
                      <Col xs={18}>
                        Tentukan Module :
                      </Col>
                      <Col xs={16}>
                        <SelectPicker
                          onClean={this.filterModulesClean}
                          onSelect={(value) => {this.modulesSelect(value)}}
                          onSearch={(value) => {this.modulesSearch(value)}}
                          data={this.state.modules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Modules"
                          value={this.state.modulesSelected}
                        />
                      </Col>
                      <Col xs={18}>
                        Tentukan Parent Sub Module :
                      </Col>
                      <Col xs={16}>
                        <SelectPicker
                          onClean={this.filterModulesClean}
                          onSelect={(value) => {this.subModulesSelect(value)}}
                          onSearch={(value) => {this.subModulesSearch(value)}}
                          data={this.state.subModules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Sub Modules"
                          value={this.state.subModulesSelected}
                        />
                      </Col>
                      <Col xs={18}>
                        Nama Sub Module :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueName} 
                          onChange={this.handleName} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Nama Sub Modul" 
                        />
                      </Col>
                      <Col xs={18}>
                        Deskripsi :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueDeskripsi} 
                          onChange={this.handleDeskripsi} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Deskripsi Sub Modul" 
                        />
                      </Col>
                      {/*
                      const thesubmodules = {
                        "created_at": item.attributes.created_at,
                        "description": item.attributes.description,
                        "id": item.attributes.id,
                        "level": item.attributes.level,
                        "module_id": item.attributes.module_id,
                        "name": item.attributes.name,
                        "parent_id": item.attributes.parent_id,
                        "updated_at": item.attributes.updated_at
                      }
                      */}
                      {/*
                      <Input value={this.state.valueDate} onChange={this.handleDate} style={{ marginBottom: 10 }} size="md" placeholder="Tanggal" />
                      */}
                     {/*
                      <Col xs={18}>
                        <DatePicker
                          value={this.state.valueDate}
                          onChange={(value) => {this.dateChange(value)}}
                          style={{ marginBottom: 10 }}
                          format="YYYY-MM-DD HH:mm:ss"
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date()
                            }
                          ]}
                        />

                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.modulesChange(value)}}
                          onSearch={(value) => {this.modulesSearch(value)}}
                          data={this.state.modulesData}
                          value={this.state.valueModules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Modules"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.periodesChange(value)}}
                          onSearch={(value) => {this.periodesSearch(value)}}
                          data={this.state.periodesData}
                          value={this.state.valuePeriodes}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Periodes"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onSelect={(value) => {this.coursesSelectedSelect(value)}}
                          onChange={(value) => {this.coursesSelectedChange(value)}}
                          onSearch={(value) => {this.coursesSelectedSearch(value)}}
                          data={this.state.coursesSelectedData}
                          value={this.state.valueCourseSelected}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Courses"
                        />
                      </Col>
                      <Col xs={18}>
                        <Input 
                          value={this.state.valueName} 
                          onChange={this.handleName} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Nama Modul" 
                        />
                      </Col>

                      <Col xs={18}>
                        <Input 
                          value={this.state.valueDescription} 
                          onChange={this.handleDescription} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Deskripsi" 
                        />
                      </Col>
                     */}
                    </Row>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  <Button 
                    onClick={this.simpanHandler}
                    color="violet"
                    loading={this.state.loadingSimpan}
                   >
                    Simpan
                  </Button>
                  <Button onClick={this.close} appearance="subtle">
                    Batal
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>

            <div>
              <Grid fluid>
                <Row>
                  {items}
                </Row>
              </Grid>
            </div>

          </div>
        </div>
      </>
    )
  }
}

export default SubModules;
