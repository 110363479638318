import React, {Component} from 'react'
import configs from '../../configs/index'
// import aaa from 'https://api.amithyainstitute.com/prakerja.json'
import { 
  // Uploader,
  Grid,
  Row,
  Col,
  Container,
  Content,
  Header,
  Footer,
  Panel,
  Loader,
  ButtonToolbar,
  Button,
  Icon,
  Modal,
  Input,
  Alert,
  Nav, 
  // Dropdown,
  IconButton
} from 'rsuite';
import axios from 'axios'
import './ConferenceSchedule.css'
import forEach from "lodash/forEach";
import RoomsServices from '../../services/Rooms/index'

class ConferenceSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listPrakerja: [],
      userName: "",
      size: "xs",
      show: false,
      role: null,
      validToken: false,
      error: false,
      loadingPage: true,
      conferences: [],
      conferencesHasData: false,
      rooms: [],
      roomExist: false,
      roomSelected: {},
      availableRooms: [],
      active: 'home',
      // expand: true,
      // myEmail: '',
      // myPassword: '',
      // loadingSubmit: false,
      // redirect: false,
      // servis: '',
      // user: {}
    };
    this.handleJoin = this.handleJoin.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.masukRoomHandler = this.masukRoomHandler.bind(this);
    this.handleUserName = this.handleUserName.bind(this);
    this.check = this.check.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    // if (authenticationService.currentUserValue) { 
    // this.props.history.push('/login');
    // }
    // window.location.reload(true)
  }

  componentDidMount() {
    // const fileUrl = 'https://meet.amithyainstitute.com/user/prakerja.json'
    const fileUrl = configs.baseEndpoint.baseURL+'api/prakerja'
    // var reader = new FileReader()
    // reader.readAsText(fileUrl)
    // console.log(reader.readAsText(fileUrl))

    // console.log(aaa)
    // console.log(FileReader.readAsDataURL(fileUrl))

    // fetch(fileUrl)
    //   .then(response => response.json())
    //   .then((jsonData) => {
    //     // jsonData is parsed json object received from url
    //     console.log(jsonData)
    //   })
    //   .catch((error) => {
    //     // handle your errors here
    //     console.error(error)
    //   })

    // ambil prakerja dari file
    axios.get(fileUrl)
      .then(response => {
        // console.log("response",response)
        // console.log("response.data",response.data)
        // console.log("response.data.data",response.data.data)
        let obj = JSON.parse(response.data.data)
        
        this.setState({ listPrakerja : obj })
        // console.log("obj",obj)
      })
      .catch( error => {
        console.log("error",error)
      });

    axios.get(configs.baseEndpoint.baseURL+'api/conferences?include=periodes&sort=-created_at')
      .then( response => {
        // console.log("response",response)
        // console.log("response.data.conferences",response.data)
        if(response.data.data.length > 0) {
          this.setState({ conferences : response.data.data })
          this.setState({ conferencesHasData : true })
        }
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        console.log('error',error.response.data)
        console.log('error',error.response.status)
        console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });

    axios.get(configs.baseEndpoint.baseURL+'api/testbbb/getallmeetings')
      .then( response => {
        // console.log("this.state.conferences",this.state.conferences)
        // console.log("response.data.getallmeetings",response.data)
        let arrRooms = []
        forEach(response.data, item => {
          // console.log("item.attendees => ",item.attendees)
          // attendees
          // item.attendees.attendee[0...n]
          // info di dalam nya :
          // clientType: "HTML5"
          // fullName: "yogiedigital"
          // hasJoinedVoice: "false"
          // hasVideo: "false"
          // isListeningOnly: "true"
          // isPresenter: "false"
          // role: "MODERATOR"
          // userID: "w_m1cgqmj6x5tk"

          let rooms = {
            "id": item.meetingID,
            "maxUsers": item.maxUsers,
            "meetingName": item.meetingName, 
            "attendees": item.attendees.length,
            "attendeePW": item.attendeePW,
            "moderatorPW": item.moderatorPW,
            "participantCount": item.participantCount,
            "running": item.running
          }
          arrRooms.push(rooms)
        })
        this.setState({ rooms : arrRooms })
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
  }

  open(size) {
    this.setState({
      size,
      show: true,
    });
  }

  close() {
    this.setState({
      show: false,
      userName: "",
      // meetingName: "",
      // attendeePW: "attendee",
      // moderatorPW: "moderator",
      // maxParticipants: 5,
      // valueRoleSelect: null,
      // valueNama: '',
      // valueEmail: '',
      // valueNim: '',
      // valuePass: '',
      // valueKonfPass: ''
    });
    this.setState({ roomExist : false })
    this.setState({ roomSelected : {} })
  }

  async masukRoomHandler() {
    this.setState({ loading : true })
    // console.log("this.state.listPrakerja",this.state.listPrakerja)
    // console.log("this.state.userName",this.state.userName)

    if(this.state.userName === "") {
      Alert.warning('Email Tidak Boleh Kosong.', 5000)
    } else {
      
      // check id first
      let res = this.state.roomSelected.meetingName.split("/ ")
      var str = res[0]
      var roomId = Number(str.replace("schedule_id_", ""))
      // console.log("roomId",roomId)

      // id yang ditentukan (statis)
      if(roomId === 42 || roomId === 43 || roomId === 44) {
        // console.log("prakerja")
        let testa = this.state.listPrakerja

        // get check room id
        let arrListEmailUser = []
        forEach(testa, item => {
          // console.log("item", item)
          if(item.id === roomId) {
            // console.log("Masuk If")
            arrListEmailUser = item.user
          }
        })
        // console.log("id selected => ", arrListEmailUser)

        // check the email LIST user
        // contoh yang ada
        // teguhanto575@gmail.com
        let userInsideList = false
        forEach(arrListEmailUser, mail => {
          // console.log("mail", mail.email, " ===> ",this.state.userName)
          if(mail.email === this.state.userName) {
            userInsideList = true
          }
        })
        // console.log("userInsideList", userInsideList)

        if(userInsideList) {
          if(this.state.roomExist) {
            let payload = {
              "id": this.state.roomSelected.id,
              "name": this.state.userName,
              "password": this.state.roomSelected.attendeePW
            }
            // console.log("payload",payload)
            let result = await RoomsServices.joinRoom(payload, {})
            // console.log("result",result)
            if(result.data.success === "true") {
              window.open(result.data.url, "_blank")
              this.close()
            } else {
              Alert.warning('Gagal Masuk.', 5000)
              this.close()
            }
          } else {
            Alert.warning('Room Belum di buka.', 5000)  
          }
        } else {
          Alert.warning('Email Tidak Terdaftar.', 5000)
        }
      } else {
        // console.log("bukan prakerja")
        if(this.state.roomExist) {
          let payload = {
            "id": this.state.roomSelected.id,
            "name": this.state.userName,
            "password": this.state.roomSelected.attendeePW
          }
          let result = await RoomsServices.joinRoom(payload, {})
          // console.log("result",result)
          if(result.data.success === "true") {
            window.open(result.data.url, "_blank")
            this.close()
          } else {
            Alert.warning('Gagal Masuk.', 5000)
            this.close()
          }
          // console.log("payload",payload)
        } else {
          Alert.warning('Room Belum di buka.', 5000)  
        }
      }
    }
    // no iframe
    // if(this.state.roomExist) {
      // console.log("masukRoomHandler",this.state.userName)
      // console.log("masukRoomHandler",this.state.roomExist)
      // console.log("masukRoomHandler",this.state.roomSelected)
      
      // 42 Webinar Prakerja Pengantar Untuk Pelayanan Makanan (Food & Beverage)
      // 43 Webinar Prakerja Dasar - Dasar Housekeeping
      // 44 Webinar Prakerja Dasar - Dasar Front Office

      // let testa = this.state.listPrakerja

      // let res = this.state.roomSelected.meetingName.split("/ ")

      // var str = res[0]
      // var resId = str.replace("schedule_id_", "")
      // console.log("resId",Number(resId))

      // let myJSON = JSON.stringify(testa)
      // console.log("myJSON", myJSON)

      // // the for
      // let arrListEmailUser = []
      // forEach(testa, item => {
      //   // console.log("item", item)
      //   if(item.id === Number(resId)) {
      //     // console.log("Masuk If")
      //     arrListEmailUser = item.user
      //   }
      // })

      // console.log("setelah diisi => ", arrListEmailUser)

      // // check the email LIST user
      // let userInsideList = false
      // forEach(arrListEmailUser, mail => {
      //   // console.log("mail", mail.email, " ===> ",this.state.userName)
      //   if(mail.email === this.state.userName) {
      //     userInsideList = true
      //   }
      // })

      // console.log("userInsideList", userInsideList)

      // let payload = {
      //   "id": this.state.roomSelected.id,
      //   "name": this.state.userName,
      //   "password": this.state.roomSelected.attendeePW
      // }

      // console.log("payload",payload)
      // let result = await RoomsServices.joinRoom(payload, {})
      // if(result.data.success === "true") {
      //   // window.open(result.data.url, "_blank")
      //   // this.close()
      // } else {
      //   Alert.warning('Gagal Masuk.', 5000)
      //   this.close()
      // }
      // console.log("result",result)
    // }

    // with iframe
    // if(this.state.roomExist) {
    //   console.log("masukRoomHandler",this.state.userName)
    //   console.log("masukRoomHandler",this.state.roomExist)
    //   console.log("masukRoomHandler",this.state.roomSelected)
    //   let payload = {
    //     "id": this.state.roomSelected.id,
    //     "name": this.state.userName,
    //     "password": this.state.roomSelected.attendeePW
    //   }

      // console.log("payload",payload)
    //   let result = await RoomsServices.joinRoom(payload, {})
    //   window.open(result.data.url, "_blank")
    //   this.close()
    // }
    this.setState({ loading : false })
  }

  handleUserName(value) {
    this.setState( {userName: value} )
    // console.log("handleUserName",this.state.userName)
  }

  handleJoin(id) {
    this.setState({ loading: true })
    // this.open("xs");

    // console.log('handleJoin => id = ',id)
    // 
    // untuk cari substring yang sama
    // const string = "foo";
    // const substring = "oo";
    // console.log(string.includes(substring));
    // 
    // var string = "foo";
    // var substring = "oo";
    // console.log(string.indexOf(substring) !== -1);
    // 
    // 
    // schedule_id_11
    
    const key = "schedule_id_"+id
    // console.log('handleJoin => id = ',key)
    // console.log("this.state.rooms",this.state.rooms)
    // meetingName: "schedule_id_42/ 1599831871"

    if(this.state.rooms.length > 0) {
      forEach(this.state.rooms, item => {
        const string = item.meetingName
        const substring = key

        // const checkSame = string.includes(substring)
        const res = string.split("/ ")
        // console.log("res",res[0])
        // console.log(res[0] === substring)

        if(res[0] === substring) {
        // if(checkSame) {

          this.open("xs")
          this.setState({ roomExist : true })
          this.setState({ roomSelected : item })

          // window.open(result.data.url, "_blank")

          // console.log(string.includes(substring))

          // console.log("item yang ini yang terpilih => ",item)
          // console.log("room dengan nama "+item.meetingName+" ada")
        } else {
          // console.log("room di bbb tidak ada")
          Alert.warning('Room Belum Tersedia.', 5000)
          this.setState({ roomExist : false })
          this.setState({ roomSelected : {} })
        }
        // console.log("item.meetingName",item.meetingName)
        // console.log(string.includes(substring))
      })
    } else {
      Alert.warning('Belum Ada Room Sama Sekali.', 5000)
      // console.log("No rooms in BBB")
    }


    // console.log("this.state.roomExist",this.state.roomExist)

    // if(this.state.roomExist) {
    //   this.open("xs")
    // }

    // this.setState({ show: true });
    // this.props.editMethod(id);
    this.setState({ loading: false })
  }

  check() {
    console.log("check this.state.conferences",this.state.conferences)
    console.log("check this.state.rooms",this.state.rooms)


    return (
      <span>Hallo</span>
    )
  }

  // checkActiveNow() {
  //   console.log("checkActiveNow()",this.state.active)
  // }

  handleSelect(activeKey) {
    if(activeKey === "home") {
      axios.get(configs.baseEndpoint.baseURL+'api/conferences?include=periodes&sort=-created_at')
        .then( response => {
          // console.log("response",response)
          // console.log("response.data.conferences",response.data)
          if(response.data.data.length > 0) {
            this.setState({ conferences : response.data.data })
            this.setState({ conferencesHasData : true })
          }
          // response.data.data.attributes.name
          // this.setState({ user : response.data.data.attributes })
          // this.setState({ error : false })
          // this.setState({ loadingPage : false })
          // this.setState({ validToken : true })
        } )
        .catch( error => {
          console.log("error",error)
        });

      axios.get(configs.baseEndpoint.baseURL+'api/testbbb/getallmeetings')
        .then( response => {
          let arrRooms = []
          forEach(response.data, item => {

            let rooms = {
              "id": item.meetingID,
              "maxUsers": item.maxUsers,
              "meetingName": item.meetingName, 
              "attendees": item.attendees.length,
              "attendeePW": item.attendeePW,
              "moderatorPW": item.moderatorPW,
              "participantCount": item.participantCount,
              "running": item.running
            }
            arrRooms.push(rooms)
          })
          this.setState({ rooms : arrRooms })
        } )
        .catch( error => {
          console.log("error",error)
        });
    }

    this.setState({ active: activeKey });
  }

  render() {
    
    // this.check()
    // console.log("this.state.conferences",this.state.conferences.length)
    // console.log("this.state.rooms",this.state.rooms)
    // schedule_id_11

    // const hasRoom = this.state.isLoggedIn;

    let items = <Loader size="lg" backdrop content="tunggu..." vertical />;

    if(this.state.active === "home") {
      // if(this.state.conferences.length > 0) {
      if(this.state.conferencesHasData === true) {
        // console.log("this.state.conferences",this.state.conferences.length)
        // console.log("this.state.conferences",this.state.conferences)
        // console.log("this.state.rooms",this.state.rooms)
        
        // let coba = this.check()

        items = this.state.conferences.map((item) => 
          
          <Col xs={24} id={`col_id_${item.attributes.id}`} key={item.attributes.id}>
            <div className="myCard">
              <Panel shaded >
                <Panel header={item.attributes.description} collapsible defaultExpanded={false}>
                  <div>
                    <p>
                      <small>{item.attributes.description} {item.attributes.date_of_meeting}</small>
                    </p>
                  </div>
                  <div>
                    <ButtonToolbar>
                      <Button 
                        loading={this.state.loading}
                        color="violet" 
                        appearance='primary' 
                        block
                        onClick={() => {this.handleJoin(item.attributes.id)}}
                      >
                        <Icon icon="sign-in" /> Join Room
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Panel>
              </Panel>
            </div>
          </Col>
        );
      } else {
        items = (
          <Col xs={24}>
            <div className="myCard">
              <Panel shaded >
                <Panel header="No Data" collapsible defaultExpanded={false}>
                  <div>
                    <p>
                      <small>No Data</small>
                    </p>
                  </div>
                </Panel>
              </Panel>
            </div>
          </Col>
        );
      }
    }

    if(this.state.active === "about") {
      items = (
        <Col xs={24}>
          <div className="myCard">
            <Panel shaded >
              <Panel header="About" collapsible defaultExpanded={false}>
                <div>
                  <p>
                    Berawal dari ide keberlanjutan industri perhotelan di masa depan, 
                    sejalan dengan kebutuhan tenaga kerja di sektor bisnis yang membutuhkan keterampilan individu yang kuat, 
                    Institut Amithya didirikan untuk menghasilkan tenaga kerja yang dibutuhkan sekaligus menjembatani para pencari kerja. 
                    Untuk memenuhi pekerjaan impian masa depan mereka.
                  </p>
                  <p>
                    Karena industri perhotelan terus berkembang, 
                    Institut Amithya menyediakan pendidikan perhotelan terbaik untuk memastikan talenta muda perhotelan siap untuk membuat peran penting dalam industri perhotelan. 
                    Program Institut Amithya menyediakan kombinasi kurikulum modern dengan pengalaman perhotelan langsung yang mengembangkan dan membentuk siswa menjadi lulusan perhotelan profesional.
                  </p>
                </div>
              </Panel>
            </Panel>
          </div>
        </Col>
      );
    }

    return (
      <div className="conferenceScheduleContainer">
        <Container>
          <Header>
            {/*
            <Grid fluid>
              <Row>
                <Col xs={24}>
                  <h1>Conference Schedule</h1>
                </Col>
              </Row>
            </Grid>
            */}
          </Header>
          <Content>
            <Grid fluid>
              {/*
              <Row>
                <Col xs={24}>
                  <div className="headerTitle">
                    <h5>Kelas Terbuka</h5>
                  </div>
                </Col>
              </Row>
              */}
              <Row>
                <Col xs={24}>
                  <Nav 
                    style={{marginBottom: 30, marginTop: 20}}
                    appearance="tabs" 
                    justified
                    onSelect={this.handleSelect}
                    activeKey={this.state.active}
                  >
                    <Nav.Item 
                      eventKey="home" 
                      icon={<Icon icon="home" />} 
                    >
                      Kelas Terbuka
                    </Nav.Item>
                    <Nav.Item eventKey="about">About</Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Grid>

            <Grid fluid>
              <Row>
                {items}
                <Col xs={24}>
                  <div className="myCard">
                    <Panel shaded>
                      <Panel header="Kembali Ke Halaman Login">
                        <ButtonToolbar>
                          <IconButton 
                            icon={<Icon icon="sign-in" />} 
                            color="violet" 
                            circle 
                            href="/login"
                            appearance="default"
                          />
                        </ButtonToolbar>
                        {/*
                        <div>
                          <p>
                            <i><small>Untuk kembali ke halaman login tekan tombol Login.</small></i>
                          </p>
                          <p>
                            <ButtonToolbar>
                              <IconButton 
                                icon={<Icon icon="sign-in" />} 
                                color="violet" 
                                circle 
                                href="/login"
                                appearance="default"
                              />
                            </ButtonToolbar>
                          </p>
                        </div>
                        */}
                      </Panel>
                    </Panel>
                  </div>
                </Col>
                {/*
                <Col xs={24}>
                  <div className="myCard">
                    <Panel shaded>
                      <Panel header="RSUITE">
                        <p>
                          <small>A suite of React components, sensible UI design, and a friendly development experience.</small>
                        </p>
                      </Panel>
                    </Panel>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="myCard">
                    <Panel shaded>
                      <Panel header="RSUITE">
                        <p>
                          <small>A suite of React components, sensible UI design, and a friendly development experience.</small>
                        </p>
                      </Panel>
                    </Panel>
                  </div>
                </Col>
                */}
              </Row>
            </Grid>

          {/* Card Version
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <div className="myCard">
                    <Panel shaded header="Card title">
                      AAA
                    </Panel>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="myCard">
                    <Panel shaded header="Card title">
                      BBB
                    </Panel>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="myCard">
                    <Panel shaded header="Card title">
                      AAA
                    </Panel>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="myCard">
                    <Panel shaded header="Card title">
                      BBB
                    </Panel>
                  </div>
                </Col>
              </Row>
            </Grid>
          */}
         
         {/*MODAL*/}
          <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
            <Modal.Header>
              <Modal.Title>Masukkan Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Grid fluid>
                
                <Row className="">
                  <Col xs={18}>
                    <Input 
                      onChange={this.handleUserName}
                      style={{ marginBottom: 10 }} 
                      size="md" 
                      placeholder="Masukkan Email"
                      value={this.state.userName} 
                    />
                  </Col>
                </Row>
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                loading={this.state.loading}
                onClick={this.masukRoomHandler}
                color="violet"
               >
                Masuk Room
              </Button>
              <Button onClick={this.close} appearance="subtle">
                Batal
              </Button>
            </Modal.Footer>
          </Modal>
          </Content>
          <Footer>
            <Grid fluid>
              <Row>
                <Col xs={8}>
                  
                </Col>
                
                <Col xs={8}>
                  
                </Col>

                <Col xs={8}>
                  {/*
                  <ButtonToolbar>
                    <IconButton icon={<Icon icon="facebook-official" />} color="blue" circle />
                    <IconButton icon={<Icon icon="google-plus-circle" />} color="red" circle />
                    <IconButton icon={<Icon icon="twitter" />} color="cyan" circle />
                    <IconButton icon={<Icon icon="linkedin" />} color="blue" circle />
                  </ButtonToolbar>
                  */}
                </Col>
              </Row>
            </Grid>
          </Footer>
        </Container>
      </div>
    );
  }
}

export default ConferenceSchedule;
