import BaseService from '../BaseService/index'

class Schedules extends BaseService {
  // constructor() {
  //   super()
  // }

   async getSchedules(params = {}) {
     // this.endPoint = 'users'
     this.endPoint = 'api/schedules'
     return this.get(params)
   }

   async createSchedules(data: any) {
     // console.log('createUser ==> data',data)
     // this.endPoint = 'auth/register'
     this.endPoint = 'api/schedules'
     return this.post(data)
   }

   async deleteSchedules(id) {
     // console.log(id)
     // `http://localhost:3000/movies/${movie.id}`;
     this.endPoint = 'api/schedules'
     return this.delete(id)
   }

   async updateSchedules(id: any, data: any) {
     // this.endPoint = 'users'
     this.endPoint = 'api/schedules'
     return this.putOne(id, data)
   }

   // // new
   async getOneSchedules(id: any, opts = {}) {
     // console.log("opts",opts)
     // this.endPoint = `api/schedules`
     this.endPoint = 'api/schedules'
     return this.getOne(id, opts)
   }
}

export default new Schedules()
