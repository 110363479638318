// import React from 'react';
import users from './services/Users/index'
// import {
//   Redirect,
// } from "react-router-dom";

// export const isAuthenticated = () => false;
export const isAuthenticated = (props) => {
  // get from localstorage after login
  // window.localStorage.clear()
  // console.log('window.localStorage', window.localStorage)
  const access_token = localStorage.getItem("access_token");
  // const role = localStorage.getItem("role");
  // console.log('access_token',access_token)
  // console.log('role',role)


  // // // userResp.hasOwnProperty("message")
  // let auth = false
  // if(userResp.hasOwnProperty("message")) {
  //   console.log('masuk if')
  //   if(userResp.message === "Unauthenticated.") {
  //     console.log('masuk if Unauthenticated')
  //     auth = false
  //     // return auth
  //   }
  // } else {
  //   console.log('masuk else')
  //   if(userResp.data.hasOwnProperty("success")) {
  //     console.log('masuk if success')
  //     if(userResp.data.success === true) {
  //       console.log('masuk auth true')
  //       auth = true
  //       // return auth
  //     }
  //   }
  // }

  // console.log('auth',auth)

  // gagal =
  // {message: "Unauthenticated."}
  // 
  // berhasil = 
  // {success: true .....}

  // dikasih if sukses or not
  // jika gagal maka return nya false
  // window.localStorage.clear()

  // siniii
  let auth = false

  if(!(access_token === null)) {
    auth = true
  }
  return auth;
};

export const checkValidToken = async () => {
  // console.log('di isFunct',props)
  try {
    const userResp = await users.getMe();
    const user = userResp.data
    console.log('userResp',userResp)
    const role_id = user.data.attributes.role_id
    const role_name = user.included.roles[role_id].attributes.name
    
    window.localStorage.setItem('role', role_name)
    return true
  } catch (error) {
    console.log('error',error.message)
    
    
    // this.catchHandler(error);
    return false
  }
};

export const getRole = async () => {
  // const userResp = await users.getMe();
  // const user = userResp.data
  // console.log('userResp',userResp)
  // const role_id = user.data.attributes.role_id
  // const role_name = user.included.roles[role_id].attributes.name
  
  // window.localStorage.setItem('role', role_name)

  try {
    const userResp = await users.getMe();
    const user = userResp.data
    // console.log('userResp',userResp)
    const role_id = user.data.attributes.role_id
    const role_name = user.included.roles[role_id].attributes.name
    window.localStorage.setItem('role', role_name)

    return role_name
  } catch(error) {
    console.log('error',error)

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    return false
    // return <Redirect to='/login'/>;
  }
  // const responseRole = await getRole();
  // return responseRole;
  

  // if(userResp.hasOwnProperty("message")) {
  //   console.log('masuk if')
  //   if(userResp.message === "Unauthenticated.") {
  //     console.log('masuk if Unauthenticated')
  //   }
  // } else {
  //   console.log('masuk else')
  //   if(userResp.data.hasOwnProperty("success")) {
  //     console.log('masuk if success')
  //     if(userResp.data.success === true) {
  //       console.log('masuk auth true')
  //     }
  //   }
  // }

  // return <Redirect to='/'/>;

  // dikasih if sukses or not
  // jika gagal maka ditendang ke /login dan clear local storage
  // window.localStorage.clear()
    // window.location.reload(true)



  // console.log('di isFunct',user)
  // return role_name
  // return "superadmin"
};