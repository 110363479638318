import React, {Component} from 'react'
// import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './AdminStudentTask.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  // Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom"
// import axios from 'axios'
// import Auth from '../../services/Auth/index'
// import forEach from "lodash/forEach"
import { 
  // Panel,
  // Button,
  // Icon,
  // Grid,
  // Row,
  // Col,
  // Modal,
  // Input,
  // SelectPicker,
  // ButtonToolbar,
  // Pagination,
  // Breadcrumb,
  // Alert,
} from 'rsuite';
// import forEach from "lodash/forEach"
// import subModules from '../../services/SubModules/index'

class AdminStudentTask extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    // const ac_tok = localStorage.getItem("access_token");
    // const tok = "Bearer "+ac_tok;
  }

  render() {
    return (
      <>
        <LayoutRsuite />
        <div className="adminStudentTaskContainer">
          Admin Student Task
        </div>
      </>
    );
  }

}

export default AdminStudentTask;
