import React, { Component } from 'react';
import axios from 'axios';

class UploadFile2 extends Component {

  constructor(props) {
    super(props);
    this.state ={
      image: ''
    }
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }
  onFormSubmit(e){
    e.preventDefault() 
    this.fileUpload(this.state.image);
  }
  onChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
          return;
    this.createImage(files[0]);
  }
  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result
      })
    };
    reader.readAsDataURL(file);
  }
  fileUpload(image){
    // const url = 'http://amithya-institute-api/api/loremUpload';
    const formData = {file: this.state.image}
    // return  axios.post(url, formData)
    //         .then(response => console.log(response))

    // for image
    const theOpts = {
      headers: { 
        // 'Access-Control-Allow-Origin': '*',
        // 'Content-Type': "multipart/form-data",
        // 'Content-Type': "text/plain",
        // 'Content-Type': "text/html",
        // 'Content-Type': "application/x-www-form-urlencoded",
        // 'Accept': '*/*' 
      }
    }

    // axios.post('http://amithya-institute-api/api/loremUpload', formData, theOpts )
    axios.post('https://api.amithyainstitute.com/api/loremUpload', formData, theOpts )
      .then( response => {
        console.log("response",response)
      } )
      .catch( error => {
        console.log("error",error)
        // this.setState({ error : true })
      });

  }

  render()
   {
      return(
        <>
          <form 
            onSubmit={this.onFormSubmit}
            encType="multipart/form-data"
          >
            <h1>React js Laravel File Upload Tutorial</h1>
            <input type="file"  onChange={this.onChange} />
            <button type="submit">Upload</button>
          </form>
        </>
      );
   }
}

export default UploadFile2;