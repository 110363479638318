import React from "react";

import Routes from "./routes";
import 'rsuite/dist/styles/rsuite-default.css';

// import LayoutRsuite from './hoc/LayoutRsuite/LayoutRsuite';
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// old
// const Appyog = () => <Routes />;
// const Appyog = () => (
//   <div className="Appyog">
//     <BrowserRouter>
//       <LayoutRsuite>
//         <Routes aaa="haloes"/>
//       </LayoutRsuite>
//     </BrowserRouter>
//   </div>
// );

const Appyog = () => (
  <div className="Appyog">
    <Routes aaa="haloes"/>
  </div>
);

export default Appyog;