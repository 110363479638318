const configs = {
  baseEndpoint: {
    // production
    baseURL: 'https://api.amithyainstitute.com/'
    // Local
    // baseURL: 'http://amithya-institute-api/'
  }
  // api: {
  //   baseURL: process.env.VUE_APP_API_URL,
  //   corsProxy: 'https://cors-anywhere.herokuapp.com/'
  // },

  // credentials: {
  //   clientID: process.env.VUE_APP_CLIENT_ID,
  //   clientSecret: process.env.VUE_APP_CLIENT_SECRET
  // },

  // pusher: {
  //   appID: '334245',
  //   key: '19b0b64fcd884af7c60f',
  //   secret: '5fd449abd6c3d968cbd4'
  // },

  // google: {
  //   apiKey: 'AIzaSyBmkto7ztRLTz3eOBU7KZjrDdkvmBz_B3g'
  // },

  // searoutes: {
  //   baseURL: 'https://api.searoutes.com/',
  //   apiKey: 'qSilGk9zYg3rBmQZWXsiH4yHKhv7aqiQ9qQe2Fch'
  // }
}

export default configs
