import React, { Component } from 'react';
import { 
  // Loader,
  // Calendar,
  // Modal,
  // Button,
  // ButtonToolbar
} from 'rsuite';
// import axios from 'axios'
// import MyCalendar from '../../components/MyCalendar/MyCalendar'
// import MyTable from '../../components/MyTable/MyTable'
// import MyList from '../../components/MyList/MyList'
import {
  Redirect
} from "react-router-dom";

// export const isAuthenticated = () => {
//   return true;
// };

class HelloWorld extends Component {
  // KEEEEP THIS //
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     show: false,
  //     error: false
  //   };
  //   this.close = this.close.bind(this);
  //   this.open = this.open.bind(this);
  // }
  // close() {
  //   this.setState({
  //     show: false
  //   });
  // }
  // open(size) {
  //   this.setState({
  //     size,
  //     show: true
  //   });
  // }
  // // // // // // // // // // // 

  state = {
    error: false,
    loadingPage: true,
  }

  componentDidMount () {
    // const tok = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjBkZmEyMDAyNzIxMThmNGEzNWM3NzViZjg0NWEyYmEyNjQ2ODc3ZjIxM2IyNmJkMGFjMTY3ZDI1M2FlN2RmNzE4MjM3NmE1MWM3MTYxN2RmIn0.eyJhdWQiOiIxIiwianRpIjoiMGRmYTIwMDI3MjExOGY0YTM1Yzc3NWJmODQ1YTJiYTI2NDY4NzdmMjEzYjI2YmQwYWMxNjdkMjUzYWU3ZGY3MTgyMzc2YTUxYzcxNjE3ZGYiLCJpYXQiOjE1OTU2NjQ2MDYsIm5iZiI6MTU5NTY2NDYwNiwiZXhwIjoxNjI3MjAwNjA2LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.O-rn-EwqhLNm7G0kxdbqyeV4AfpFJ8NANP3KK0hmF4MHE0xIjxtmRpq3iH3KGSSSNCnJvLeGSzlRHNH-pWJb3AVhiX_vY1cRwIceG6uSWMt99D5fzDeEGGLcs54unFmwaMb9dwIsR2jGDQiiXNIidVXruAuJvdbTqy-kVAjOqDidOQTwoVc2QDBFy00h1mBrQtKgS4HmB3Fsgm0LgjuLsmlOKVMIQNBhZvF4VWnleQHOTwO2xCsW4peqb6v8jHDwqjH2L49ljgVRglvWev4ZQD_8Odaf3DNdabsbhD50ON2eAgMstMYitdjpK0l7JdRwlClUoUHY2Pyrt00_79BmgAeGNOTC-FGaJ6IRKctSY8WnN0W6_U4KcedyjdUwBYWxs2lUrAlWqFNw_j9nCvjj6e4-tbMvzFKiDRznysMvZb32M44nenxNK0wavVANjZtSmCpRSdr7eIzUC_AS7Ymi_TYAzczt-b55eKWnDqSPoRNnEXi3L_lDykn9OLa7LH9EGSS4Irg2nwkiZBCtTwWEB-ztH8Lm2Er8Y22KYJDQiCLA7pPOof5hQZgqDzX2EZ1MfCX77xwPczzo60IASSSHXjxknxpCH2fUcJ7qFlKbbZhsNxIORtWjajNOhhcWyd5J8MmrNe8cSwEr5WcXrKAfCWZkWDNxGwv1nuRUgHOdEkQ";
    // axios.get('http://amithya-institute-api/api/users', {
    //   headers: { 'Authorization': tok }
    // })
    //   .then( response => {
    //     console.log("response",response)
    //   } )
    //   .catch( error => {
    //     console.log("error",error)
    //     this.setState({ error : true })
    //   });
  }


  render() {
    // return <Redirect to={{ pathname: '/buangan' }}/>;
    // let result = <div style={{textAlign: 'center'}}>Error</div>;
    let result = <Redirect to={{ pathname: '/login' }}/>;
    // if(!this.state.error) {
    //   // result = <div style={{textAlign: 'center'}}>Sukses</div>;
    //   result = <Loader size="lg" backdrop content="loading..." vertical />
    // }
    return (
      <div>
        {result}
      </div>
    );
  }

  // render() {
  //   return (
  //     <div className="modal-container">
  //       <ButtonToolbar>
  //         <Button size="xs" onClick={() => this.open('xs')}>
  //           Xsmall
  //         </Button>
  //       </ButtonToolbar>
  //       <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
  //         <Modal.Header>
  //           <Modal.Title>Modal Title</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>
  //           <h1>Modal</h1>
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <Button onClick={this.close} appearance="primary">
  //             Ok
  //           </Button>
  //           <Button onClick={this.close} appearance="subtle">
  //             Cancel
  //           </Button>
  //         </Modal.Footer>
  //       </Modal>
  //     </div>
  //   );
  // }
}

export default HelloWorld;
