import React, {Component} from 'react'
import configs from '../../configs/index'
import { 
  Loader,
  Panel,
  Breadcrumb,
  Icon,
  Button
  // Calendar,
  // Modal,
  // Button,
  // ButtonToolbar
} from 'rsuite';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
// import MyList from '../../components/MyList/MyList';
// import MyTable from '../../components/MyTable/MyTable';
import './Dashboard.css'
// import { 
//   getRole, 
//   // checkValidToken 
// } from "../../auth";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  // Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
// import users from '../../services/Users/index'

// const userResp = await users.getMe();

class Dashboard extends Component{
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      validToken: false,
      error: false,
      loadingPage: true,
      user: {}
      // expand: true,
      // myEmail: '',
      // myPassword: '',
      // loadingSubmit: false,
      // redirect: false,
      // servis: '',
      // user: {}
    };
    // if (authenticationService.currentUserValue) { 
    // this.props.history.push('/login');
    // }
    // window.location.reload(true)
  }

  componentDidMount() {
    // console.log("configs",configs.baseEndpoint.baseURL)
    this._isMounted = true;

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/me', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)
        // response.data.data.attributes.name
        this.setState({ user : response.data.data.attributes })
        this.setState({ error : false })
        this.setState({ loadingPage : false })
        this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        this.setState({ user : {} })
        this.setState({ error : true })
        this.setState({ loadingPage : true })
        this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // try {
    //   var yuu = await getRole();
    //   if (this._isMounted) {
    //     this.setState( { role: yuu } );
    //     this.setState( { validToken: true } );
    //   }  
    // } catch(error) {
    //   // this.setState( { validToken: false } );
    //   console.log('error',error.message)
    //   console.log('error',error.response.data)
    //   console.log('error',error.response.status)
    //   console.log('error',error.response.headers)
    //   if (this._isMounted) {
    //     this.setState( { validToken: false } );
    //   }
    // }

    // if (this._isMounted) {
    //     this.setState( { role: yuu } );
    // }

    
    // if (this._isMounted) {
    //   this.setState( { role: yuu } );
    // }
    // console.log('await getRole();')
    // this.setState( { role: yuu } );

    // if (this._isMounted) {
    //   this.setState( { role: yuu } );
    //   // await this.getUsers();
    //   // await this.getRoles();
    //   // this.setState( { role: 'student' } );
    // }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // async getTheRole() {
  //   try {
  //     const responseRole = await getRole();
  //     console.log('responseRole',responseRole)
  //   } catch(error) {
  //     console.log('error',error)

  //     console.log(error.response.data);
  //     console.log(error.response.status);
  //     console.log(error.response.headers);
  //     return <Redirect to='/login'/>;
  //   }
  //   // const responseRole = await getRole();
  //   // return responseRole;
  //   return "superadmin";
  // }

  render () {
    // console.log('xxx this.props',this.props)
    // console.log('xxx this.props',this.props.role)
    // console.log('xxx this.props.location.state.hallo',this.props.location.state.hallo)

    // console.log('this.state',this.state)
    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")
    // const user_role = this.state.role
    // const user_role = await this.getTheRole();
    // console.log('dashboard',this.props)
    // console.log('dashboard',this.state.role)
    // const xxx = localStorage.getItem("role")
    // console.log('from local', xxx)

    // const user_role = localStorage.getItem("role")

    // console.log('props',this.props)
    // if(this.state.validToken === false) {
    //   window.localStorage.clear()
    //   this.props.history.push('/login');
    //   // return <Redirect to='/login'/>;
    // }
    // let result = <Redirect to={{ pathname: '/buangan' }}/>;
    // if(!this.state.error) {
    //   result = <Loader size="lg" backdrop content="loading..." vertical />
    // }
    
    // let result = <Redirect to={{ pathname: '/buangan' }}/>;
    // if(!this.state.error) {
    //   // result = <div style={{textAlign: 'center'}}>Sukses</div>;
    //   result = <Loader size="lg" backdrop content="tunggu..." vertical />
    // }

    // let errorPage = <div style={{textAlign: 'center'}}>Error</div>;
    // let loadPage = <div>VVVV</div>;
    

    // let errorPage = <div style={{textAlign: 'center'}}>Error</div>;
    // if(!this.state.error) {
    //   if(this.state.loadingPage) {
    //     // loadPage = <Loader size="lg" backdrop content="tunggu..." vertical />;
    //   } else {
    //     if(!this.state.error) {
    //       errorPage = <div style={{textAlign: 'center'}}>Sukses</div>;
    //     }
    //   }
    //   errorPage = <div style={{textAlign: 'center'}}>Sukses</div>;
    // }

    // let checkToken = null;
    // if(!this.state.validToken) {
    //   checkToken = <Redirect to={{ pathname: '/login' }}/>;
    // }

    let thePage = <Loader size="lg" backdrop content="tunggu..." vertical />;
    if(!this.state.error) {
      // gak error
      if(this.state.loadingPage) {
        thePage = <Loader size="lg" backdrop content="tunggu..." vertical />;
      } else {
        thePage = (
          <Panel header="Selamat Datang" collapsible bordered defaultExpanded={true}>
            <h5><b>Nama </b>: {this.state.user.name}</h5>
            <h5><b>Email </b>: {this.state.user.email}</h5>
          </Panel>
        );
      }
    } else {
      // error
      thePage = <Loader size="lg" backdrop content="tunggu..." vertical />;
    }

    return (
      <>
        {/*
        {result}
        */}
        <LayoutRsuite role={this.props.role}/>

        <div className="dashboardContainer">
          <div className="jumbotron">
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>{path}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            {thePage}
          </div>
          <div>
            <Panel bordered defaultExpanded={true}>
              <div className="itemKeterangan">
                <i>*System ini masih Beta Version, dan akan terus dikembangkan.</i>
              </div>
              <div className="itemKeterangan">
                <i>*Bisa Menghubungi Admin jika ada yang ditanyakan.</i>
              </div>
              <div className="itemKeterangan">
                <i>*Untuk melihat Kelas tekan simbol Kalendar di "Side Menu", atau bisa tekan tombol dibawah ini.</i>
              </div>
              <div className="itemKeterangan">
                <Button 
                  color="green" 
                  href="/schedules"
                >
                  <Icon icon="calendar"  /> Jadwal Kelas
                </Button>
              </div>

              <div className="itemKeterangan">
                <Button 
                  color="green" 
                  href="/subject"
                >
                  <Icon icon="book"  /> Daftar Materi
                </Button>
              </div>
            {/*
              <div className="itemKeterangan">
                <Button 
                  color="green" 
                  href="/uploadtask"
                >
                  <Icon icon="book"  /> Upload Tugas Harian
                </Button>
              </div>
              <div className="itemKeterangan">
                <Button 
                  color="green" 
                  href="/uploadtask"
                >
                  <Icon icon="film"  /> Rekaman Perkuliahan
                </Button>
              </div>
            */}


            </Panel>
          </div>
          

          {
            // loadPage
            
            // if(this.state.loadingPage) {
            // }
            // console.log("VVVV", this)
          }

          {/*
          <MyTable />
          */}
          
        </div>
      </>
    );
  } 
}

export default Dashboard;
