import axios from 'axios'
import configs from '../../configs/index'
// import config from '@/configs'

export default axios.create({
  // baseURL: config.api.baseURL
  // production
  // baseURL: 'https://api.amithyainstitute.com/'
  // local
  // baseURL: 'http://amithya-institute-api/'

  baseURL: configs.baseEndpoint.baseURL
})
