import React, {Component} from 'react';
import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './Presences.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
// import forEach from "lodash/forEach"
import { 
  // Panel,
  // Button,
  // Icon,
  // Grid,
  // Row,
  // Col,
  // Modal,
  // Input,
  // SelectPicker,
  // ButtonToolbar,
  // Pagination,
  Breadcrumb,
  // Alert,
} from 'rsuite';


class Presences extends Component{
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // check role
    axios.get(configs.baseEndpoint.baseURL+'api/me?include=roles', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)

        const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        const role_name = response.data.included.roles[role_id].attributes.name
        window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  render () {

    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    return (
      <>
        <LayoutRsuite />
        <div className="presencesContainer">
          <div className="container">
            <div className="jumbotron">
              <Breadcrumb>
                <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
                <Breadcrumb.Item active>{path}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Presences;
