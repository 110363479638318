import React, {Component} from 'react'
import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './MaterialFile.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
// import forEach from "lodash/forEach"
import { 
  Panel,
  Button,
  Icon,
  Grid,
  Row,
  Col,
  Modal,
  Input,
  SelectPicker,
  ButtonToolbar,
  // Pagination,
  Breadcrumb,
  Alert,
} from 'rsuite';
import forEach from "lodash/forEach";


class MaterialFile extends Component{
  constructor(props) {
    super(props);
    this.state = {

      loadingSimpan: false,

      valueModalFileType: "",
      valueModalFilePath: "",
      editValueModalFilePath: "",

      subModuleNameSelected: "",

      modalSubmoduleName: "",
      modalSubmoduleId: "",

      actionItem: "new",
      show: false,
      showModalFile: false,
      showModalEditPath: false,

      subModules: [],

      materialFiles: [],

      filterSubModulesData: [],
      submodulesFilterSelected: null,

      addFileSubModulesParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "created_at"
      },

      subModulesParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "created_at"
      },

      courseMaterialsParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at"
      },


      courseMaterialEditFileTypeSelected: "",
      courseMaterialEditPathSelected: "",
      courseMaterialEditDataSelected: {},

      addFileSubModulesSelected: null,
      addFileSubModulesData: [],
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.openModalFile = this.openModalFile.bind(this);
    this.closeModalFile = this.closeModalFile.bind(this);

    this.openModalEditPath = this.openModalEditPath.bind(this);
    this.closeModalEditPath = this.closeModalEditPath.bind(this);

    this.handleSelectFilterSubModules = this.handleSelectFilterSubModules.bind(this);
    this.handleChangeFilterSubModules = this.handleChangeFilterSubModules.bind(this);
    this.handleSearchFilterSubModules = this.handleSearchFilterSubModules.bind(this);

    this.filterSubModulesClean = this.filterSubModulesClean.bind(this);

    this.searchFilter = this.searchFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);

    this.getSubModuleFiles = this.getSubModuleFiles.bind(this);
    this.editSubModuleFiles = this.editSubModuleFiles.bind(this);

    this.addSubModuleFiles = this.addSubModuleFiles.bind(this);

    this.addFileSubModulesSelect = this.addFileSubModulesSelect.bind(this); 
    this.addFileSubModulesSearch = this.addFileSubModulesSearch.bind(this); 

    this.addFileSubModulesClean = this.addFileSubModulesClean.bind(this);

    this.handleModalFilePath = this.handleModalFilePath.bind(this);
    this.handleModalFileType = this.handleModalFileType.bind(this);

    this.simpanHandler = this.simpanHandler.bind(this);

    this.handleLinkButton = this.handleLinkButton.bind(this);

    this.handleSimpanLinkBaru = this.handleSimpanLinkBaru.bind(this);

    this.handleModalEditFilePath = this.handleModalEditFilePath.bind(this);

    this.handleButtonEditLinkBaru =this.handleButtonEditLinkBaru.bind(this);
    this.simpanModalEditPath = this.simpanModalEditPath.bind(this);
    
    this.handleInputcourseMaterialEditPathSelected = this.handleInputcourseMaterialEditPathSelected.bind(this);
    this.handleInputcourseMaterialEditFileTypeSelected = this.handleInputcourseMaterialEditFileTypeSelected.bind(this);

    this.handleDelLinkButton = this.handleDelLinkButton.bind(this);

    // this.closeModalEditPath = this.closeModalEditPath.bind(this);

    // this.handleName = this.handleName.bind(this);
  }

  openModalEditPath(size) {
    this.setState({
      size,
      showModalEditPath: true,
    });
  }

  closeModalEditPath() {
    this.setState({
      showModalEditPath: false,
      courseMaterialEditPathSelected: "",
      // modalSubmoduleId: "",
      // modalSubmoduleName: "",
      // valueCourseSelected: null,
      // valueName: "",
      // valueDescription: ""
    });
  }

  openModalFile(size) {
    this.setState({
      size,
      showModalFile: true,
    });
  }

  closeModalFile() {
    this.setState({
      showModalFile: false,

      // modalSubmoduleId: "",
      // modalSubmoduleName: "",
      // valueCourseSelected: null,
      // valueName: "",
      // valueDescription: ""
    });
  }

  open(size) {
    // console.log("this.state.valueCourses",this.state.valueCourses)
    this.setState({
      size,
      show: true,
    });
  }

  close() {
    this.setState({
      show: false,
      modalSubmoduleId: "",
      modalSubmoduleName: "",
      valueModalFilePath: "",
      valueModalFileType: "",
      // valueCourseSelected: null,
      // valueName: "",
      // valueDescription: ""
    });
  }

  componentDidMount() {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // check role
    axios.get(configs.baseEndpoint.baseURL+'api/me?include=roles', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)

        const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        const role_name = response.data.included.roles[role_id].attributes.name
        window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  filterSubModulesClean() {
    console.log("filterSubModulesClean")
  }

  handleSelectFilterSubModules(value) {
    // console.log("handleSelectFilterSubModules", value)
    this.setState({
      submodulesFilterSelected: value,
    })
  }

  handleChangeFilterSubModules(value) {
    console.log("handleChangeFilterSubModules", value)
  }

  handleSearchFilterSubModules(value) {
    console.log("handleSearchFilterSubModules", value)

    let subModulesParam = this.state.subModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      // "filter[module_id][is]": this.state.modulesFilterSelected,
      // "filter[parent_id][null]": true,
      "filter[name][like]": value,
    }

    // if(this.state.modulesFilterSelected !== null) {
    //   newSubModulesParam["filter[module_id][is]"] = this.state.modulesFilterSelected
    // }

    // console.log("handleSearchFilterSubModules", newSubModulesParam)

    // this.getSubModules(newSubModulesParam)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: newSubModulesParam
    })
      .then( response => {
        console.log("response sub modules",response)
        // filterSubModulesData
        // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {

          let subModules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrSubmodules.push(subModules)
        })
        this.setState({ filterSubModulesData: arrSubmodules })

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)

        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  searchFilter() {
    console.log("searchFilter")

    let subModulesParam = this.state.subModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      // "filter[id][is]": this.state.submodulesFilterSelected,
    }

    if(this.state.submodulesFilterSelected !== null) {
      newSubModulesParam["filter[id][is]"] = this.state.submodulesFilterSelected
    }

    // console.log("handleSearchFilterSubModules", newSubModulesParam)

    // this.getSubModules(newSubModulesParam)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: newSubModulesParam
    })
      .then( response => {
        // console.log("response sub modules",response)
        // filterSubModulesData
        // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {

          let subModules = {
            "created_at": item.attributes.created_at,
            "description": item.attributes.description,
            "id": item.attributes.id,
            "level": item.attributes.level,
            "module_id": item.attributes.module_id,
            "name": item.attributes.name,
            "parent_id": item.attributes.parent_id,
            "updated_at": item.attributes.updated_at
          }
          arrSubmodules.push(subModules)

        })
        this.setState({ subModules: arrSubmodules })

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)

        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  resetFilter() {
    // console.log("resetFilter")
    this.setState({submodulesFilterSelected: null})
  }

  getSubModuleFiles(data) {
    console.log("getSubModuleFiles", data)

    const sub_modules_id = data.id
    let newCourseMaterialsParam = this.state.courseMaterialsParam

    newCourseMaterialsParam["filter[sub_modules_id][is]"] = sub_modules_id

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // axios.get('https://api.amithyainstitute.com/api/testbbb/getrecord', 
    axios.get(configs.baseEndpoint.baseURL+'/api/coursematerials', {
      headers: { 'Authorization': tok },
      params: newCourseMaterialsParam,
    })
      .then( response => {
        console.log("response.data.data",response.data.data)
        let arrMaterialFiles = []
        forEach(response.data.data, item => {
          // console.log("item.attendees => ",item.attendees)

          let files = {
            "file_path": item.attributes.file_path,
            "file_type": item.attributes.file_type,
            "id": item.attributes.id,
            "sub_modules_id": item.attributes.sub_modules_id,
          }
          arrMaterialFiles.push(files)
        })
        this.setState({ materialFiles : arrMaterialFiles })
        this.setState({ subModuleNameSelected : data.name })
        // getSubModuleFiles
        // this.setState({ editValueModalFilePath : arrMaterialFiles })

      } )
      .catch( error => {
        console.log("error",error)
        
      });

      this.openModalFile("xs")
  }

  editSubModuleFiles(data) {

    console.log("editSubModuleFiles", data)

    const sub_modules_id = data.id
    let newCourseMaterialsParam = this.state.courseMaterialsParam

    newCourseMaterialsParam["filter[sub_modules_id][is]"] = sub_modules_id

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // axios.get('https://api.amithyainstitute.com/api/testbbb/getrecord', 
    axios.get(configs.baseEndpoint.baseURL+'/api/coursematerials', {
      headers: { 'Authorization': tok },
      params: newCourseMaterialsParam,
    })
      .then( response => {
        console.log("response.data",response.data)
        // let arrRooms = []
        // forEach(response.data, item => {
        //   console.log("item.attendees => ",item.attendees)

        //   let rooms = {
        //     "id": item.meetingID,
        //     "maxUsers": item.maxUsers,
        //     "meetingName": item.meetingName, 
        //     "attendees": item.attendees.length,
        //     "attendeePW": item.attendeePW,
        //     "moderatorPW": item.moderatorPW,
        //     "participantCount": item.participantCount,
        //     "running": item.running
        //   }
        //   arrRooms.push(rooms)
        // })
        // this.setState({ rooms : arrRooms })

      } )
      .catch( error => {
        console.log("error",error)
        
      });
  }

  addSubModuleFiles(data={}) {
    console.log("addSubModuleFiles",data)
    this.setState({actionItem: "new"})
    this.setState({modalSubmoduleName: data.name})
    this.setState({modalSubmoduleId: data.id})
    this.open("xs")
  }

  addFileSubModulesSelect(value) {
    // console.log("addFileSubModulesSelect", value)
    this.setState({addFileSubModulesSelected: value})
  }

  addFileSubModulesSearch(value) {
    console.log("addFileSubModulesSearch", value)

    let subModulesParam = this.state.addFileSubModulesParam
    let newSubModulesParam = {
      ...subModulesParam,
      // "filter[module_id][is]": this.state.modulesFilterSelected,
      // "filter[parent_id][null]": true,
      "filter[name][like]": value,
    }

    // if(this.state.modulesFilterSelected !== null) {
    //   newSubModulesParam["filter[module_id][is]"] = this.state.modulesFilterSelected
    // }

    // console.log("handleSearchFilterSubModules", newSubModulesParam)

    // this.getSubModules(newSubModulesParam)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get submodules
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.subModulesParam
      params: newSubModulesParam
    })
      .then( response => {
        console.log("response sub modules",response)
        // filterSubModulesData
        // subModules
        let arrSubmodules = []
        forEach(response.data.data, item => {

          let subModules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrSubmodules.push(subModules)
        })
        this.setState({ addFileSubModulesData: arrSubmodules })

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)

        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  addFileSubModulesClean() {
    // console.log("addFileSubModulesClean")
    this.setState({
      addFileSubModulesSelected: null,
    })
  }

  handleModalFilePath(value) {
    // console.log("handleModalFilePath",value)
    this.setState({ valueModalFilePath: value })
  }

  handleModalFileType(value) {
    this.setState({ valueModalFileType: value })
  }

  simpanHandler() {
    // course_material table
    // sub_modules_id
    // file_path
    
    let payload = {
      "sub_modules_id" : this.state.modalSubmoduleId,
      "file_path" : this.state.valueModalFilePath,
      "file_type" : this.state.valueModalFileType,
    }

    // console.log("payload",payload)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // axios.get('https://api.amithyainstitute.com/api/testbbb/getrecord', 
    axios.post(configs.baseEndpoint.baseURL+'/api/coursematerials', 
      payload, {
      headers: { 'Authorization': tok },
      params: {},
    })
      .then( response => {
        console.log("response.data",response.data)
        // let arrRooms = []
        // forEach(response.data, item => {
        //   console.log("item.attendees => ",item.attendees)

        //   let rooms = {
        //     "id": item.meetingID,
        //     "maxUsers": item.maxUsers,
        //     "meetingName": item.meetingName, 
        //     "attendees": item.attendees.length,
        //     "attendeePW": item.attendeePW,
        //     "moderatorPW": item.moderatorPW,
        //     "participantCount": item.participantCount,
        //     "running": item.running
        //   }
        //   arrRooms.push(rooms)
        // })
        // this.setState({ rooms : arrRooms })
        if(response.data.success) {
          Alert.success("Tambah Berhasil", 5000)
          this.close()
        }
      } )
      .catch( error => {
        console.log("error",error)
        
      });
  }

  handleLinkButton(data) {
    // console.log("handleLinkButton",data.file_path)
    window.open(data.file_path, "_blank")
  }

  handleModalEditFilePath(value) {
    console.log("handleModalEditFilePath",value)
    this.setState({ editValueModalFilePath: value })
  }

  handleSimpanLinkBaru(data) {
    console.log("handleSimpanLinkBaru data", data)
    console.log("handleSimpanLinkBaru editValueModalFilePath", this.state.editValueModalFilePath)
    const payload = {
      "file_path": this.state.editValueModalFilePath,
    }

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // axios.get('https://api.amithyainstitute.com/api/testbbb/getrecord', 
    axios.put(configs.baseEndpoint.baseURL+'/api/coursematerials/'+data.id, 
      payload, {
      headers: { 'Authorization': tok },
      params: {},
    })
      .then( response => {
        console.log("response.data",response.data)
        // let arrRooms = []
        // forEach(response.data, item => {
        //   console.log("item.attendees => ",item.attendees)

        //   let rooms = {
        //     "id": item.meetingID,
        //     "maxUsers": item.maxUsers,
        //     "meetingName": item.meetingName, 
        //     "attendees": item.attendees.length,
        //     "attendeePW": item.attendeePW,
        //     "moderatorPW": item.moderatorPW,
        //     "participantCount": item.participantCount,
        //     "running": item.running
        //   }
        //   arrRooms.push(rooms)
        // })
        // this.setState({ rooms : arrRooms })
        if(response.data.success) {
          Alert.success('Update Berhasil', 5000)
        }

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
      });
  }

  handleButtonEditLinkBaru(data) {
    console.log("handleButtonEditLinkBaru",data)
    // data.file_type
    this.setState({ courseMaterialEditFileTypeSelected: data.file_type })
    this.setState({ courseMaterialEditPathSelected: data.file_path })
    this.setState({ courseMaterialEditDataSelected: data })
    this.openModalEditPath("xs")
  }

  handleInputcourseMaterialEditPathSelected(value) {
    console.log("handleInputcourseMaterialEditPathSelected", value)
    this.setState({ courseMaterialEditPathSelected: value })
  }

  handleInputcourseMaterialEditFileTypeSelected(value) {
    console.log("handleInputcourseMaterialEditFileTypeSelected", value)
    this.setState({ courseMaterialEditFileTypeSelected: value })
  }

  simpanModalEditPath() {
    // console.log("this.state.courseMaterialEditDataSelected",this.state.courseMaterialEditDataSelected)
    // console.log("simpanModalEditPath",this.state.courseMaterialEditPathSelected)


    const id = this.state.courseMaterialEditDataSelected.id
    let payload = {
      "file_path": this.state.courseMaterialEditPathSelected,
      "file_type" : this.state.courseMaterialEditFileTypeSelected,
    }

    console.log("payload",payload)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // axios.get('https://api.amithyainstitute.com/api/testbbb/getrecord', 
    axios.put(configs.baseEndpoint.baseURL+'/api/coursematerials/'+id, 
      payload, {
      headers: { 'Authorization': tok },
      params: {},
    })
      .then( response => {
        console.log("response.data",response.data)
        console.log("this.state.materialFiles",this.state.materialFiles)
        // response.data.data.attributes
        // this.state.materialFiles
        let materialFiles = this.state.materialFiles
        let arrmaterialFiles = []
        forEach(materialFiles, item => {
          if(item.id !== this.state.courseMaterialEditDataSelected.id) {
            arrmaterialFiles.push(item)
          } else {
            arrmaterialFiles.push(response.data.data.attributes)
          }
        //   console.log("item.attendees => ",item.attendees)

        //   let rooms = {
        //     "id": item.meetingID,
        //     "maxUsers": item.maxUsers,
        //     "meetingName": item.meetingName, 
        //     "attendees": item.attendees.length,
        //     "attendeePW": item.attendeePW,
        //     "moderatorPW": item.moderatorPW,
        //     "participantCount": item.participantCount,
        //     "running": item.running
        //   }
          // arrmaterialFiles.push(rooms)
        })
        this.setState({ materialFiles : arrmaterialFiles })

        if(response.data.success) {
          this.closeModalEditPath()
          Alert.success('Update Berhasil', 5000)
        }

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
      });
  }

  handleDelLinkButton(data) {
    // console.log("handleDelLinkButton", data)

    this.setState({ loadingSimpan: true })

    const id = data.id
    
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;
    
    axios.delete(configs.baseEndpoint.baseURL+'/api/coursematerials/'+id, {
      headers: { 'Authorization': tok },
      params: {},
    })
      .then( response => {
        // console.log("response.data",response.data)
        // console.log("this.state.materialFiles",this.state.materialFiles)
        
        let arrFiles = []
        forEach(this.state.materialFiles, item => {
          if(item.id !== data.id) {
            // file_path: "padamu"
            // file_type: null
            // id: 7
            // sub_modules_id: 1
            arrFiles.push(item)
          }

        })
        this.setState({ materialFiles : arrFiles })
        
        if(response.data.success) {

          Alert.success(response.data.message, 5000)
        }

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
      });

      this.setState({ loadingSimpan: false })
  }

  render () {

    // modalSubmoduleName
    // modalSubmoduleId
    // console.log("this.state.modalSubmoduleName",this.state.modalSubmoduleName)
    // console.log("this.state.modalSubmoduleId",this.state.modalSubmoduleId)
    // console.log("this.state.materialFiles",this.state.materialFiles)

    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let items = (
      <Col xs={24}>
        <div className="myCard">
          <Panel shaded >
            <Panel>
              <Grid fluid>
                <Row>
                  <Col xs={24} >
                    <h5>Data Kosong</h5>
                    <h5>Pilih dari filter ...</h5>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Panel>
        </div>
      </Col>
    );

    if(this.state.subModules.length > 0) {
      // console.log("subModules ada isi nya",this.state.subModules)

      items = this.state.subModules.map((subModules) => 
      //   console.log("modules",modules)
        <Col xs={24} id={`submodules_id_${subModules.id}`} key={subModules.id}>
          <div className="myCard">
            <Panel shaded >
              <Panel 
                header={subModules.name} 
                collapsible 
                defaultExpanded={false}
              >
                <Grid fluid>
                  <Row>
                    <Col xs={24} >
                      <div>
                        <p>
                          <b>Id : </b><small>{subModules.id}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Name : </b><small>{subModules.name}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Deskripsi : </b><small>{subModules.description}</small>
                        </p>
                      </div>
                      
                      
                      <div>
                        <ButtonToolbar>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.addSubModuleFiles(subModules)}}
                          >
                            <Icon icon="plus-square" /> Tambah File
                          </Button>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.getSubModuleFiles(subModules)}}
                          >
                            <Icon icon="book" /> Lihat File
                          </Button>
                          {/*
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.editSubModuleFiles(subModules)}}
                          >
                            <Icon icon="pencil" /> Edit
                          </Button>


                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleEditSubModules(subModules)}}
                          >
                            <Icon icon="sign-in" /> Edit
                          </Button>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleDeleteSubModules(subModules)}}
                          >
                            <Icon icon="sign-in" /> Delete
                          </Button>
                          <Button 
                            color="cyan"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekParent(subModules)}}
                          >
                            Cek Parent
                          </Button>
                          <Button 
                            color="orange"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekChild(subModules)}}
                          >
                            Cek Child
                          </Button>
                          */}
                        </ButtonToolbar>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel>  
            </Panel>
          </div>
        </Col>
        
      )
    }

    let listFile = (
      <Col xs={20}>
        <div className="myCard">
          <Panel shaded >
            <Panel>
              <Grid fluid>
                <Row>
                  <Col xs={24} >
                    <h5>Data Kosong</h5>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Panel>
        </div>
      </Col>
    );

    if(this.state.materialFiles.length > 0) {
      // file_path: "wan awn"
      // file_type: null
      // id: 3
      // sub_modules_id: 1
      listFile = this.state.materialFiles.map((materialFile) => 
        // console.log("materialFile",materialFile)
        
        <Col xs={20} key={materialFile.id}>
          <div className="myCard">
            <Panel shaded >
              <Panel>
                <Grid fluid>
                  <Row className="viewListItem">
                    <Col xs={24} >
                      <b>Link :</b>
                    </Col>
                    <Col xs={24} >
                      <i>{materialFile.file_path}</i>
                    </Col>
                  </Row>
                  <Row className="viewListItem">
                    <Col xs={24} >
                      <b>File Type :</b>
                    </Col>
                    <Col xs={24} >
                      <i>{materialFile.file_type}</i>
                    </Col>
                  </Row>
                  {/*
                  <Row className="viewListItem">
                    <Col xs={24} >
                      <b>Edit Langsung isi disini :</b>
                    </Col>
                    <Col xs={24} >
                      <Input 
                        value={this.state.editValueModalFilePath} 
                        onChange={(value) => {this.handleModalEditFilePath(value)}} 
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Path File" 
                        key={materialFile.id}
                      />
                    </Col>
                  </Row>
                  */}
                  <Row className="viewListItem">
                    <Col xs={24} >
                      <Button 
                        color="green" 
                        key={materialFile.id}
                        block
                        onClick={() => {this.handleButtonEditLinkBaru(materialFile)}}
                      >
                        <Icon icon="pencil"  /> Ubah
                      </Button>
                    </Col>
                  </Row>
                  {/*
                  <Row className="viewListItem">
                    <Col xs={24} >
                      <Button 
                        color="green" 
                        key={materialFile.id}
                        block
                        onClick={() => {this.handleSimpanLinkBaru(materialFile)}}
                      >
                        <Icon icon="save"  /> Simpan URL Baru
                      </Button>
                    </Col>
                  </Row>
                  */}

                  <Row className="viewListItem">
                    <Col xs={24} >
                      <Button 
                        color="cyan" 
                        key={materialFile.id}
                        block
                        onClick={() => {this.handleLinkButton(materialFile)}}
                      >
                        <Icon icon="external-link"  /> Go To URL
                      </Button>
                    </Col>
                  </Row>

                  <Row className="viewListItem">
                    <Col xs={24} >
                      <Button 
                        loading={this.state.loadingSimpan}
                        color="red" 
                        key={materialFile.id}
                        block
                        onClick={() => {this.handleDelLinkButton(materialFile)}}
                      >
                        <Icon icon="external-link"  /> Hapus
                      </Button>
                    </Col>
                  </Row>
                </Grid>
              </Panel>
            </Panel>
          </div>
        </Col>

      )
    }

    // this.state.materialFiles
    // this.state.courseMaterialEditPathSelected

    return (
      <>
        <LayoutRsuite />
        <div className="presencesContainer">
          <div className="container">
            <div className="jumbotron">
              <Breadcrumb>
                <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
                <Breadcrumb.Item active>{path}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="jumbotron">
              {/*
              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Action" collapsible bordered defaultExpanded={false}>
                      <Button 
                        color="green" 
                        onClick={() => this.addSubModuleFiles()}
                      >
                        <Icon icon='plus-square' />
                      </Button>
                    </Panel>
                  </Col>
                </Row>
              </Grid>
              */}

              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Filter" collapsible bordered defaultExpanded={false}>
                      <Col xs={16}>
                        <SelectPicker
                          onClean={this.filterSubModulesClean}
                          onSelect={(value) => {this.handleSelectFilterSubModules(value)}}
                          onChange={(value) => {this.handleChangeFilterSubModules(value)}}
                          onSearch={(value) => {this.handleSearchFilterSubModules(value)}}
                          data={this.state.filterSubModulesData}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Nama Sub Modules"
                          value={this.state.submodulesFilterSelected}
                        />
                      </Col>
                      <Col xs={16}>
                        <Button 
                          color="blue"
                          appearance="primary" 
                          size="xs"
                          onClick={this.searchFilter}
                          style={{ marginBottom: 10 }}
                        >
                          Search
                        </Button>
                      </Col>
                      <Col xs={16}>
                        <Button
                          color="green" 
                          appearance="primary" 
                          size="xs"
                          onClick={this.resetFilter}
                          style={{ marginBottom: 10 }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Modal size={this.state.size} show={this.state.showModalEditPath} onHide={this.closeModalEditPath}>
                <Modal.Header>
                  <Modal.Title>Edit Path</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid fluid>
                    <Row className="">
                      Path URL :
                    </Row>
                    <Row className="">
                      <Input 
                        value={this.state.courseMaterialEditPathSelected} 
                        onChange={this.handleInputcourseMaterialEditPathSelected} 
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Path File" 
                      />
                    </Row>
                    <Row className="">
                      File Type :
                    </Row>
                    <Row className="">
                      <Input 
                        value={this.state.courseMaterialEditFileTypeSelected} 
                        onChange={this.handleInputcourseMaterialEditFileTypeSelected} 
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Path File" 
                      />
                    </Row>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    // onClick={() => {this.handleSimpanLinkBaru(materialFile)}} 
                    onClick={this.simpanModalEditPath}
                    color="violet"
                    loading={this.state.loadingSimpan}
                   >
                    Simpan
                  </Button>
                  <Button onClick={this.closeModalEditPath} appearance="subtle">
                    Tutup
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal size={this.state.size} show={this.state.showModalFile} onHide={this.closeModalFile}>
                <Modal.Header>
                  <Modal.Title>Lihat File Materi Sub Modules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid fluid>
                    <Row className="">
                      <h5>{this.state.subModuleNameSelected} : </h5>
                    </Row>
                  </Grid>
                  <Grid fluid>
                    <Row className="">
                      {listFile}
                    </Row>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  {/*
                  <Button 
                    onClick={this.simpanHandler}
                    color="violet"
                    loading={this.state.loadingSimpan}
                   >
                    Simpan
                  </Button>
                  */}
                  <Button onClick={this.closeModalFile} appearance="subtle">
                    Tutup
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
                <Modal.Header>
                  {/*
                  <Modal.Title>{action} Modules</Modal.Title>
                  */}
                  <Modal.Title>Tambah File Sub Modules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid fluid>
                    <Row className="">
                      {/*
                      <Col xs={18}>
                        Pilih Sub Module :
                      </Col>
                      <Col xs={16}>
                        <SelectPicker
                          onClean={this.addFileSubModulesClean}
                          onSelect={(value) => {this.addFileSubModulesSelect(value)}}
                          onSearch={(value) => {this.addFileSubModulesSearch(value)}}
                          data={this.state.addFileSubModulesData}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Sub Modules"
                          value={this.state.addFileSubModulesSelected}
                        />
                      </Col>
                      */}
                      <Col xs={18}>
                        Id Sub Module :
                      </Col>
                      <Col xs={16}>
                        <b>{this.state.modalSubmoduleId}</b>
                      </Col>
                      <Col xs={18}>
                        Nama Sub Module :
                      </Col>
                      <Col xs={16}>
                        <b>{this.state.modalSubmoduleName}</b>
                      </Col>
                    {/*
                      <Col xs={18}>
                        Ekstensi File :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueName} 
                          onChange={this.handleName} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Ekstensi File" 
                        />
                      </Col>
                    */}

                      <Col xs={18}>
                        Path File :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueModalFilePath} 
                          onChange={this.handleModalFilePath} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Path File" 
                        />
                      </Col>

                      <Col xs={18}>
                        Tipe File :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueModalFileType} 
                          onChange={this.handleModalFileType} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Tipe File" 
                        />
                      </Col>

                      {/*
                      <Col xs={18}>
                        Nama Sub Module :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueName} 
                          onChange={this.handleName} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Nama Sub Modul" 
                        />
                      </Col>
                      <Col xs={18}>
                        Tentukan Module :
                      </Col>
                      <Col xs={16}>
                        <SelectPicker
                          onClean={this.filterModulesClean}
                          onSelect={(value) => {this.modulesSelect(value)}}
                          onSearch={(value) => {this.modulesSearch(value)}}
                          data={this.state.modules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Modules"
                          value={this.state.modulesSelected}
                        />
                      </Col>
                      <Col xs={18}>
                        Tentukan Parent Sub Module :
                      </Col>
                      <Col xs={16}>
                        <SelectPicker
                          onClean={this.filterModulesClean}
                          onSelect={(value) => {this.subModulesSelect(value)}}
                          onSearch={(value) => {this.subModulesSearch(value)}}
                          data={this.state.subModules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Sub Modules"
                          value={this.state.subModulesSelected}
                        />
                      </Col>
                      
                      <Col xs={18}>
                        Deskripsi :
                      </Col>
                      <Col xs={16}>
                        <Input 
                          value={this.state.valueDeskripsi} 
                          onChange={this.handleDeskripsi} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Deskripsi Sub Modul" 
                        />
                      </Col>



                      const thesubmodules = {
                        "created_at": item.attributes.created_at,
                        "description": item.attributes.description,
                        "id": item.attributes.id,
                        "level": item.attributes.level,
                        "module_id": item.attributes.module_id,
                        "name": item.attributes.name,
                        "parent_id": item.attributes.parent_id,
                        "updated_at": item.attributes.updated_at
                      }
                      */}
                      {/*
                      <Input value={this.state.valueDate} onChange={this.handleDate} style={{ marginBottom: 10 }} size="md" placeholder="Tanggal" />
                      */}
                     {/*
                      <Col xs={18}>
                        <DatePicker
                          value={this.state.valueDate}
                          onChange={(value) => {this.dateChange(value)}}
                          style={{ marginBottom: 10 }}
                          format="YYYY-MM-DD HH:mm:ss"
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date()
                            }
                          ]}
                        />

                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.modulesChange(value)}}
                          onSearch={(value) => {this.modulesSearch(value)}}
                          data={this.state.modulesData}
                          value={this.state.valueModules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Modules"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.periodesChange(value)}}
                          onSearch={(value) => {this.periodesSearch(value)}}
                          data={this.state.periodesData}
                          value={this.state.valuePeriodes}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Periodes"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onSelect={(value) => {this.coursesSelectedSelect(value)}}
                          onChange={(value) => {this.coursesSelectedChange(value)}}
                          onSearch={(value) => {this.coursesSelectedSearch(value)}}
                          data={this.state.coursesSelectedData}
                          value={this.state.valueCourseSelected}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Courses"
                        />
                      </Col>
                      <Col xs={18}>
                        <Input 
                          value={this.state.valueName} 
                          onChange={this.handleName} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Nama Modul" 
                        />
                      </Col>

                      <Col xs={18}>
                        <Input 
                          value={this.state.valueDescription} 
                          onChange={this.handleDescription} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Deskripsi" 
                        />
                      </Col>
                     */}
                    </Row>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  <Button 
                    onClick={this.simpanHandler}
                    color="violet"
                    loading={this.state.loadingSimpan}
                   >
                    Simpan
                  </Button>
                  <Button onClick={this.close} appearance="subtle">
                    Batal
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>

            <div>
              <Grid fluid>
                <Row>
                  {items}
                </Row>
              </Grid>
            </div>

          </div>
        </div>
      </>
    )
  }
}

export default MaterialFile;
