import React, {Component} from "react"
import LayoutRsuite from "../../hoc/LayoutRsuite/LayoutRsuite"
import MyTable from "../../components/MyTable/MyTable"
import "./Users.css"
import { getRole } from "../../auth"
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom"
import { 
  Panel,
  Button,
  Icon,
  Grid,
  Row,
  Col,
  Modal,
  Input,
  SelectPicker,
  Pagination,
  Breadcrumb,
  Alert
} from "rsuite"

import users from "../../services/Users/index"
import roles from "../../services/Roles/index"
import forEach from "lodash/forEach"

class Users extends Component{
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      role: null,
      users: [],
      show: false,
      valueRoleSelect: null,
      valueNama: "",
      valueEmail: "",
      valueNim: "",
      valuePass: "",
      valueKonfPass: "",
      selectData: [],
      createNew: true,
      editId: null,
      activePage: 1,
      limitUserPagination: 10,
      countedTotalPage: 1,
      maxButtonUserPagination: 1,
    }

    this.close = this.close.bind(this)
    this.open = this.open.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleNama = this.handleNama.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.handleNim = this.handleNim.bind(this)
    this.handlePass = this.handlePass.bind(this)
    this.handleKonfPass = this.handleKonfPass.bind(this)
    this.simpanHandler = this.simpanHandler.bind(this)
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this)
    // this.parentFunc = this.parentFunc.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true

    let yuu = await this.getTheRole()
    // this.setState( { role: yuu } );

    if (this._isMounted) {
      this.setState( { role: yuu } )
      await this.getUsers()
      await this.getRoles()
      // this.setState( { role: 'student' } );
    }

    // const user_role = localStorage.getItem("role")
    // if(user_role !== "superadmin") {
    //   return <Redirect to='/dashboard'/>;
    // }

    // await this.getUsers();
    // await this.getRoles();
    // await this.delUsers();
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async delUsers(id) {
    // console.log('delUsers',id);
    const delUserResp = await users.deleteUsers(id)
    if(delUserResp.data.success) {
      Alert.success("Hapus Sukses", 5000)
    } else {
      Alert.success("Server Error", 5000)
    }
    // console.log('after delete',delUserResp)
  }

  async getRoles() {
    const opts = {
      params: {
          "page[num]": 1,
          "page[limit]": 10,
          "sort": "-created_at",
        // include: "items,invoices,platforms,parent,child,packaging,user"
        //   page: this.tablePagination.page,
        //   limit: this.tablePagination.rowsPerPage,
        //   sortedBy: this.tablePagination.descending ? "desc" : "asc",
        //   orderBy: this.tablePagination.sortBy,
        //   include: "details.ref.images,details.ref.variation,user"
      }
    }
    const rolesResp = await roles.getRoles(opts)
    const theRoles = rolesResp.data.data

    let arrRoles = []
    forEach(theRoles, role => {
      // console.log('user',user)
      
      let item = {
        // "id": role.attributes.id,
        // "name": role.attributes.name,
        "label": role.attributes.name,
        // "value": role.attributes.name
        "value": role.attributes.id
      }
      arrRoles.push(item)
    })
    this.setState( { selectData: arrRoles } )
    // console.log('this.state.selectData',this.state.selectData)
    // console.log('theRoles',theRoles)
  }

  async getUsers(page=1) {
    const opts = {
      params: {
          // "page[num]": 1,
          "page[num]": page,
          "page[limit]": this.state.limitUserPagination,
          "sort": "-created_at",
        // include: "items,invoices,platforms,parent,child,packaging,user"
        //   page: this.tablePagination.page,
        //   limit: this.tablePagination.rowsPerPage,
        //   sortedBy: this.tablePagination.descending ? "desc" : "asc",
        //   orderBy: this.tablePagination.sortBy,
        //   include: "details.ref.images,details.ref.variation,user"
      }
    }
    const userResp = await users.getUsers(opts)
    // console.log('userResp',userResp.data.meta.pagination.total)
    const totalItem = userResp.data.meta.pagination.total
    // set total page
    const totalPage = Math.ceil(totalItem/this.state.limitUserPagination)
    // console.log(totalPage);
    this.setState( { countedTotalPage: totalPage } )

    // set Max button
    // const maxButton = Math.ceil(totalItem/this.state.limitUserPagination);
    const maxButtonUserPagination = Math.floor(totalPage/this.state.limitUserPagination)
    // 
    // totalPage/this.state.limitUserPagination
    // console.log(maxButtonUserPagination);
    this.setState( { maxButtonUserPagination: maxButtonUserPagination } )

    const theUsers = userResp.data.data

    let arrUser = []
    forEach(theUsers, user => {
      // console.log('user',user)

      let theNim = user.attributes.nim
      if(user.attributes.nim === null) {
        theNim = '-'
      }
      
      let item = {
        "id": user.attributes.id,
        "email": user.attributes.email,
        "name": user.attributes.name,
        "role_id": user.attributes.role_id,
        "nim": theNim,
      }
      arrUser.push(item)
    })
    this.setState( { users: arrUser } )
  }

  async getUserById(id) {
    const userResp = await users.getOneUser(id)
    const userData = userResp.data;
    // set to state
    this.setState({
      valueRoleSelect: userData.data.attributes.role_id,
      valueNama: userData.data.attributes.name,
      valueEmail: userData.data.attributes.email,
      valueNim: userData.data.attributes.nim,
      // valuePass: userData.data.attributes.password,
      // valueKonfPass: userData.data.attributes.password
    });
    this.open('xs');
  }

  async getTheRole() {
    const responseRole = await getRole()
    return responseRole
  }

  close() {
    this.setState({
      show: false,
      valueRoleSelect: null,
      valueNama: '',
      valueEmail: '',
      valueNim: '',
      valuePass: '',
      valueKonfPass: ''
    });
  }
  open(size) {
    this.setState({
      size,
      show: true,
    })
  }

  handleNama(value) {
    // console.log(value)
    this.setState( { valueNama: value } )
  }
  handleEmail(value) {
    // console.log(value)
    this.setState( { valueEmail: value } )
  }
  handleNim(value) {
    // console.log(value)
    this.setState( { valueNim: value } )
  }
  handlePass(value) {
    // console.log(value)
    this.setState( { valuePass: value } )
  }
  handleKonfPass(value) {
    // console.log(value)
    this.setState( { valueKonfPass: value } )
  }
  handleSelectChange(value) {
    // console.log(value)
    this.setState( { valueRoleSelect: value } )
  }

  async delMethod(data) {
    // console.log('dari parent //bar', data);
    await this.delUsers(data)
    await this.getUsers()
  }

  async editMethod(data) {
    // console.log('dari parent //bar EDIT', data);
    this.setState( { editId: data } )
    await this.getUserById(data)
  }

  // submitHandler = async () => {

  // }

  async simpanHandler() {

    // let thePayload = {
    //   "role_id": this.state.valueRoleSelect,
    //   "name": this.state.valueNama,
    //   "email": this.state.valueEmail,
    //   "nim": this.state.valueNim,
    //   "password": this.state.valuePass,
    //   // this.state.valueKonfPass,
    // }
    
    // console.log('this.state.createNew',this.state.createNew)
    
    this.setState( { loading: true } )

    if(this.state.editId === null) {
      if(this.state.valuePass.length > 0 && (this.state.valuePass === this.state.valueKonfPass)) {
        const thePayload = {
          "role_id": this.state.valueRoleSelect,
          "name": this.state.valueNama,
          "email": this.state.valueEmail,
          "nim": this.state.valueNim,
          "password": this.state.valuePass,
          // this.state.valueKonfPass,
        }
        // console.log('thePayload',thePayload)
        const userResp = await users.createUser(thePayload);
        // console.log('userResp',userResp)
        
        if(userResp.data.success) {
          Alert.success("Tambah Data Success", 5000)
        } else {
          Alert.error("Server Error", 5000)
        }
        await this.getRoles()
        await this.getUsers()
        this.close()
      } else {
        // console.log('password not match')
        Alert.error("Password Tidak Match.", 5000)
      }
    } else {
      // console.log("ini edit", this.state)
      // console.log(">>>",this.state.valuePass.length)
      let thePayload = {
        "role_id": this.state.valueRoleSelect,
        "name": this.state.valueNama,
        "email": this.state.valueEmail,
        "nim": this.state.valueNim,
        // this.state.valueKonfPass,
      }
      if(this.state.valuePass.length > 0 && (this.state.valuePass === this.state.valueKonfPass)) {
          thePayload.password = this.state.valuePass;
      }
      const updateUserResp = await users.updateUsers(this.state.editId, thePayload);
      // console.log("updateUserResp",updateUserResp)
      if(updateUserResp.data.success) {
        Alert.success("Edit Data Success", 5000)
      } else {
        Alert.error("Server Error", 5000)
      }
      
      await this.getRoles()
      await this.getUsers()
      this.close()
    }
    this.setState( { loading: false } )
    // console.log('thePayload',thePayload)
    // const userResp = await users.createUser(thePayload);
    // console.log('this.state',this.state)
    
  }

  async handlePaginationSelect(eventKey) {
    // console.log('eventKey',eventKey)
    this.setState({ activePage: eventKey })
    await this.getUsers(eventKey)

    // console.log('this.state.activePage',this.state.activePage)
  }

  render () {
    // console.log('www this.props',this.props.role)
    // console.log('www this.state',this.state)
    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")
    // console.log('this.state.activePage',this.state.activePage)

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>
    }

    return (
      <>
        <LayoutRsuite role={this.props.role}/>
        <div className="usersContainer">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>{path}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Action" collapsible bordered defaultExpanded={true}>
                    <Button color="green" >
                      <Icon 
                        icon="user-plus"
                        // onClick={() => {console.log('Add')}}
                        onClick={() => this.open('xs')}
                      />
                    </Button>
                  </Panel>
                </Col>
              </Row>
            </Grid>
            <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
              <Modal.Header>
                <Modal.Title>Tambah Users</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid fluid>
                  <Row className="">
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleNama}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Nama"
                        defaultValue={this.state.valueNama} 
                      />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueEmail} type="email" onChange={this.handleEmail} style={{ marginBottom: 10 }} size="md" placeholder="Email" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valuePass} type="password" onChange={this.handlePass} style={{ marginBottom: 10 }} size="md" placeholder="Password" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueKonfPass} type="password" onChange={this.handleKonfPass} style={{ marginBottom: 10 }} size="md" placeholder="Konfirmasi Password" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueNim} onChange={this.handleNim} style={{ marginBottom: 10 }} size="md" placeholder="NIM" />
                    </Col>
                    <Col xs={18}>
                      
                      <SelectPicker
                        onChange={this.handleSelectChange}
                        value={this.state.valueRoleSelect}
                        data={this.state.selectData}
                        searchable={false}
                        style={{ width: 224, marginBottom: 10 }}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button 
                  loading={this.state.loading}
                  onClick={this.simpanHandler}
                  color="violet"
                 >
                  Simpan
                </Button>
                <Button onClick={this.close} appearance="subtle">
                  Batal
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div>
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Filter" collapsible bordered>
                    <h1>Filter</h1>
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </div>

          <MyTable 
            // parentMethod={this.someMethod}
            editMethod={(data) => this.editMethod(data)}
            deleteMethod={(data) => this.delMethod(data)} 
            fill={this.state.users} 
            title="User List"
          />

          <div>
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel>
                    <Pagination 
                      pages={this.state.countedTotalPage} 
                      activePage={this.state.activePage} 
                      maxButtons={this.state.maxButtonUserPagination} 
                      ellipsis={true}
                      boundaryLinks={true}
                      prev={true}
                      next={true}
                      first={true}
                      last={true}
                      onSelect={this.handlePaginationSelect}
                    />
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </div>

        </div>
      </>
    );
  } 
}

export default Users;

