import React, {Component} from 'react'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import configs from '../../configs/index'
// import MyTable from '../../components/MyTable/MyTable';
import './Rooms.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import { 
  Panel, 
  Grid,
  Row,
  Col,
  Button,
  Icon,
  Modal,
  Divider,
  Table,
  IconButton,
  Input,
  SelectPicker,
  Breadcrumb
  // PanelGroup 
} from 'rsuite';

// import { getRole } from "../../auth";

import forEach from "lodash/forEach";
import RoomsServices from '../../services/Rooms/index'
import SchedulesServices from '../../services/Schedules/index'

const { 
  Column, 
  HeaderCell, 
  Cell, 
  // Pagination 
} = Table;

class Rooms extends Component{
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      selectSchedules: [],
      role: null,
      show: false,
      rooms: [],
      // rooms: [{
      //   "id": "1",
      //   "name": "asal"
      // },
      // {
      //   "id": "2",
      //   "name": "coba"
      // }]
      showModalUserJoin: false,
      sizeModalUserJoin: "xs",
      showModalInfo: false,
      sizeModalInfo: "xs",
      valueNamaUser: "",
      roomSelected: {},

      meetingName: "",
      attendeePW: "attendee",
      moderatorPW: "moderator",
      maxParticipants: 5,

    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.openModalUserJoin = this.openModalUserJoin.bind(this);
    this.closeModalUserJoin = this.closeModalUserJoin.bind(this);
    this.openModalInfo = this.openModalInfo.bind(this);
    this.closeModalInfo = this.closeModalInfo.bind(this);
    this.randomRoom = this.randomRoom.bind(this);
    this.showAllRooms = this.showAllRooms.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleJoinRoom = this.handleJoinRoom.bind(this);
    // this.handleJoinRoomAsUser = this.handleJoinRoomAsUser.bind(this);
    this.handleNamaUser = this.handleNamaUser.bind(this);
    this.simpanHandlerModalUserJoin = this.simpanHandlerModalUserJoin.bind(this);
    this.simpanRoomHandler = this.simpanRoomHandler.bind(this);
    // simpanHandlerModalUserJoin
    this.handleMeetingName = this.handleMeetingName.bind(this);
    this.handleAttendeePW = this.handleAttendeePW.bind(this);
    this.handleModeratorPW = this.handleModeratorPW.bind(this);
    this.handleMaxParticipants = this.handleMaxParticipants.bind(this);
    this.changeSelectorSchedule = this.changeSelectorSchedule.bind(this);
    this.searchSchedule = this.searchSchedule.bind(this);

    this.handleGetRecording = this.handleGetRecording.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    // let yuu = await this.getTheRole();


    if (this._isMounted) {
      const ac_tok = localStorage.getItem("access_token");
      const tok = "Bearer "+ac_tok;

      // check role
      axios.get(configs.baseEndpoint.baseURL+'api/me', {
        headers: { 'Authorization': tok }
      })
        .then( response => {
          // console.log("response",response)
          // response.data.data.attributes.name
          // this.setState({ user : response.data.data.attributes })
          // this.setState({ error : false })
          // this.setState({ loadingPage : false })
          // this.setState({ validToken : true })
        } )
        .catch( error => {
          console.log("error",error)
          // console.log('error',error.response.data)
          // console.log('error',error.response.status)
          // console.log('error',error.response.headers)
          // this.setState({ user : {} })
          // this.setState({ error : true })
          // this.setState({ loadingPage : true })
          // this.setState({ validToken : false })
          // if(error.response.status === 401) {
          //   Auth.logout();
          //   this.props.history.push('/login');
          // }
        });
      
      // get all rooms
      axios.get('https://api.amithyainstitute.com/api/testbbb/getallmeetings', {
        headers: { 'Authorization': tok }
      })
        .then( response => {
          console.log("response.data",response.data)
          let arrRooms = []
          forEach(response.data, item => {
            console.log("item.attendees => ",item.attendees)

            let rooms = {
              "id": item.meetingID,
              "maxUsers": item.maxUsers,
              "meetingName": item.meetingName, 
              "attendees": item.attendees.length,
              "attendeePW": item.attendeePW,
              "moderatorPW": item.moderatorPW,
              "participantCount": item.participantCount,
              "running": item.running
            }
            arrRooms.push(rooms)
          })
          this.setState({ rooms : arrRooms })
          // response.data.data.attributes.name
          // this.setState({ user : response.data.data.attributes })
          // this.setState({ error : false })
          // this.setState({ loadingPage : false })
          // this.setState({ validToken : true })
        } )
        .catch( error => {
          console.log("error",error)
          // console.log('error',error.response.data)
          // console.log('error',error.response.status)
          // console.log('error',error.response.headers)
          // this.setState({ user : {} })
          // this.setState({ error : true })
          // this.setState({ loadingPage : true })
          // this.setState({ validToken : false })
          // if(error.response.status === 401) {
          //   Auth.logout();
          //   this.props.history.push('/login');
          // }
        });
      
    }
    // await this.SchedulesServices.getSchedules()
    // let yuu = await getRole();
    // if (this._isMounted) {
    //   this.setState( { role: yuu } );
    //   // this.setState( { role: 'student' } );
    // }

    // const user_role = localStorage.getItem("role")
    // console.log('user_role',user_role)
    // if(user_role !== "superadmin") {
    //   return <Redirect to='/dashboard'/>;
    // }

    // await this.getUsers();
    // await this.getRoles();
    // await this.delUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  close() {
    this.setState({
      show: false,
      meetingName: "",
      attendeePW: "attendee",
      moderatorPW: "moderator",
      maxParticipants: 5,
      // valueRoleSelect: null,
      // valueNama: '',
      // valueEmail: '',
      // valueNim: '',
      // valuePass: '',
      // valueKonfPass: ''
    });
  }

  open(size) {
    this.setState({
      size,
      show: true,
    });
  }

  randomRoom() {
    console.log("Random")
    const data = {
      "meetingName": "Coba",
      "attendeePW": "attendee",
      "moderatorPW": "moderator",
      "maxParticipants": 5,
    }
    RoomsServices.createRandomRoom(data)
  }

  showAllRooms() {
    // maxUsers: "5"
    // meetingID: "1595987343"
    // meetingName: "test meeting 1595987343"
    // attendees
    // attendeePW
    // moderatorPW
    // participantCount
    // running
    
    // let result = RoomsServices.getAllRooms()
    // console.log("result",result)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get('https://api.amithyainstitute.com/api/testbbb/getallmeetings', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        console.log("response.data",response.data)
        let arrRooms = []
        forEach(response.data, item => {
          console.log("item.attendees => ",item.attendees)
          // attendees
          // item.attendees.attendee[0...n]
          // info di dalam nya :
          // clientType: "HTML5"
          // fullName: "yogiedigital"
          // hasJoinedVoice: "false"
          // hasVideo: "false"
          // isListeningOnly: "true"
          // isPresenter: "false"
          // role: "MODERATOR"
          // userID: "w_m1cgqmj6x5tk"

          let rooms = {
            "id": item.meetingID,
            "maxUsers": item.maxUsers,
            "meetingName": item.meetingName, 
            "attendees": item.attendees.length,
            "attendeePW": item.attendeePW,
            "moderatorPW": item.moderatorPW,
            "participantCount": item.participantCount,
            "running": item.running
          }
          arrRooms.push(rooms)
        })
        this.setState({ rooms : arrRooms })
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });

  }

  async simpanHandler() {
    this.close();
  }

  async delMethod(data) {
    console.log('dari parent //bar', data);
    // await this.delUsers(data);
    // await this.getUsers();
  }

  async editMethod(data) {
    console.log('dari parent //bar EDIT', data);
    // this.setState( { editId: data } );
    // await this.getUserById(data);
  }

  async handleDelete(data) {
    console.log('data',data)
    let theData = {
      'meetingID': data.id,
      'moderatorPW': data.moderatorPW
    }
    let result = RoomsServices.delRoom(theData, {})
    console.log("result",result)
    // this.props.deleteMethod(id);
  }

  // for join using moderator
  async handleJoinRoom(data) {

    console.log('data',data)
    let payload = {
      "id": data.id,
      "name": "Admin",
      "password": data.moderatorPW
    }
    let result = await RoomsServices.joinRoom(payload, {})
    console.log("handleJoinRoom result",result)
    window.open(result.data.url, "_blank") 
  }

  // async handleJoinRoomAsUser(id) {

  //   console.log('id',id)
  //   // let data = {
  //   //   "id": id
  //   // }
  //   // let result = await RoomsServices.joinRoom(data, {})
  //   // console.log("handleJoinRoom result",result)
  //   // window.open(result.data.url, "_blank") //to open new page
  //   // this.props.deleteMethod(id);
  // }

  closeModalInfo() {
    this.setState({
      showModalInfo: false,
      roomSelected: {}
      // valueRoleSelect: null,
      // valueNama: '',
      // valueEmail: '',
      // valueNim: '',
      // valuePass: '',
      // valueKonfPass: ''
    });
  }

  openModalInfo(data) {
    console.log("data",data)
    let size = "xs";
    this.setState({
      size: size,
      showModalInfo: true,
      roomSelected: data
    });
  }

  
  closeModalUserJoin() {
    this.setState({
      showModalUserJoin: false,
      roomSelected: {}
      // valueRoleSelect: null,
      // valueNama: '',
      // valueEmail: '',
      // valueNim: '',
      // valuePass: '',
      // valueKonfPass: ''
    });
  }

  openModalUserJoin(data) {
    console.log("data",data)
    let size = "xs";
    this.setState({
      size: size,
      showModalUserJoin: true,
      roomSelected: data
    });
  }

  async simpanHandlerModalUserJoin() {
    console.log("simpanHandlerModalUserJoin")
    // console.log("this.state.valueNamaUser", this.state.valueNamaUser)
    // console.log("this.state.roomSelected", this.state.roomSelected)
    // this.closeModalUserJoin();

    // console.log('id',id)
    let data = {
      "id": this.state.roomSelected.id,
      "name": this.state.valueNamaUser,
      "password": this.state.roomSelected.attendeePW
    }
    let result = await RoomsServices.joinRoom(data, {})
    console.log("simpanHandlerModalUserJoin result",result)
    window.open(result.data.url, "_blank") //to open new page
    this.closeModalUserJoin();
  }

  async simpanRoomHandler() {
    console.log("Add Manual")
    let payloadCreateRoom = {
      "meetingName": this.state.meetingName,
      "attendeePW": this.state.attendeePW,
      "moderatorPW": this.state.moderatorPW,
      "maxParticipants": this.state.maxParticipants,
    }
    RoomsServices.createRandomRoom(payloadCreateRoom)
    console.log("payloadCreateRoom",payloadCreateRoom)
    this.close();
  }

  handleNamaUser(value) {
    this.setState( { valueNamaUser: value } );
  }

  // handle for add new room form
  handleMeetingName(value) {
    this.setState( {meetingName: value} );
    // console.log("this.state.meetingName",this.state.meetingName)
  }

  handleAttendeePW(value) {
    this.setState( {attendeePW: value} );
  }

  handleModeratorPW(value) {
    this.setState( {moderatorPW: value} );
  }

  handleMaxParticipants(value) {
    this.setState( {maxParticipants: value} );
  }

  changeSelectorSchedule(value) {
    let name = "schedule_id_"+value+"/"
    // console.log("aa",name)
    this.handleMeetingName(name)
    // this.setState( {meetingName: name} );
    // console.log("this.state.meetingName",this.state.meetingName)
  }

  handleGetRecording(data) {
    console.log("handleGetRecording", data)
    
    // from delete
    // console.log('data',data)
    // let theData = {
    //   'meetingID': data.id,
    //   'moderatorPW': data.moderatorPW
    // }
    // let result = RoomsServices.delRoom(theData, {})
    // console.log("result",result)
    
    let payload = {
      "meetingID" : data.id,
    }
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // axios.get('https://api.amithyainstitute.com/api/testbbb/getrecord', 
    axios.post(configs.baseEndpoint.baseURL+'/api/testbbb/getrecord', 
      payload, {
      headers: { 'Authorization': tok },
      params: {},
    })
      .then( response => {
        console.log("response.data",response.data)
        // let arrRooms = []
        // forEach(response.data, item => {
        //   console.log("item.attendees => ",item.attendees)

        //   let rooms = {
        //     "id": item.meetingID,
        //     "maxUsers": item.maxUsers,
        //     "meetingName": item.meetingName, 
        //     "attendees": item.attendees.length,
        //     "attendeePW": item.attendeePW,
        //     "moderatorPW": item.moderatorPW,
        //     "participantCount": item.participantCount,
        //     "running": item.running
        //   }
        //   arrRooms.push(rooms)
        // })
        // this.setState({ rooms : arrRooms })

      } )
      .catch( error => {
        console.log("error",error)
        
      });
  }

  async searchSchedule(value) {
    // console.log("the search function",value)
    const opts = {
      params: {
          "page[num]": 1,
          "page[limit]": 10,
          // "page[num]": page,
          // "page[limit]": this.state.limitUserPagination,
          "sort": "-created_at",
          "filter[description][like]": value, 
        // include: "items,invoices,platforms,parent,child,packaging,user"
      }
    }
    // console.log("the search description",opts)
    await this.getSchedule(opts)
  }

  async getSchedule(params) {
    // console.log("params",params)
    const resSchedules = await SchedulesServices.getSchedules(params)
    let arrSchedules = []
    forEach(resSchedules.data.data, schedule => {
      // console.log('schedule',schedule)
      const item = {
        "label": schedule.attributes.description,
        "value": schedule.attributes.id
      }
      arrSchedules.push(item)
      // schedule.attributes
    })
    this.setState( {selectSchedules: arrSchedules} );
    
    // console.log('arrSchedules',arrSchedules)
    // resSchedules.data.data
    // resSchedules.data.included
    // selectSchedules: [
    //   {
    //     "label": "Eugenia",
    //     "value": "Eugenia",
    //     "role": "Master"
    //   },
    //   {
    //     "label": "Kariane",
    //     "value": "Kariane",
    //     "role": "Master"
    //   },
    //   {
    //     "label": "Louisa",
    //     "value": "Louisa",
    //     "role": "Master"
    //   }
    // ],
    // console.log("resSchedules",resSchedules)
  }

  render () {
    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")
    // console.log('this.state.activePage',this.state.activePage)

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    // console.log('zzzzz this.props',this.props)
    // console.log('Users',this.state.role)
    // const user_role = localStorage.getItem("role")

    // if(user_role !== "superadmin") { //this is how I tried to redirect
    //     return ( //
    //         <Redirect to='/dashboard' />
    //     );
    // }

    // if(this.state.role !== "superadmin") { //this is how I tried to redirect
    //     return ( //
    //         <Redirect to='/dashboard' />
    //     );
    // }

    // const user_role = localStorage.getItem("role")
    // console.log('user_role',user_role)
    // if(user_role !== "superadmin") {
    //   console.log('bukan super admin')
    //   return <Redirect to='/dashboard'/>;
    // }
    return (
      <>
        <LayoutRsuite />
        <div className="roomsContainer">
          <div className="">
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>{path}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Action" collapsible bordered defaultExpanded={true}>
                    <Button 
                      color="green" 
                      onClick={() => this.open('xs')}
                    >
                      <Icon 
                        icon="plus"
                        // onClick={() => {console.log('Add')}}
                        // onClick={() => this.open('xs')}
                      />
                    </Button>
                    <Divider vertical />
                    <Button 
                      color="blue" 
                      onClick={() => this.randomRoom()}
                    >
                      <Icon 
                        icon="random"
                        // onClick={() => {console.log('Add')}}
                        // onClick={() => this.randomRoom()}
                      />
                    </Button>
                    <Divider vertical />
                    <Button 
                      color="blue" 
                      onClick={() => this.showAllRooms()}
                    >
                      <Icon 
                        icon="refresh"
                        // onClick={() => {console.log('Add')}}
                        // onClick={() => this.showAllRooms()}
                      />
                    </Button>
                  </Panel>
                </Col>
              </Row>
            </Grid>
            <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
              <Modal.Header>
                <Modal.Title>Tambah Room</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid fluid>
                  <Row className="">
                    <Col xs={18}>
                      <SelectPicker 
                        data={this.state.selectSchedules} 
                        style={{ marginBottom: 10 }} 
                        onChange={(value) => {this.changeSelectorSchedule(value)}}
                        onSearch={(value) => {this.searchSchedule(value)}}
                      />
                    </Col>
                  </Row>
                  <Row className="">
                    {/*
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleMeetingName}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Prefix Nama Room"
                        defaultValue={this.state.meetingName}
                        value={this.state.meetingName} 
                      />
                    </Col>
                    */}
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleMeetingName}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Prefix Nama Room"
                        value={this.state.meetingName} 
                      />
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleAttendeePW}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Password Attendee"
                        defaultValue={this.state.attendeePW} 
                      />
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleModeratorPW}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Password Moderator"
                        defaultValue={this.state.moderatorPW} 
                      />
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleMaxParticipants}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Max Participants"
                        defaultValue={this.state.maxParticipants} 
                      />
                    </Col>
                  </Row>
                  
                  
                    {/*
                  <Row className="">
                      'meetingName' => 'test meeting '.$theId,
                      'attendeePW' => 'attendee',
                      'moderatorPW' => 'moderator',
                      'maxParticipants' => 5,
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleNama}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Nama"
                        defaultValue={this.state.valueNama} 
                      />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueEmail} type="email" onChange={this.handleEmail} style={{ marginBottom: 10 }} size="md" placeholder="Email" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valuePass} type="password" onChange={this.handlePass} style={{ marginBottom: 10 }} size="md" placeholder="Password" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueKonfPass} type="password" onChange={this.handleKonfPass} style={{ marginBottom: 10 }} size="md" placeholder="Konfirmasi Password" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueNim} onChange={this.handleNim} style={{ marginBottom: 10 }} size="md" placeholder="NIM" />
                    </Col>
                    <Col xs={18}>
                      
                      <SelectPicker
                        onChange={this.handleSelectChange}
                        value={this.state.valueRoleSelect}
                        data={this.state.selectData}
                        searchable={false}
                        style={{ width: 224, marginBottom: 10 }}
                      />
                    </Col>
                  </Row>
                    */}
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button 
                  onClick={this.simpanRoomHandler}
                  color="violet"
                 >
                  Simpan
                </Button>
                <Button onClick={this.close} appearance="subtle">
                  Batal
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="containerTable">
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="The Tables" shaded bordered>
                    <Table
                      height={400}
                      data={this.state.rooms}
                    >

                      <Column width={200} align="center">
                        <HeaderCell>Id</HeaderCell>
                        <Cell dataKey="id" />
                      </Column>

                      <Column width={200} >
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="meetingName" />
                      </Column>

                      <Column width={200} >
                        <HeaderCell>Attendees</HeaderCell>
                        <Cell dataKey="attendees" />
                      </Column>

                      <Column width={200} >
                        <HeaderCell>Attendee PW</HeaderCell>
                        <Cell dataKey="attendeePW" />
                      </Column>

                      <Column width={200} >
                        <HeaderCell>Moderator PW</HeaderCell>
                        <Cell dataKey="moderatorPW" />
                      </Column>

                      <Column width={200} >
                        <HeaderCell>Participant Count</HeaderCell>
                        <Cell dataKey="participantCount" />
                      </Column>

                      <Column width={200} >
                        <HeaderCell>Running</HeaderCell>
                        <Cell dataKey="running" />
                      </Column>
                      
                      <Column width={200}>
                        <HeaderCell>Action</HeaderCell>

                        <Cell>
                          {rowData => {
                            // function handleAction() {
                            //   alert(`id:${rowData.id}`);
                            // }
                            return (
                              <span>
                                  <IconButton
                                    appearance="ghost"
                                    color="violet"
                                    icon={
                                      <Icon
                                        className="fill-color" 
                                        icon="trash" 
                                      />
                                    } 
                                    placement="left" 
                                    size="xs"
                                    // onClick={() => {this.theClick(rowData.id)}}
                                    // onClick={this.myClick}
                                    onClick={() => {this.handleDelete(rowData)}}
                                    // onClick={() => this.props.parentMethod('Hello from child')}
                                  >
                                  </IconButton>
                                {/*
                                <a onClick={handleAction}> 
                                </a> */}{' '}|{' '}
                                  <IconButton
                                    circle
                                    appearance="ghost"
                                    color="violet"
                                    icon={
                                      <Icon
                                        className="fill-color" 
                                        icon="sign-in" 
                                      />
                                    } 
                                    placement="left" 
                                    size="xs"
                                    onClick={() => {this.handleJoinRoom(rowData)}}
                                    // onClick={handleAction}
                                  >
                                  </IconButton>
                                  {' '}|{' '}
                                  <IconButton
                                    circle
                                    appearance="ghost"
                                    color="violet"
                                    icon={
                                      <Icon
                                        className="fill-color" 
                                        icon="user-plus" 
                                      />
                                    } 
                                    placement="left" 
                                    size="xs"
                                    onClick={() => {this.openModalUserJoin(rowData)}}
                                    // onClick={handleAction}
                                  >
                                  </IconButton>
                                  {' '}|{' '}
                                  <IconButton
                                    circle
                                    appearance="ghost"
                                    color="violet"
                                    icon={
                                      <Icon
                                        className="fill-color" 
                                        icon="info" 
                                      />
                                    } 
                                    placement="left" 
                                    size="xs"
                                    onClick={() => {this.openModalInfo(rowData)}}
                                    // onClick={() => {this.handleJoinRoomAsUser(rowData.id)}}
                                    // onClick={handleAction}
                                  >
                                  </IconButton>
                                  {' '}|{' '}
                                  <IconButton
                                    circle
                                    appearance="ghost"
                                    color="violet"
                                    icon={
                                      <Icon
                                        className="fill-color" 
                                        icon="film" 
                                      />
                                    } 
                                    placement="left" 
                                    size="xs"
                                    onClick={() => {this.handleGetRecording(rowData)}}
                                    // onClick={() => {this.handleJoinRoomAsUser(rowData.id)}}
                                    // onClick={handleAction}
                                  >
                                  </IconButton>
                                {/* info
                                <a onClick={handleAction}>
                                </a>
                                */}
                              </span>
                            );
                          }}
                        </Cell>
                      </Column>
                    </Table>
                  </Panel>
                </Col>
              </Row>
            </Grid>
            <Modal size={this.state.sizeModalUserJoin} show={this.state.showModalUserJoin} onHide={this.closeModalUserJoin}>
              <Modal.Header>
                <Modal.Title>Join Room Sebagai User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid fluid>
                  <Row className="">
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleNamaUser}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Nama"
                        defaultValue={this.state.valueNamaUser} 
                      />
                    </Col>
                    {/*
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueEmail} type="email" onChange={this.handleEmail} style={{ marginBottom: 10 }} size="md" placeholder="Email" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valuePass} type="password" onChange={this.handlePass} style={{ marginBottom: 10 }} size="md" placeholder="Password" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueKonfPass} type="password" onChange={this.handleKonfPass} style={{ marginBottom: 10 }} size="md" placeholder="Konfirmasi Password" />
                    </Col>
                    <Col xs={18}>
                      <Input defaultValue={this.state.valueNim} onChange={this.handleNim} style={{ marginBottom: 10 }} size="md" placeholder="NIM" />
                    </Col>
                    <Col xs={18}>
                      
                      <SelectPicker
                        onChange={this.handleSelectChange}
                        value={this.state.valueRoleSelect}
                        data={this.state.selectData}
                        searchable={false}
                        style={{ width: 224, marginBottom: 10 }}
                      />
                    </Col>
                    */}
                  </Row>
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button 
                  onClick={this.simpanHandlerModalUserJoin}
                  color="violet"
                 >
                  Simpan
                </Button>
                <Button onClick={this.closeModalUserJoin} appearance="subtle">
                  Batal
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal size={this.state.sizeModalInfo} show={this.state.showModalInfo} onHide={this.closeModalInfo}>
              <Modal.Header>
                <Modal.Title>Room Info</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid fluid>
                  <Row className="">
                    <Col xs={8}>
                      id
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.id}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      attendees
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.attendees}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      maxUsers
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.maxUsers}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      meetingName
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.meetingName}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      moderatorPW
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.moderatorPW}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      attendeePW
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.attendeePW}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      participantCount
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.participantCount}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={8}>
                      running
                    </Col>
                    <Col xs={16}>
                      : {this.state.roomSelected.running}
                    </Col>
                  </Row>
                    {/*
                      attendeePW: "attendee"
                      attendees: 1
                      id: "1595999272"
                      maxUsers: "5"
                      meetingName: "test meeting 1595999272"
                      moderatorPW: "moderator"
                      participantCount: "0"
                      running: "false"
                  <Row className="">
                    <Col xs={18}>
                      <Input 
                        onChange={this.handleNamaUser}
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Nama"
                        defaultValue={this.state.valueNamaUser} 
                      />
                    </Col>
                  </Row>
                    */}
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button 
                  onClick={this.closeModalInfo}
                  color="violet"
                 >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    );
  } 
}

export default Rooms;

