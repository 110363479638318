import React, {Component} from 'react';
import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './ClassRecord.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom"
import axios from 'axios'
import Auth from '../../services/Auth/index'
// import forEach from "lodash/forEach"
import { 
  Panel,
  Button,
  Icon,
  Grid,
  Row,
  Col,
  Modal,
  Input,
  SelectPicker,
  ButtonToolbar,
  // Pagination,
  Breadcrumb,
  Alert,
} from 'rsuite';
import forEach from "lodash/forEach"
// import subModules from '../../services/SubModules/index'

class ClassRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      actionItem: "new",

      filterScheduleData: [],
      recordsData: [],
      modalFilterSchedule: [],

      recordsParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
        "filter[type][is]": "class_record",
      },

      schedulesParam: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
      },

      scheduleFilterSelected: null,

      scheduleSelected: null,
      valueFileType: "",
      valueFilePath: "",
      valueType: "",
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.newRecord = this.newRecord.bind(this);
    // this.editRecord = this.editRecord.bind(this);

    this.filterScheduleClean = this.filterScheduleClean.bind(this);
    this.handleSelectFilterSchedule = this.handleSelectFilterSchedule.bind(this);
    this.handleSearchFilterSchedule = this.handleSearchFilterSchedule.bind(this);

    this.searchFilter = this.searchFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);

    this.handleEditRecords = this.handleEditRecords.bind(this);
    this.handleDeleteRecords = this.handleDeleteRecords.bind(this);

    this.handleFileType = this.handleFileType.bind(this);
    this.handleFilePath = this.handleFilePath.bind(this);
    this.handleType = this.handleType.bind(this);

    // this.filterScheduleClean = this.filterScheduleClean.bind(this);
    this.scheduleSelect = this.scheduleSelect.bind(this);
    this.scheduleSearch = this.scheduleSearch.bind(this);

    this.simpanHandler = this.simpanHandler.bind(this);
  }

  open(size) {
    // console.log("this.state.valueCourses",this.state.valueCourses)
    this.setState({
      size,
      show: true,
    });
  }

  close() {
    this.setState({
      show: false,

      valueFileType: "",
      valueFilePath: "",
      valueType: "",
      scheduleSelected: null,

      // modulesSelected: null,
      // valueName: "",
      // valueDeskripsi: "",
      // subModulesSelected: null,
      // subModulesSelectedId: null,

    });
  }

  componentDidMount() {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // check role
    axios.get(configs.baseEndpoint.baseURL+'api/me?include=roles', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)

        const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        const role_name = response.data.included.roles[role_id].attributes.name
        window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  newRecord() {
    this.setState({actionItem: "new"})
    this.open('xs')
  }

  // editRecord(data) {
  //   this.setState({actionItem: "edit"})
  //   this.open('xs')
  // }

  filterScheduleClean() {
    // console.log("filterScheduleClean")
    this.setState({ scheduleFilterSelected: null })
  }

  handleSelectFilterSchedule(value) {
    // console.log("handleSelectFilterCourses",value)
    this.setState({ scheduleFilterSelected: value })
  }

  handleSearchFilterSchedule(value) {
    console.log("handleSearchFilterCourses",value)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    let schedulesParam = this.state.schedulesParam
    let newSchedulesParam = {
      ...schedulesParam,
      // "filter[module_id][is]": this.state.modulesFilterSelected,
      // "filter[parent_id][null]": true,
      "filter[description][like]": value,
    }

    // console.log("newSchedulesParam",newSchedulesParam)

    // check schedule
    axios.get(configs.baseEndpoint.baseURL+'api/schedules', {
      headers: { 'Authorization': tok },
      params: newSchedulesParam
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)

        let arrSchedule = []
        forEach(response.data.data, item => {
          let sche = {
            "label": item.attributes.description,
            "value": item.attributes.id,
          }

          arrSchedule.push(sche)
        })
        this.setState({filterScheduleData: arrSchedule})
        // filterScheduleData

        // const role_id = response.data.data.relationships.roles.id
        // // console.log("role_id",role_id)
        // // response.data.include
        // const role_name = response.data.included.roles[role_id].attributes.name
        // window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  searchFilter() {
    console.log("searchFilter", this.state.scheduleFilterSelected)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    let recordsParam = this.state.recordsParam
    let newRecordsParam = {
      ...recordsParam,
      // "filter[module_id][is]": this.state.modulesFilterSelected,
      // "filter[parent_id][null]": true,
      // "filter[schedule_id][is]": this.state.scheduleFilterSelected,
    }
    console.log("newRecordsParam",newRecordsParam)
    if(this.state.scheduleFilterSelected !== null) {
      newRecordsParam["filter[schedule_id][is]"] = this.state.scheduleFilterSelected
    }

    // check file_courses
    axios.get(configs.baseEndpoint.baseURL+'api/filecourses', {
      headers: { 'Authorization': tok },
      params: newRecordsParam
    })
      .then( response => {
        console.log("response.data.data",response.data.data)

        let arrRecords = []
        forEach(response.data.data, item => {
          let rec = {
            // "label": item.attributes.description,
            // "value": item.attributes.id,
            // "created_at": "2020-08-14T08:48:33.000000Z"
            "file_path": item.attributes.file_path,
            "file_type": item.attributes.file_type,
            "id": item.attributes.id,
            "schedule_id": item.attributes.schedule_id,
            // "updated_at": "2020-08-14T08:48:33.000000Z"
          }

          arrRecords.push(rec)
        })
        this.setState({recordsData: arrRecords})

        // filterScheduleData

        // const role_id = response.data.data.relationships.roles.id
        // // console.log("role_id",role_id)
        // // response.data.include
        // const role_name = response.data.included.roles[role_id].attributes.name
        // window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  resetFilter() {
    this.filterScheduleClean()
  }

  handleEditRecords(data) {
    // console.log("handleEditRecords", data)
    
    this.setState({actionItem: "edit"})

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get one records
    axios.get(configs.baseEndpoint.baseURL+'api/filecourses/'+data.id, {
      headers: { 'Authorization': tok },
      params: {
        "include": "schedules"
      }
    })
      .then( response => {
        console.log("response",response)

        console.log("response",response.data.included.schedules[response.data.data.relationships.schedules.id])
        let theSche = response.data.included.schedules[response.data.data.relationships.schedules.id].attributes
        
        let arrSche = []

        let sche = {
          "label": theSche.description,
          "value": theSche.id,
        }

        arrSche.push(sche)

        this.setState({
          valueFileType: response.data.data.attributes.file_type,
          valueFilePath: response.data.data.attributes.file_path,
          valueType: response.data.data.attributes.type,
          scheduleSelected: response.data.data.attributes.schedule_id,
          modalFilterSchedule: arrSche,
        })
        // response.data.data
        // file_path: "aaaa"
        // file_type: "video"
        // id: 8
        // schedule_id: 12

        if(response.data.success) {
        //   // this.state.recordsData
        //   Alert.success("Hapus Success", 5000)
        //   this.setState({loading: false})
        //   this.searchFilter()
          this.open('xs')
        }

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error("Server Error", 5000)
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // this.open('xs')
  }

  handleDeleteRecords(data) {
    // console.log("handleDeleteRecords", data)
    this.setState({loading: true})
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // del records
    axios.delete(configs.baseEndpoint.baseURL+'api/filecourses/'+data.id, {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)

        if(response.data.success) {
          // this.state.recordsData
          Alert.success("Hapus Success", 5000)
          this.setState({loading: false})
          this.searchFilter()
        }

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error("Server Error", 5000)
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  handleFileType(value) {
    // console.log("handleFileType", value)
    
    this.setState({valueFileType: value})
    
  }

  handleFilePath(value) {
    // console.log("handleFilePath", value)
    
    this.setState({valueFilePath: value})
  }

  handleType(value) {
    // console.log("handleType", value)
    
    this.setState({valueType: value})
  }

  // filterScheduleClean() {
  //   console.log("filterScheduleClean")
  // }

  scheduleSelect(value) {
    // console.log("scheduleSelect", value)
    this.setState({scheduleSelected: value})
  }

  scheduleSearch(value) {
    console.log("scheduleSearch", value)
  }

  simpanHandler() {
    
    if(this.state.actionItem === "new") {
      let payload = {
        "schedule_id": this.state.scheduleSelected,
        "file_type": this.state.valueFileType,
        "file_path": this.state.valueFilePath,
        "type": this.state.valueType,
      }
      console.log("new", payload)
    } else {
      let payload = {
        "schedule_id": this.state.scheduleSelected,
        "file_type": this.state.valueFileType,
        "file_path": this.state.valueFilePath,
        "type": this.state.valueType,
      }
      console.log("edit", payload)
    }
  }

  render() {

    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let items = (
      <Col xs={24}>
        <div className="myCard">
          <Panel shaded >
            <Panel>
              <Grid fluid>
                <Row>
                  <Col xs={24} >
                    <h5>Data Kosong</h5>
                    <h5>Pilih dari filter ...</h5>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Panel>
        </div>
      </Col>
    );

    if(this.state.recordsData.length > 0) {
      items = this.state.recordsData.map((records) => 
      //   console.log("modules",modules)
        <Col xs={24} 
          id={`records_id_${records.id}`} 
          key={records.id}
        >
          <div className="myCard">
            <Panel shaded >
              <Panel 
                collapsible 
                defaultExpanded={true}
              >
                <Grid fluid>
                  <Row>
                    <Col xs={24} >
                      <div>
                        <p>
                          <b>Id : </b><small>{records.id}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>File Type : </b><small>{records.file_type}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>File Path : </b><small>{records.file_path}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Type : </b><small>{records.type}</small>
                        </p>
                      </div>
                      
                      
                      <div>
                        <ButtonToolbar>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleEditRecords(records)}}
                          >
                            <Icon icon="sign-in" /> Edit
                          </Button>
                          <Button 
                            loading={this.state.loading}
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleDeleteRecords(records)}}
                          >
                            <Icon icon="sign-in" /> Delete
                          </Button>
                        {/*
                          <Button 
                            color="cyan"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekParent(subModules)}}
                          >
                            Cek Parent
                          </Button>
                          <Button 
                            color="orange"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekChild(subModules)}}
                          >
                            Cek Child
                          </Button>
                          <Button 
                            color="yellow"
                            appearance='primary' 
                            block
                            onClick={() => {this.cekMap(subModules)}}
                          >
                            Cek Map
                          </Button>
                        */}
                        </ButtonToolbar>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel>  
            </Panel>
          </div>
        </Col>
        
      )
    } else {
      console.log("records kosong")
    }

    return (
      <>
        <LayoutRsuite />
        <div className="classRecordContainer">
          <div className="jumbotron">
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>{path}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="jumbotron">
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Action" collapsible bordered defaultExpanded={false}>
                    <Button 
                      color="green" 
                      onClick={() => this.newRecord('xs')}
                    >
                      <Icon icon='plus-square' />
                    </Button>
                  </Panel>
                </Col>
              </Row>
            </Grid>

            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Filter" collapsible bordered defaultExpanded={false}>
                    <Grid fluid>
                      <Row className="">
                        <Col xs={20}>
                          <SelectPicker
                            // loading={this.state.loadingSimpan}
                            onClean={this.filterScheduleClean}
                            onSelect={(value) => {this.handleSelectFilterSchedule(value)}}
                            // onChange={(value) => {this.handleChangeFilterCourses(value)}}
                            onSearch={(value) => {this.handleSearchFilterSchedule(value)}}
                            data={this.state.filterScheduleData}
                            style={{ width: 224, marginBottom: 10 }}
                            placeholder="Schedule"
                            value={this.state.scheduleFilterSelected}
                          />
                        </Col>
                      {/*
                        <Col xs={16}>
                          <SelectPicker
                            onClean={this.filterModulesClean}
                            onSelect={(value) => {this.handleSelectFilterModules(value)}}
                            onChange={(value) => {this.handleChangeFilterModules(value)}}
                            onSearch={(value) => {this.handleSearchFilterModules(value)}}
                            data={this.state.filterModulesData}
                            style={{ width: 224, marginBottom: 10 }}
                            placeholder="Modules"
                            value={this.state.modulesFilterSelected}
                          />
                        </Col>
                        <Col xs={16}>
                          <SelectPicker
                            onClean={this.filterSubModulesClean}
                            onSelect={(value) => {this.handleSelectFilterSubModules(value)}}
                            onChange={(value) => {this.handleChangeFilterSubModules(value)}}
                            onSearch={(value) => {this.handleSearchFilterSubModules(value)}}
                            data={this.state.filterSubModulesData}
                            style={{ width: 224, marginBottom: 10 }}
                            placeholder="Nama Sub Modules"
                            value={this.state.submodulesFilterSelected}
                          />
                        </Col>
                        <Col xs={16}>
                          <Input 
                            value={this.state.submodulesFilterName} 
                            onChange={this.handleFilterName} 
                            style={{ marginBottom: 10 }} 
                            size="md" 
                            placeholder="Nama Sub Modul" 
                          />
                        </Col>
                      */}
                        <Col xs={16}>
                          <Button 
                            color="blue"
                            appearance="primary" 
                            size="xs"
                            onClick={this.searchFilter}
                            style={{ marginBottom: 10 }}
                          >
                            Search
                          </Button>
                        </Col>
                        <Col xs={16}>
                          <Button
                            color="green" 
                            appearance="primary" 
                            size="xs"
                            onClick={this.resetFilter}
                            style={{ marginBottom: 10 }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </Grid>
                  </Panel>
                </Col>
              </Row>
            </Grid>

            <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
              <Modal.Header>
                 <Modal.Title>{this.state.actionItem} Records</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Grid fluid>
                  <Row className="">
                    <Col xs={18}>
                      Tentukan Schedule :
                    </Col>
                    <Col xs={16}>
                      <SelectPicker
                        onClean={this.filterScheduleClean}
                        onSelect={(value) => {this.scheduleSelect(value)}}
                        onSearch={(value) => {this.scheduleSearch(value)}}
                        data={this.state.modalFilterSchedule}
                        style={{ width: 224, marginBottom: 10 }}
                        placeholder="Schedule"
                        value={this.state.scheduleSelected}
                      />
                    </Col>
                    <Col xs={18}>
                      File Type :
                    </Col>
                    <Col xs={16}>
                      <Input 
                        value={this.state.valueFileType} 
                        onChange={this.handleFileType} 
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Nama Sub Modul" 
                      />
                    </Col>
                    <Col xs={18}>
                      File Path :
                    </Col>
                    <Col xs={16}>
                      <Input 
                        value={this.state.valueFilePath} 
                        onChange={this.handleFilePath} 
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Nama Sub Modul" 
                      />
                    </Col>
                    <Col xs={18}>
                      Type :
                    </Col>
                    <Col xs={16}>
                      <Input 
                        value={this.state.valueType} 
                        onChange={this.handleType} 
                        style={{ marginBottom: 10 }} 
                        size="md" 
                        placeholder="Type like : class_record" 
                      />
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <Button 
                  onClick={this.simpanHandler}
                  color="violet"
                  loading={this.state.loadingSimpan}
                 >
                  Simpan
                </Button>
                <Button onClick={this.close} appearance="subtle">
                  Batal
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div>
            <Grid fluid>
              <Row>
                {items}
              </Row>
            </Grid>
          </div>
        </div>
      </>
    );
  }

}

export default ClassRecord;
