import React, { Component } from 'react'
import configs from '../../configs/index'
import {
// Placeholder, 
// Avatar,
Input, InputGroup, Icon,
Button, 
IconButton, 
Alert,
// ButtonGroup, 
// ButtonToolbar,
Grid, 
Row, 
Col, 
Container, Header, Content, Footer,
//  Sidebar, Navbar, 
FlexboxGrid, Panel, 
// ButtonToolbar,
// Divider,
// Form, FormGroup, 
// ControlLabel, 
// FormControl 
} from 'rsuite'

// import axios from "axios"

import './Login2.css'
import axios from 'axios'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";

// import users from '../../services/Users/index'
// import auth from '../../services/Auth/index'
// import axios from 'axios';

const styles = {
  marginBottom: 10
};



class Login2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
      myEmail: '',
      myPassword: '',
      loadingSubmit: false,
      redirect: false,
      servis: '',
      user: {}
    };
    // console.log('Login',localStorage.getItem("role"))
    // const user_access_token = localStorage.getItem("access_token")
    // if (user_access_token !== null) {
    //   this.props.history.push('/dashboard');
    // }
    this.getUserData = this.getUserData.bind(this)
  }

  // state = {
  //     controls: {
  //         email: {
  //             elementType: 'input',
  //             elementConfig: {
  //                 type: 'email',
  //                 placeholder: 'Mail Address'
  //             },
  //             value: '',
  //             validation: {
  //                 required: true,
  //                 isEmail: true
  //             },
  //             valid: false,
  //             touched: false
  //         },
  //         password: {
  //             elementType: 'input',
  //             elementConfig: {
  //                 type: 'password',
  //                 placeholder: 'Password'
  //             },
  //             value: '',
  //             validation: {
  //                 required: true,
  //                 minLength: 6
  //             },
  //             valid: false,
  //             touched: false
  //         },
  //         myEmail: 'aaa@a.com',
  //         myPassword: '',
  //     },
  //     isSignup: true
  // }

  inputChangedHandler = ( value, controlName ) => {
      this.setState( { [controlName]: value } );
  }

  submitHandler = () => {
    // const history = useHistory();
    // let www = await this.login()
    // console.log('www',this.state)

    this.setState( { loadingSubmit: true } )
    // const response = await auth.login(this.state.myEmail, this.state.myPassword)

    // console.log('response',response)
    
    // window.localStorage.setItem('access_token', response.data.data.token)

    const payload = {
      "email": this.state.myEmail,
      "password": this.state.myPassword
    }

    axios.post(configs.baseEndpoint.baseURL+'api/login', 
      payload, {
      // headers: { 'Authorization': tok },
      // params: {},
    })
      .then( response => {
        console.log("response",response)
        
        
        if(response.data.success) {
          window.localStorage.setItem('access_token', response.data.data.token)
          this.getUserData()
        } else {
          Alert.error("Login Gagal", 5000)
          this.setState( { loadingSubmit: false } )
        }

        

        // let arrRooms = []
        // forEach(response.data, item => {
        //   console.log("item.attendees => ",item.attendees)

        //   let rooms = {
        //     "id": item.meetingID,
        //     "maxUsers": item.maxUsers,
        //     "meetingName": item.meetingName, 
        //     "attendees": item.attendees.length,
        //     "attendeePW": item.attendeePW,
        //     "moderatorPW": item.moderatorPW,
        //     "participantCount": item.participantCount,
        //     "running": item.running
        //   }
        //   arrRooms.push(rooms)
        // })
        // this.setState({ rooms : arrRooms })

      } )
      .catch( error => {
        console.log("error",error)
        Alert.error("Login Gagal", 5000)
        this.setState( { loadingSubmit: false } )
      });

    

    // axios.post('http://amithya-institute-api/api/login', payload, 
    // )
    //   .then( response => {
    //     console.log("response",response)
    //     // const user_id = response.data.data.id
    //     // const token = 'Bearer '+response.data.data.token
    //     window.localStorage.setItem('access_token', response.data.data.token)

    //     const userResp = users.getMe();
    //     const user = userResp.data;
    //     // this.setState( { user: user } );

    //     const user_data_role_id = user.data.relationships.roles.id
    //     const user_role = user.included.roles[user_data_role_id].attributes.name
    //     window.localStorage.setItem('role', user_role)
    //     this.setState({
    //       redirect: true
    //     })
    //   } )
    //   .catch( error => {
    //     console.log("error",error)
    //     // this.setState({ error : true })
    //   });

    // const user_id = response.data.data.id
    // const token = 'Bearer '+response.data.data.token

    // window.localStorage.setItem('access_token', response.data.data.token)


    // const userResp = await users.getMe();
    // const user = userResp.data;
    // console.log("user",user)
    // // // this.setState( { user: user } );

    // const user_data_role_id = user.data.relationships.roles.id
    // const user_role = user.included.roles[user_data_role_id].attributes.name
    // window.localStorage.setItem('role', user_role)

    this.setState({
      redirect: true
    })

    

  }

  getUserData() {
    console.log("getUserData")
    const ac_tok = localStorage.getItem("access_token")
    const tok = "Bearer "+ac_tok
    axios.get(configs.baseEndpoint.baseURL+'api/me?include=roles', 
      // payload, 
      {
        headers: { 'Authorization': tok }
      }
    )
      .then( response => {
        console.log("response getUserData",response)

        // const userResp = await users.getMe();
        // const user = userResp.data;
        // console.log("user",user)
        // // // this.setState( { user: user } );

        const user_data_role_id = response.data.data.relationships.roles.id
        const user_role = response.data.included.roles[user_data_role_id].attributes.name
        window.localStorage.setItem('role', user_role)


        // const user_id = response.data.data.id
        // const token = 'Bearer '+response.data.data.token
        // window.localStorage.setItem('access_token', response.data.data.token)

        // const userResp = users.getMe();
        // const user = userResp.data;
        // this.setState( { user: user } );

        // const user_data_role_id = user.data.relationships.roles.id
        // const user_role = user.included.roles[user_data_role_id].attributes.name
        // window.localStorage.setItem('role', user_role)
        this.setState({
          redirect: true
        })
      } )
      .catch( error => {
        console.log("error",error)
        // this.setState({ error : true })
      });
  }  

  render() {
    const user_access_token = localStorage.getItem("access_token")
    const role_name = localStorage.getItem("role")
    // console.log('role_name',role_name)
    // let { redirect } = this.state;
    // console.log('user_access_token',user_access_token)
    // console.log('redirect',redirect)
    // if(user_access_token !== null) {
    //   redirect = true;
    // }
    
    if (user_access_token !== null) {
    // if (user_access_token !== null && redirect) {
      // console.log('aaa')
      // return <Redirect to='/dashboard' />;
      // return <Redirect to={{pathname: '/dashboard', state: { from: props.location } }}/>;
      if(role_name === "superadmin") {
        return <Redirect to={{ pathname: '/admindashboard', state: { user_role: role_name, hallo: "samaaja" } }}/>;
      } else {
        return <Redirect to={{ pathname: '/dashboard', state: { user_role: role_name, hallo: "samaaja" } }}/>;
      }
      // return <Redirect to={{ pathname: '/dashboard', state: { user_role: role_name, hallo: "samaaja" } }}/>
    }

    // return (
    //   <div className="page">
    //     <h1>Login page</h1>
    //   </div>
    // );

    return (
      <div className="page">
        <Container>
          <Header></Header>
          <Content>
            <Grid fluid>
              <Row>

              </Row>
              <Row>
                <Col xs={20} xsOffset={2} className="containerLogin">
                  <Panel shaded className="withoutBorderRadius">
                    <Row>
                      <Col xs={24} xsHidden smHidden>
                        <Panel className="withoutBorderRadius">
                          <img src="https://amithyainstitute.com/wp-content/uploads/2020/01/amithya-institute-logo-1024x263.png" alt="" />
                        </Panel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={24} md={12} lg={12} xsHidden smHidden >
                        <Panel className="withoutBorderRadius">
                          <h1>Selamat Datang di Institut Amithya.</h1>
                        </Panel>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Col xs={24} sm={24} md={12} lg={12} lgHidden mdHidden>
                          <Panel className="withoutBorderRadius">
                              {/*
                              ini diisi dengan image
                              <h5>Selamat Datang di Institut Amithya.</h5>
                            <div className="logoImageResp">
                              <img src="https://amithyainstitute.com/wp-content/uploads/2020/01/amithya-institute-logo-1024x263.png" alt="" width="216px" height="52px"/>
                            </div>
                              */}
                             <img src="https://amithyainstitute.com/wp-content/uploads/2020/01/amithya-institute-logo-1024x263.png" alt="" width="216px" height="52px"/>
                          </Panel>
                        </Col>
                        <Panel bordered className="withoutBorderRadius" header={<h5>Login</h5>}>
                          <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                              
                              <InputGroup inside style={styles}>
                                <InputGroup.Addon>
                                  <Icon icon="avatar" />
                                </InputGroup.Addon>
                                <Input 
                                  placeholder="Email"
                                  type="email"
                                  onChange={( value ) => this.inputChangedHandler( value, 'myEmail' )}
                                />
                              </InputGroup>
                              <InputGroup inside style={styles}>
                                <InputGroup.Addon>
                                  <Icon icon="lock" />
                                </InputGroup.Addon>
                                <Input 
                                  placeholder="Password"
                                  type="password"
                                  onChange={( value ) => this.inputChangedHandler( value, 'myPassword' )}
                                />
                              </InputGroup>
                              <IconButton
                                color="violet"
                                loading={this.state.loadingSubmit}
                                icon={<Icon className="fill-color" icon="arrow-right" />} 
                                placement="right" 
                                size="md"
                                onClick={() => this.submitHandler()}
                                style={styles}
                              >
                                Submit
                              </IconButton>
                              <Button 
                                color="blue" 
                                href="/conference"
                                style={styles}
                              >
                                <Icon icon="comments"  /> Konferensi
                              </Button>
                            </FlexboxGrid.Item>
                          </FlexboxGrid>
                        </Panel>
                       {/*
                        <Panel 
                          bordered 
                          className="withoutBorderRadius" 
                          header={<h5>Conference</h5>} 
                          collapsible
                        >
                          AAA
                        </Panel>
                       */}

                      </Col>
                    </Row>
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </Content>
          <Footer>
            <Grid fluid>
              <Row>
                <Col xs={8}>
                  
                </Col>
                
                <Col xs={8}>
                  
                </Col>

                <Col xs={8}>
                  {/*
                  <ButtonToolbar>
                    <IconButton icon={<Icon icon="facebook-official" />} color="blue" circle />
                    <IconButton icon={<Icon icon="google-plus-circle" />} color="red" circle />
                    <IconButton icon={<Icon icon="twitter" />} color="cyan" circle />
                    <IconButton icon={<Icon icon="linkedin" />} color="blue" circle />
                  </ButtonToolbar>
                  */}
                </Col>
              </Row>
            </Grid>
          </Footer>
        </Container>
      </div>
    );
  }
}

export default Login2;