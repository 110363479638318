import React, {Component} from 'react';

import { 
  // List,
  // Grid, 
  // Row, 
  // Col,
  Panel, 
  // PanelGroup
  // Calendar,
  // Modal,
  // Button,
  // ButtonToolbar
} from 'rsuite';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import "./JoinRoom.css";

class JoinRoom extends Component{
  render(){
    return (
      <>
        <LayoutRsuite role={this.props.role}/>
        <div className="joinroomContainer">
          <Panel header="Panel title" shaded>
            <h1>AA</h1>
          </Panel>
          <Panel header="Panel title" shaded>
            <h1>AA</h1>
          </Panel>
          <Panel header="Panel title" shaded>
            <h1>AA</h1>
          </Panel>
          <Panel header="Panel title" shaded>
            <h1>AA</h1>
          </Panel>
          {/*
          <Grid fluid>
            <Row>
              <Col xs={24}>
                <List bordered>
                  <List.Item>
                    <Grid fluid>
                      <Row>
                        <Col xs={8}>A</Col>
                        <Col xs={8}>B</Col>
                        <Col xs={8}>C</Col>
                      </Row>
                    </Grid>
                  </List.Item>
                  <List.Item>
                    <Grid fluid>
                      <Row>
                        <Col xs={24}>B</Col>
                      </Row>
                    </Grid>
                  </List.Item>
                </List>
              </Col>
            </Row>
          </Grid>
          */}
        </div>
     </>
    );
  } 
}

export default JoinRoom;