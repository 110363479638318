import React, {Component} from 'react'
import configs from '../../configs/index'
import { 
  // Loader,
  Panel,
  // PanelGroup,
  Breadcrumb,
  Grid,
  Row,
  Col,
  Input,
  // Radio,
  // RadioGroup,
  // Tree,
  Icon,
  // List,
  // Form,
  // FormGroup,
  // ControlLabel,
  // HelpBlock,
  // FormControl,
  // Button,
  // ButtonToolbar
  // Calendar,
  // Modal,
  Button,
  ButtonToolbar,
  // SelectPicker
} from 'rsuite';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite'
import SubModuleList from '../../hoc/SubModuleList/SubModuleList'
import './StudentSubject.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
import forEach from "lodash/forEach"
// import remove from 'lodash/remove'
// import _ from 'lodash';

class StudentSubject extends Component{
  constructor(props) {
    super(props);
    this.state = {
      lihatMateriLoading: false,

      valueSearch: "",

      role: null,
      validToken: false,
      error: false,
      loadingPage: true,
      user: {},
      presencestatuses: [],
      userName: "",
      userId: null,

      valueNik: "",
      valueJenis: "",
      valueAlasan: "",

      scheduleSelected: "",

      paramModules: {
        "page[num]": 1,
        "page[limit]": 20,
        "sort": "created_at",
        // "filter[id][in]": "1,2,3"
      },

      schedulesData: [],

      selectValueExpand: [],
      item: [],

      panel: [],

      moduleItems: [],
      myNewModules: [],

      subModules: [],

      firstLevelStatus: [],

      child: [],

      status: false,

    }

      this.getSubModules = this.getSubModules.bind(this);
      this.handleExpand = this.handleExpand.bind(this);
      this.renderHead = this.renderHead.bind(this);

      this.testExpandItem = this.testExpandItem.bind(this);
      this.testUnExpandItem = this.testUnExpandItem.bind(this);
      this.exp = this.exp.bind(this);

      this.subModuleHandler = this.subModuleHandler.bind(this);
      this.handleClick = this.handleClick.bind(this);

      this.handleChangeSearch = this.handleChangeSearch.bind(this);

      this.searchFilter = this.searchFilter.bind(this);
      this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount() {
    // console.log("configs",configs.baseEndpoint.baseURL)
    this._isMounted = true;

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/me', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response / me",response)
        // response.data.data.attributes.name
        this.setState({ userName : response.data.data.attributes.name })
        this.setState({ userId : response.data.data.attributes.id })

        this.setState({ user : response.data.data.attributes })
        this.setState({ error : false })
        this.setState({ loadingPage : false })
        this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        console.log('error',error.response.data)
        console.log('error',error.response.status)
        console.log('error',error.response.headers)
        this.setState({ user : {} })
        this.setState({ error : true })
        this.setState({ loadingPage : true })
        this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // modules
    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: this.state.paramModules
    })
      .then( response => {
        // console.log("response",response)
        // console.log("schedule response.data.data",response.data.data)
        

        var firstLevelArr = []
        var firstLevelStatus = []
        var myFirstModuleArr = []
        forEach(response.data.data, item => {
          // console.log("item",item)

          const sche = {
            ...item,
            status: false
          }

          const expandStatus = {
            status: false
          }

          const myFirstModule = {
            "id": item.attributes.id,
            "name": item.attributes.name,
            "description": item.attributes.description,
            "course_id": item.attributes.course_id,
            "child": []
          }

          firstLevelStatus[item.attributes.id] = expandStatus
          firstLevelArr.push(sche)
          myFirstModuleArr.push(myFirstModule)
        })
        // console.log("firstLevelArr",firstLevelArr)
        this.setState({ moduleItems: firstLevelArr })
        this.setState({ firstLevelStatus: firstLevelStatus })
        this.setState({ myNewModules: myFirstModuleArr })
        // this.setState({ schedulesData : scheArr })

        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  scheduleSearch(value) {

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    console.log("value",value)

    // schedule
    axios.get(configs.baseEndpoint.baseURL+'api/schedules', {
      headers: { 'Authorization': tok },
      params: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
        "filter[description][like]": value,
        "filter[courses_id][not]": "null",
        "filter[modules_id][not]": "null"
      }
    })
      .then( response => {
        // console.log("response",response)
        // console.log("schedule response.data.data",response.data.data)

        var scheArr = []
        forEach(response.data.data, item => {
          // console.log("item",item)

          const sche = {
            "label": item.attributes.description,
            "value": item.attributes.id,
          }

          scheArr.push(sche)
        })

        this.setState({ schedulesData : scheArr })

        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  getSubModules(data,indexSelected) {

    // console.log("getSubModules", data, indexSelected)
    // // console.log("index", data.refKey)

    
    this.setState({ lihatMateriLoading: true })

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // // sub modules
    // // {`schedule_id_${item.attributes.id}`}
    axios.get(configs.baseEndpoint.baseURL+'api/submodules', {
      headers: { 'Authorization': tok },
      // params: this.state.paramModules
      params: {
        "filter[module_id][is]": data.id,
        "filter[parent_id][null]": true,
        "include": "coursematerials",
      }
    })
      .then( response => {
        console.log("response",response)

        // response.data.included.coursematerials
        
        // console.log("sub modules response.data.data",response.data.data)
        
        // console.log("this.state.treeDataMe",this.state.treeDataMe)
        // console.log("sub modules data",data)

        var theChild = []
        forEach(response.data.data, item => {
          // console.log("sub modules item",item)
          // response.data.data.relationships.coursematerials

          // const sche = {
          //   // "label": item.attributes.description,
          //   // "value": item.attributes.id,
          //   // {
          //   "label": item.attributes.name,
          //   "value": item.attributes.id,
          //   "children": []
          //   // }
          // }
          // const sche = {
          //   ...item
          // }
          
          // // item.relationships.coursematerials.id
          // // get course materials
          let arrCourseMaterials = []
          let materialItem = item.relationships.coursematerials
          forEach(materialItem, material => {
            
          //   // response.data.included.coursematerials[course.id]

          //   // created_at: "2020-08-04T02:45:12.000000Z"
          //   // file_path: "/coursematerials/filea.mp4"
          //   // file_type: "mp4"
          //   // id: 2
          //   // sub_modules_id: 3
          //   // updated_at: "2020-08-04T03:01:34.000000Z"

            // CEK LOGO
            // logo-video / file
            let logo = "logo-video"
            let buttonColor = "green"
            if (response.data.included.coursematerials[material.id].attributes.file_type === "video") {
              logo = "logo-video"
              buttonColor = "blue"
            }

            if (response.data.included.coursematerials[material.id].attributes.file_type === "doc") {
              logo = "file"
              buttonColor = "green"
            }

            // CEK file_type
            let typeOfFile = "Video"
            
            if(response.data.included.coursematerials[material.id].attributes.file_type === null) {
              if(logo === "logo-video") {
                typeOfFile = "Video"
                
              } else {
                typeOfFile = "Dokumen"
                
              }
            } else {
              typeOfFile = response.data.included.coursematerials[material.id].attributes.file_type
            }

            const theCourse = {
              "created_at": response.data.included.coursematerials[material.id].attributes.created_at,
              "file_path": response.data.included.coursematerials[material.id].attributes.file_path,
              // "file_type": response.data.included.coursematerials[material.id].attributes.file_type,
              "file_type": typeOfFile,
              "id": response.data.included.coursematerials[material.id].attributes.id,
              "sub_modules_id": response.data.included.coursematerials[material.id].attributes.sub_modules_id,
              "logo": logo,
              "button_color": buttonColor,
            }

            arrCourseMaterials.push(theCourse)
          })

          // console.log("arrCourseMaterials",arrCourseMaterials)

          const sche = {
            "id": item.attributes.id,
            "level": item.attributes.level,
            "module_id": item.attributes.module_id,
            "name": item.attributes.name,
            "description": item.attributes.description,
            "parent_id": item.attributes.parent_id,
            "course_materials": arrCourseMaterials
          }

          theChild.push(sche)
        })
        // data.children = theChild

        // console.log("the indexSelected = ",indexSelected)
        // console.log("this.state.myNewModules",this.state.myNewModules)
        // console.log("theChild",theChild)

        var modulesNow = this.state.myNewModules
        var newModulesArr = []
        forEach(modulesNow, (item,index) => {

          let theItem = {}

          if(index === indexSelected) {
            // console.log("item",item)
            theItem = {
              "course_id": item.course_id,
              "description": item.description,
              "id": item.id,
              "name": item.name,
              // "name": "VVVV",
              "child": theChild
            }
          } else {
            theItem = {
              ...item
            }
          }
          newModulesArr.push(theItem)

        })
        // console.log("newModulesArr",newModulesArr)
        
        this.setState({ myNewModules: newModulesArr })
        // this.setState({ subModules: theChild })



        // this.setState({ item: [data.value] })
        // this.setState({ treeDataMe: firstLevelArr })
        // this.setState({ schedulesData : scheArr })

        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
        
        this.setState({lihatMateriLoading: false})
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

  }

  subModuleHandler(data,index) {
    // console.log("subModuleHandler",data,index)
    this.getSubModules(data,index)
  }

  testExpandItem() {
    // console.log("this.state.firstLevelStatus",this.state.firstLevelStatus)
    // console.log("testExpandItem")
    // console.log("this.state.moduleItems",this.state.moduleItems)
    // console.log("this.state.moduleItems[2]",this.state.moduleItems[2])
    var sd = []
    sd["aaa"] = 5
    console.log("sd",sd)
    console.log("sd[aaa]",sd["aaa"])

    // misal :
    // index = 2



    if(this.state.status === false) {
      console.log("false")
      console.log("this.state.moduleItems",this.state.moduleItems)
      var av = this.state.moduleItems

      forEach(av, item => {
        console.log("item",item)

      })

      // this.setState({ status: true })
      // this.setState({ })
    } else {
      console.log("true")
      // this.setState({ status: false })

    }
    
  }

  testUnExpandItem() {
    console.log("testUnExpandItem")
    console.log("this.state.moduleItems",this.state.moduleItems)
  }

  handleExpand(eventKey) {
    // console.log("handleExpand",eventKey)
    // this.getSubModules(eventKey)
  }

  handleClick(data) {
    console.log("handleClick",data)
  }

  renderHead(val) {
    // return (<Icon icon='star' />)
    return (<div><Icon icon='book2' /> {val}</div>)
  }

  exp() {
    console.log("exp")
    // return false
  }

  handleChangeSearch(value) {
    // console.log("handleChangeSearch",value)
    this.setState({ valueSearch: value })
  }

  searchFilter() {
    console.log("searchFilter", this.state.valueSearch)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    let paramModules = {
      ...this.state.paramModules,
      "filter[name][like]": this.state.valueSearch
    }


    // modules
    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: paramModules
    })
      .then( response => {
        // console.log("response",response)
        console.log("schedule response.data.data",response.data.data)
        

        // var firstLevelArr = []
        // var firstLevelStatus = []
        var myFirstModuleArr = []
        forEach(response.data.data, item => {
        //   // console.log("item",item)

        //   const sche = {
        //     ...item,
        //     status: false
        //   }

        //   const expandStatus = {
        //     status: false
        //   }

          const myFirstModule = {
            "id": item.attributes.id,
            "name": item.attributes.name,
            "description": item.attributes.description,
            "course_id": item.attributes.course_id,
            "child": []
          }

        //   firstLevelStatus[item.attributes.id] = expandStatus
        //   firstLevelArr.push(sche)
          myFirstModuleArr.push(myFirstModule)
        })
        // console.log("firstLevelArr",firstLevelArr)
        // this.setState({ moduleItems: firstLevelArr })
        // this.setState({ firstLevelStatus: firstLevelStatus })
        this.setState({ myNewModules: myFirstModuleArr })
        // this.setState({ schedulesData : scheArr })

        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  resetFilter() {
    this.setState({ valueSearch: "" })
    
  }

  render () {

    // const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "student") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let items = (
      <Panel header="No Data">
        No Data
      </Panel>
    )

    // let child = (
    //   <List.Item key={0} index={0}>
    //     AAAA
    //   </List.Item>
    // )

    // if(this.state.subModules.length > 0) {
    //   // console.log("this.state.subModules",this.state.subModules)
    //   child = this.state.moduleItems.map((item,index) => 
    //     <List.Item 
    //       key={item.attributes.id} 
    //       index={index}
    //     >
    //       {item.attributes.name}
    //     </List.Item>
    //   )
    // }

    // console.log("this.state.moduleItems",this.state.moduleItems)

    if(this.state.myNewModules.length > 0) {
      // console.log("masuk",this.state.myNewModules)
      // items = this.state.moduleItems.map((item) => 
      //   console.log("item",item)
        
      // )

      items = this.state.myNewModules.map((item,index) => 

        <Panel 
          // onSelect={(value) => {this.handleExpand(value)}}
          onSelect={() => {this.handleExpand(index)}}
          // header={item.attributes.name} 
          header={ this.renderHead(item.name) }
          eventKey={item.id}
          key={item.id}
          collapsible={true}
          // id={index}
          // bodyFill={true}
          // expanded={this.exp}
          // expanded={this.state.moduleItems[index].status}
          // expanded={item.status}
          // expanded={this.state.status}
          // expanded={this.state.firstLevelStatus[item.attributes.id].status}
        >
          <Grid fluid>
            <Row className="">
              <Col xs={24}>
                <div>
                  <p><b>Deskripsi</b> : {item.description}</p>
                </div>
                <div className="subModuleBtnContainer">
                  <Button 
                    color="violet"
                    appearance="primary"
                    onClick={ () => this.subModuleHandler(item,index)}
                    size={'xs'}
                    key={item.id}
                    id={index}
                    loading={this.state.lihatMateriLoading}
                  >
                    Lihat Materi
                  </Button>
                {/*
                  <Panel 
                    header="Sub Modules" 
                    collapsible={false}
                  >
                    
                  </Panel>
                */}
                </div>
                <div>
                  <SubModuleList 
                    id={item.id} 
                    theData={item.child} 
                    index={index}
                  />
              {/*
                  <List bordered>
                    <List.Item 
                      key={item.id} 
                      index={index}
                    >
                      No Data
                    </List.Item>
                  </List>
                  <List bordered>
                    <List.Item key={0} index={0}>
                      AAAA
                    </List.Item>
                  </List>
              */}
                </div>
              </Col>
            </Row>
          </Grid>
        </Panel>
      )


    }

    return (
      <>
        <LayoutRsuite role={this.props.role}/>
        <div className="dashboardContainer">
          <div className="jumbotron">
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>Materi</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Panel header="Pencarian">
              <Grid fluid>
                <Row className="">
                  <Col xs={24} className="subModuleBtnContainer">
                    <Input 
                      placeholder="Masukkan Nama Module"
                      onChange={this.handleChangeSearch}
                      value={this.state.valueSearch} 
                    />
                  </Col>
                  <Col xs={24} className="subModuleBtnContainer">
                    <ButtonToolbar>
                      <Button 
                        size="xs"
                        color="violet"
                        appearance="default"
                        onClick={this.searchFilter}
                      >
                        Cari
                      </Button>
                      <Button 
                        size="xs"
                        color="blue"
                        appearance="default"
                        onClick={this.resetFilter}
                      >
                        Reset
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>  
              </Grid>
            </Panel>
          </div>
          <div>
            <Grid fluid>
              <Row className="">
                {/*
                <Col xs={24}>
                  <Button 
                    appearance="primary"
                    onClick={this.testExpandItem}
                  >
                    Expand
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button 
                    appearance="primary"
                    onClick={this.testUnExpandItem}
                  >
                    Unexpand
                  </Button>
                </Col>
                */}
                <Col xs={24}>
                  
                    {items}
                    {/*
                    <Panel header="Panel 1" eventKey={1}>
                      AAA
                    </Panel>
                    <Panel header="Panel 2" eventKey={2}>
                      BBB
                    </Panel>
                    <Panel header="Panel 3" eventKey={3}>
                      CCC
                    </Panel>
                    */}
                  
                </Col>
              </Row>
            </Grid>
          </div>

          

          {
            // loadPage
            
            // if(this.state.loadingPage) {
            // }
            // console.log("VVVV", this)
          }

          
        </div>
      </>
    );
  }
}

export default StudentSubject;
