import BaseService from '../BaseService/index'

class Users extends BaseService {
  // constructor() {
  //   super()
  // }

  async getMe(params = {}) {
    // console.log('in', id)
    this.endPoint = 'api/me?include=roles'
    return this.get(params)
  }

  async getUser(id,params = {}) {
    // console.log('in', id)
    this.endPoint = 'api/users?filter[id][is]='+id+'&include=roles'
    return this.get(params)
  }

  async getUsers(params = {}) {
    // this.endPoint = 'users'
    this.endPoint = 'api/users'
    return this.get(params)
  }

  async createUser(data: any) {
    // console.log('createUser ==> data',data)
    // this.endPoint = 'auth/register'
    this.endPoint = 'api/users'
    return this.post(data)
  }

  async deleteUsers(id) {
    // console.log(id)
    // `http://localhost:3000/movies/${movie.id}`;
    this.endPoint = 'api/users'
    return this.delete(id)
  }

  async updateUsers(id: any, data: any) {
    // this.endPoint = 'users'
    this.endPoint = 'api/users'
    return this.putOne(id, data)
  }

  // // new
  async getOneUser(id: any, params= {}) {
    this.endPoint = `api/users`
    return this.getOne(id,params)
  }

  async getYogie() {
    return 'Yogie'
  }

  // async getInactiveUsers(params = {}) {
  //   this.endPoint = 'api/v2/users/inactive'
  //   return this.get(params)
  // }


  // async getOneUserWithInclude(id: any, params = {}) {
  //   // this.endPoint = `api/v2/users`
  //   this.endPoint = 'api/v2/users/' + id + '?include=codes'
  //   // return this.getOne(params)
  //   return this.get(params)
  // }

  // async getOneUserCart(id: any, params = {}) {
  //   // this.endPoint = 'api/v2/users/'+id+'?include=codes'
  //   this.endPoint = `api/v2/users`
  //   return this.getOne(id, params)
  // }

  // async createUser(data: any) {
  //   // this.endPoint = 'auth/register'
  //   this.endPoint = 'api/v2/users'
  //   return this.post(data)
  // }

  // async getRoles(params = {}) {
  //   this.endPoint = 'roles'
  //   return this.get(params)
  // }

  // async getCategories() {
  //   this.endPoint = 'categories'
  //   return this.get()
  // }

  // async getMemberships(params = {}) {
  //   this.endPoint = 'api/v2/memberships'
  //   return this.get(params)
  // }

  // async updateMembershipLimit(id, data: any){
  //   this.endPoint = `api/v2/memberships/${id}`
  //   return this.put(data)
  // }

  // async getTotalProducts(id, opts) {
  //   this.endPoint = `/api/v2/users/${id}/my-products`
  //   return this.get(opts)
  // }

  // async getAllTotalProducts() {
  //   this.endPoint = `api/v2/users/total-my-products`
  //   return this.get()
  // }

  // async restoreUser(data: any) {
  //   this.endPoint = 'api/v2/users/restore'
  //   return this.post(data)
  // }

  // async getContactUser(userid, opts) {
  //   this.endPoint = `api/v2/users/${userid}/contacts`
  //   return this.get(opts)
  // }

  // async getOneContact(userid, contactid, opts = {}) {
  //   this.endPoint = `api/v2/users/${userid}/contacts`
  //   return this.getOne(contactid, opts)
  // }

  // async setContact(userid, data: any) {
  //   this.endPoint = `api/v2/users/${userid}/contacts`
  //   return this.post(data)
  // }

  // async updateContact(userid, contactid, data: any) {
  //   this.endPoint = `api/v2/users/${userid}/contacts`
  //   return this.putOne(contactid, data)
  // }

  // async deleteContact(userid, contactid) {
  //   this.endPoint = `api/v2/users/${userid}/contacts`
  //   return this.delete(contactid)
  // }
}

export default new Users()
