import React, {Component} from 'react'
// import configs from '../../configs/index'
import RoomsServices from '../../services/Rooms/index'
import Iframe from 'react-iframe';
import './About.css'
// import { Player } from 'video-react';
// import ReactPlayer from 'react-player'
import "../../../node_modules/video-react/dist/video-react.css";
import { 
  // List,
  Grid, 
  Row, 
  Col,
  // Calendar,
  // Modal,
  // Button,
  // ButtonToolbar
} from 'rsuite';

class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      videoLink: "https://drive.google.com/file/d/1pzVOXgrgbjcQE5QD_AylivSC1cxw8lOI/preview",
      // videoLink: "https://media.w3.org/2010/05/sintel/trailer_hd.mp4",
      // videoLink: "https://drive.google.com/uc?export=download&id=1pzVOXgrgbjcQE5QD_AylivSC1cxw8lOI",
      pdfLink: "https://drive.google.com/file/d/1uAaa4uenKDXldRI517fhjDgvtXsXUOnq/preview",
      myLink: "",
      // role: null,
      // validToken: false,
      // error: false,
      // loadingPage: true,
      // user: {},
      // presencestatuses: [],
      // userName: "",
      // userId: null,

      // valueNik: "",
      // valueJenis: "",
      // valueAlasan: "",

      // scheduleSelected: "",

      // paramModules: {
      //   "page[num]": 1,
      //   "page[limit]": 20,
      //   "sort": "created_at",
      //   // "filter[id][in]": "1,2,3"
      // },

      // schedulesData: [],

      // selectValueExpand: [],
      // item: [],

      // panel: [],

      // moduleItems: [],
      // myNewModules: [],

      // subModules: [],

      // firstLevelStatus: [],

      // child: [],

      // status: false,

    }

      // this.getSubModules = this.getSubModules.bind(this);
      // this.handleExpand = this.handleExpand.bind(this);
      // this.renderHead = this.renderHead.bind(this);

      // this.testExpandItem = this.testExpandItem.bind(this);
      // this.testUnExpandItem = this.testUnExpandItem.bind(this);
      // this.exp = this.exp.bind(this);

      // this.subModuleHandler = this.subModuleHandler.bind(this);
      // this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    // id: "1598240253"
    // name: "jalil"
    // password: "attendee"
    let payload = {
      "id": "1598240253",
      "name": "jepang",
      "password": "attendee"
    }

    // console.log("payload",payload)
    let result = await RoomsServices.joinRoom(payload, {})
    // console.log()
    // window.open(result.data.url, "_blank")
    this.setState({ myLink: result.data.url })
  }

  render(){
    return (

     <div className="">
       {/*
       <Grid fluid>
         <Row>
           <Col xs={24}>
             <Iframe url={this.state.videoLink}
               // width="640px"
               // height="480px"
             />
           </Col>
         </Row>
       </Grid>
       */}
      
       <Grid fluid>
         <Row>
           <Col xs={24}>
             <Iframe url={this.state.myLink}
               // width="640px"
               // height="480px"
               // id="myId"
               // className="pdfIframe"
               // display="initial"
               // position="relative"
             />
           </Col>
         </Row>
       </Grid>

     {/*
       <Grid fluid>
         <Row>
           <Col xs={24}>
             <Iframe url={this.state.pdfLink}
               // width="640px"
               // height="480px"
               // id="myId"
               // className="pdfIframe"
               // display="initial"
               // position="relative"
             />
           </Col>
         </Row>
       </Grid>
     */}
     </div>
    );
  }
}

export default About;