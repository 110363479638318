import React, {Component} from 'react'
import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './Schedules.css'

import axios from 'axios'
import Auth from '../../services/Auth/index'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom"
import { 
  Loader,
  Panel,
  Col,
  Icon,
  Grid,
  Row,
  // Calendar,
  Modal,
  Input,
  SelectPicker,
  Button,
  ButtonToolbar,
  IconButton,
  DatePicker,
  Breadcrumb,
  Alert
} from 'rsuite';
import schedules from '../../services/Schedules/index'
import forEach from "lodash/forEach"

class Schedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSimpan: false,
      actionItem: "new",
      editSelectedScheduleId: null,
      show: false,
      size: "xs",
      rooms: [],
      role: null,
      schedules: [],
      modules: {},
      courses: {},
      periodes: {},
      activePage: 1,
      limitUserPagination: 3,
      countedTotalPage: 1,
      searchDesc: "",

      searchCourses: "",
      coursesData: [],
      valueCourses: "",

      searchModules: "",
      modulesData: [],
      valueModules: "",

      searchPeriodes: "",
      periodesData: [],
      valuePeriodes: "",

      valueOrder: "",
      valueDescription: "",
      valueDate: new Date(),
    };
    this.handleCreateRoom = this.handleCreateRoom.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.handleSearchDesc = this.handleSearchDesc.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.resetSearch = this.resetSearch.bind(this);

    this.coursesChange = this.coursesChange.bind(this);
    this.coursesSearch = this.coursesSearch.bind(this);

    this.modulesChange = this.modulesChange.bind(this);
    this.modulesSearch = this.modulesSearch.bind(this);

    this.periodesChange = this.periodesChange.bind(this);
    this.periodesSearch = this.periodesSearch.bind(this);

    this.handleOrder = this.handleOrder.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.dateChange = this.dateChange.bind(this);
    
    this.simpanHandler = this.simpanHandler.bind(this);

    this.renderHead = this.renderHead.bind(this);
  }

  async componentDidMount() {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'/api/me', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)
        // response.data.data.attributes.name
        this.setState({ user : response.data.data.attributes })
        this.setState({ error : false })
        this.setState({ loadingPage : false })
        this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        this.setState({ user : {} })
        this.setState({ error : true })
        this.setState({ loadingPage : true })
        this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // get all rooms
    axios.get(configs.baseEndpoint.baseURL+'/api/testbbb/getallmeetings', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response.data",response.data)
        let arrRooms = []
        forEach(response.data, item => {
          // console.log("item.attendees => ",item.attendees)

          let rooms = {
            "id": item.meetingID,
            "maxUsers": item.maxUsers,
            "meetingName": item.meetingName, 
            "attendees": item.attendees.length,
            "attendeePW": item.attendeePW,
            "moderatorPW": item.moderatorPW,
            "participantCount": item.participantCount,
            "running": item.running
          }
          arrRooms.push(rooms)
        })
        this.setState({ rooms : arrRooms })
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
    // if() {

    // }
    await this.getItemsSchedules()
  }

  async getItemsSchedules (page=1) {
    try {
      const opts = {
        params: {
            // "page[num]": 1,
            "page[num]": page,
            // "page[limit]": this.state.limitUserPagination,
            "page[limit]": 10,
            "sort": "-created_at",
            "filter[description][like]": this.state.searchDesc,
          include: "modules,courses,presences,periodes,filecourses"
          //   page: this.tablePagination.page,
          //   limit: this.tablePagination.rowsPerPage,
          //   sortedBy: this.tablePagination.descending ? "desc" : "asc",
          //   orderBy: this.tablePagination.sortBy,
          //   include: "details.ref.images,details.ref.variation,user"
        }
      }
      const schedulesResp = await schedules.getSchedules(opts);
      // console.log("schedulesResp.data",schedulesResp.data)

      // console.log("schedulesResp.data.data",schedulesResp.data.data)
      
      // const aaa = schedulesResp.data.data.map((item) => 
      //   ({
      //     "attributes": item.attributes,
      //     "attributes.aaa" : 2
          
      //   })
      // );

      // console.log("aaa", aaa)

      this.setState({ schedules : schedulesResp.data.data })
      this.setState({ modules : schedulesResp.data.included.modules })
      this.setState({ courses : schedulesResp.data.included.courses })
      this.setState({ periodes : schedulesResp.data.included.periodes })
      // this.setState({ courses : schedulesResp.data.included.courses })
      // 0: "modules"
      // 1: "courses"
      // 2: "presences"
      // 3: "periodes"
      // 4: "filecourses"

    }
    catch(err) {
      console.log("err",err)
    }
  }

  handleCreateRoom(data) {
    // attendeePW: "attendee"
    // maxParticipants: 5
    // meetingName: "schedule_id_28/"
    // moderatorPW: "moderator"
    console.log("handleCreate ==> data", data)
  }

  async handleEdit(data) {
    // console.log("data",data)
    this.setState({
      actionItem: "edit",
      editSelectedScheduleId: data.id
    })
    // console.log("handleEdit ==> data", data.id)
    const opts = {
      params : {
        "include": "modules,courses,presences,periodes,filecourses"
      }
    }
    let respOne = await schedules.getOneSchedules(data.id, opts)
    // console.log("respOne.data",respOne.data)
    // console.log("respOne.data.data",respOne.data.data)

    let arrCourse = []
    if(respOne.data.data.relationships.courses.length > 0) {
        let course_id = respOne.data.data.relationships.courses[0].id
        let course_data = respOne.data.included.courses[course_id].attributes

        // course_data_name = course_data.name
        // course_data_id = course_data.id

        let setCourse = {
          "label": course_data.name,
          "value": course_data.id,
        }
        arrCourse.push(setCourse)
    }

    this.setState({ coursesData: arrCourse })

    // Modules
    // ====
    let arrModules = []
    if(respOne.data.data.relationships.modules.length > 0) {
      let modules_id = respOne.data.data.relationships.modules[0].id
      let module_data = respOne.data.included.modules[modules_id].attributes

      // module_data_name = module_data.name
      // module_data_id = module_data.id

      let setModule = {
        "label": module_data.name,
        "value": module_data.id,
      }
      arrModules.push(setModule)
    }

    this.setState({ modulesData: arrModules })

    // Periodes
    let arrPeriodes = []
    let periodes_id = respOne.data.data.relationships.periodes.id
    // console.log("course_id",course_id)
    let periode_data = respOne.data.included.periodes[periodes_id].attributes
    // console.log("course_data",course_data)
    let setPeriode = {
      "label": periode_data.name,
      "value": periode_data.id,
    }
    arrPeriodes.push(setPeriode)
    this.setState({ periodesData: arrPeriodes })

    // set date format
    // respOne.data.data.attributes.date_of_meeting
    const date = new Date(respOne.data.data.attributes.date_of_meeting)

    // console.log('date edit',date)

    // console.log("respOne.data.data.attributes.courses_id",respOne.data.data.attributes.courses_id)
    // console.log("respOne.data.data.attributes.modules_id",respOne.data.data.attributes.modules_id)

    let courses_id = respOne.data.data.attributes.courses_id
    let modules_id = respOne.data.data.attributes.modules_id

    if(courses_id !== null) {
      this.setState({valueCourses: courses_id})
    }

    if(modules_id !== null) {
      this.setState({valueModules: modules_id})
    }  

    let order = respOne.data.data.attributes.order_of_meeting
    if(order === null) {
      order = ""
    }
    
    // arrModules

    this.setState({
      // actionItem: "edit"
      // valueCourses: "",
      // valueModules: "",
      valuePeriodes: respOne.data.data.attributes.periode_id,
      valueOrder: order,
      valueDescription: respOne.data.data.attributes.description,
      valueDate: date,
    })

    // this.setState({ coursesData:  })

    

    // this.setState({actionItem: "edit"})

    // open modal
    this.open("xs")

  }

  async handleDelete(data) {
    // console.log("handleDelete ==> data", data.id)
    let respDel = await schedules.deleteSchedules(data.id)
    console.log("respDel",respDel)
    await this.getItemsSchedules()
  }

  handleSearchDesc(value) {
    this.setState({
      searchDesc: value,
    });
  }

  async submitSearch() {
    // console.log("this.state.searchDesc",this.state.searchDesc)
    await this.getItemsSchedules()
  }

  async resetSearch() {
    // console.log("this.state.searchDesc",this.state.searchDesc)
    this.setState({
      searchDesc: "",
    });
    // await this.getItemsSchedules()
  }

  open(size) {
    // console.log("this.state.valueCourses",this.state.valueCourses)
    this.setState({
      size,
      show: true,
    });
  }

  close() {
    this.setState({
      show: false,
      valueCourses: "",
      valueModules: "",
      valuePeriodes: "",
      valueOrder: "",
      valueDescription: "",
      valueDate: new Date(),
    });
  }

  coursesChange(value) {
    this.setState({ valueCourses : value })
  }

  dateChange(value) {
    this.setState({ valueDate : value }) 
  }

  coursesSearch(value) {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/courses', {
      headers: { 'Authorization': tok },
      params: {
          "page[num]": 1,
          // "page[num]": page,
          // "page[limit]": this.state.limitUserPagination,
          "page[limit]": 10,
          "sort": "-created_at",
          "filter[name][like]": value,
        // include: "modules"
      }
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrItem = []
        forEach(response.data.data, item => {
          const theItem = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrItem.push(theItem)
          // console.log("item",item)
        })
        this.setState({ coursesData : arrItem })
      } )
      .catch( error => {
        console.log("error",error)
      });
  }

  modulesChange(value) {
    this.setState({ valueModules : value })
  }

  modulesSearch(value) {
    // console.log("this.state.valueCourses",this.state.valueCourses)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: {
          "page[num]": 1,
          // "page[num]": page,
          // "page[limit]": this.state.limitUserPagination,
          "page[limit]": 10,
          "sort": "-created_at",
          "filter[name][like]": value,
          "filter[course_id][is]": this.state.valueCourses,
        // include: "modules"
      }
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrItem = []
        forEach(response.data.data, item => {
          const theItem = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrItem.push(theItem)
          // console.log("item",item)
        })
        this.setState({ modulesData : arrItem })
      } )
      .catch( error => {
        console.log("error",error)
      });
  }

  periodesChange(value) {
    this.setState({ valuePeriodes : value })
  }

  periodesSearch(value) {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/periodes', {
      headers: { 'Authorization': tok },
      params: {
          "page[num]": 1,
          // "page[num]": page,
          // "page[limit]": this.state.limitUserPagination,
          "page[limit]": 10,
          "sort": "-created_at",
          "filter[name][like]": value,
        // include: "modules"
      }
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrItem = []
        forEach(response.data.data, item => {
          const theItem = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrItem.push(theItem)
          // console.log("item",item)
        })
        this.setState({ periodesData : arrItem })
      } )
      .catch( error => {
        console.log("error",error)
      });
  }

  handleOrder(value) {
    this.setState( { valueOrder: value } );
  }

  handleDescription(value) {
    this.setState( { valueDescription: value } );
  }

  handleDate(value) {
    this.setState( { valueDate: value } );
  }

  newSchedule() {
    this.setState({actionItem: "new"})
    this.open("xs")
  }

  async simpanHandler() {
    this.setState({ loadingSimpan: true })
    if(this.state.actionItem === "new") {
      // console.log("this.state.valueCourses",this.state.valueCourses)
      // console.log("this.state.valueModules",this.state.valueModules)
      // console.log("this.state.valuePeriodes",this.state.valuePeriodes)
      // console.log("this.state.valueOrder",this.state.valueOrder)
      // console.log("this.state.valueDescription",this.state.valueDescription)
      // console.log("this.state.valueDate",this.state.valueDate)
      
      const date = new Date(this.state.valueDate)
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      let year = date.getFullYear()

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      let hour = date.getHours()
      let minute = date.getMinutes()
      let second = date.getSeconds()

      let newDate = [year, month, day].join('-')
      let newTime = [hour,minute,second].join(':')
      let dateSend = [newDate,newTime].join(' ')

      const payload = {
        "courses_id": this.state.valueCourses,
        "modules_id": this.state.valueModules,
        "periode_id": this.state.valuePeriodes,
        "order_of_meeting": this.state.valueOrder,
        "description": this.state.valueDescription,
        "date_of_meeting": dateSend
      }

      // console.log("payload",payload)
      // console.log("NEW")
      let responseCreate = await schedules.createSchedules(payload)
      // console.log("responseCreate",responseCreate)
      if(responseCreate.data.success) {
        Alert.success('Tambah Berhasil.', 5000)
        await this.getItemsSchedules()
        this.close()
      }
    } else {
      // console.log("this.state.valueCourses",this.state.valueCourses)
      // console.log("this.state.valueModules",this.state.valueModules)
      // console.log("this.state.valuePeriodes",this.state.valuePeriodes)
      // console.log("this.state.valueOrder",this.state.valueOrder)
      // console.log("this.state.valueDescription",this.state.valueDescription)
      // console.log("this.state.valueDate",this.state.valueDate)

      const date = new Date(this.state.valueDate)
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      let year = date.getFullYear()

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      let hour = date.getHours()
      let minute = date.getMinutes()
      let second = date.getSeconds()

      let newDate = [year, month, day].join('-')
      let newTime = [hour,minute,second].join(':')
      let dateSend = [newDate,newTime].join(' ')

      const payload = {
        "courses_id": this.state.valueCourses,
        "modules_id": this.state.valueModules,
        "periode_id": this.state.valuePeriodes,
        "order_of_meeting": this.state.valueOrder,
        "description": this.state.valueDescription,
        "date_of_meeting": dateSend
      }

      // console.log("EDIT", payload)
      // console.log("EDIT", this.state.editSelectedScheduleId)
      
      let responseEdit = await schedules.updateSchedules(this.state.editSelectedScheduleId, payload)
      // console.log("responseEdit",responseEdit.data.success)
      if(responseEdit.data.success) {
        Alert.success('Ubah Berhasil.', 5000)
        await this.getItemsSchedules()
        this.close()
      }
    }
    this.setState({ loadingSimpan: false })
    // console.log("this.state.actionItem",this.state.actionItem)
  }

  renderHead(val) {
    // return (<div><Icon icon='book2' /> {val}</div>)
    let res = val.split(" ")
    let date = res[0]
    let time = res[1]

    return (
      <>
        <div>
          <Icon icon='calendar' /> <b>Tanggal :</b>
        </div>
        <div>
          {date}
        </div>
        <div>
          <Icon icon='clock-o' /> <b>Jam :</b>
        </div>
        <div>
          {time}
        </div>
      </>
    )
  }

  render () {
    // {console.log("schedule",schedule)}
    
    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")
    // console.log('this.state.activePage',this.state.activePage)

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let action = "Edit"
    if(this.state.actionItem === "new") {
      action = "Tambah"
    }
    
    let schedules = <Loader size="lg" backdrop content="tunggu..." vertical />;
    // console.log('Users')
    if(this.state.schedules.length > 0) {
      schedules = this.state.schedules.map((schedule) => 
        
        <Col xs={24} id={`schedule_id_${schedule.attributes.id}`} key={schedule.attributes.id}>
          <div className="myCard">
            <Panel shaded >
              <Panel 
                // header={schedule.attributes.date_of_meeting} 
                header={this.renderHead(schedule.attributes.date_of_meeting)} 
                collapsible 
                defaultExpanded={false}
              >
                  <Grid fluid>
                    <Row>
                      <Col xs={24} >
                        {/*
                        <div>
                          <p>
                            <b>Periode : </b><small>{this.state.periodes[schedule.attributes.periode_id].attributes.name}</small>
                          </p>
                        </div>
                        */}
                        <div>
                          <p>
                            <b>Deskripsi : </b><small>{schedule.attributes.description}</small>
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Tanggal : </b><small>{schedule.attributes.date_of_meeting}</small>
                          </p>
                        </div>
                        <div>
                          <ButtonToolbar>
                            {/*
                            <Button 
                              color="violet" 
                              appearance='primary' 
                              block
                              onClick={() => {this.handleCreateRoom(schedule.attributes)}}
                            >
                              <Icon icon="sign-in" /> Create Room
                            </Button>
                            */}
                            <Button 
                              color="violet" 
                              appearance='primary' 
                              block
                              onClick={() => {this.handleEdit(schedule.attributes)}}
                            >
                              <Icon icon="sign-in" /> Edit
                            </Button>
                            <Button 
                              color="violet" 
                              appearance='primary' 
                              block
                              onClick={() => {this.handleDelete(schedule.attributes)}}
                            >
                              <Icon icon="sign-in" /> Delete
                            </Button>
                          </ButtonToolbar>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
              </Panel>
            </Panel>
          </div>
        </Col>
      );
    }
    return (
      <>
        <LayoutRsuite />
        <div className="schedulesContainer">
          <div className="container">
            <div className="jumbotron">
              <Breadcrumb>
                <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
                <Breadcrumb.Item active>{path}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="jumbotron">

              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Action" collapsible bordered defaultExpanded={false}>
                      <Button 
                        color="green" 
                        onClick={() => this.newSchedule('xs')}
                      >
                        <Icon 
                          icon="user-plus"
                          // onClick={() => {console.log('Add')}}
                          // onClick={() => this.open('xs')}
                          // onClick={() => this.newSchedule('xs')}
                        />
                      </Button>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Filter" collapsible bordered defaultExpanded={false}>
                      <Grid fluid>
                        <Row className="">
                          <Col xs={16}>
                            <Input 
                              onChange={this.handleSearchDesc}
                              style={{ marginBottom: 10 }} 
                              size="md" 
                              placeholder="Search ..."
                              value={this.state.searchDesc} 
                            />
                          </Col>
                          <Col xs={4}>
                            <IconButton
                              appearance="ghost"
                              color="violet"
                              icon={
                                <Icon
                                  className="fill-color" 
                                  icon="search" 
                                />
                              } 
                              placement="left" 
                              size="xs"
                              onClick={this.submitSearch}
                            >
                            </IconButton>
                          </Col>
                          <Col xs={4}>
                            <IconButton
                              appearance="ghost"
                              color="violet"
                              icon={
                                <Icon
                                  className="fill-color" 
                                  icon="eraser" 
                                />
                              } 
                              placement="left" 
                              size="xs"
                              onClick={this.resetSearch}
                            >
                            </IconButton>
                          </Col>
                        </Row>
                      </Grid>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
                <Modal.Header>
                  <Modal.Title>{action} Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid fluid>
                    <Row className="">
                      {/*
                      courses_id
                      modules_id
                      periode_id
                      order_of_meeting
                      description
                      date_of_meeting
                      */}
                      <Col xs={18}>
                        {/*
                        <Input value={this.state.valueDate} onChange={this.handleDate} style={{ marginBottom: 10 }} size="md" placeholder="Tanggal" />
                        */}
                        <DatePicker
                          value={this.state.valueDate}
                          onChange={(value) => {this.dateChange(value)}}
                          style={{ marginBottom: 10 }}
                          format="YYYY-MM-DD HH:mm:ss"
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date()
                            }
                          ]}
                        />

                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.coursesChange(value)}}
                          onSearch={(value) => {this.coursesSearch(value)}}
                          data={this.state.coursesData}
                          value={this.state.valueCourses}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Courses"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.modulesChange(value)}}
                          onSearch={(value) => {this.modulesSearch(value)}}
                          data={this.state.modulesData}
                          value={this.state.valueModules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Modules"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.periodesChange(value)}}
                          onSearch={(value) => {this.periodesSearch(value)}}
                          data={this.state.periodesData}
                          value={this.state.valuePeriodes}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Periodes"
                        />
                      </Col>
                      <Col xs={18}>
                        <Input 
                          value={this.state.valueOrder} 
                          onChange={this.handleOrder} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Pertemuan ke (isi dengan angka)" />
                      </Col>

                      <Col xs={18}>
                        <Input value={this.state.valueDescription} onChange={this.handleDescription} style={{ marginBottom: 10 }} size="md" placeholder="Deskripsi" />
                      </Col>
                    </Row>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  <Button 
                    loading={this.state.loadingSimpan}
                    onClick={this.simpanHandler}
                    color="violet"
                   >
                    Simpan
                  </Button>
                  <Button onClick={this.close} appearance="subtle">
                    Batal
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  {schedules}
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  } 
}

export default Schedules;

