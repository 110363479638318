import React, { Component } from 'react'
// import configs from '../../configs/index'
import { 
  // List,
  Button,
  Icon,
  // Grid,
  // Row,
  // Col,
  // Container, 
  // Sidebar, 
  // Header, 
  // Content, 
  // Footer, Sidenav , 
  // Nav, Icon, 
  // Dropdown, 
  // Navbar, 
  // IconButton, Avatar 
} from 'rsuite'
import './ListCourseMaterials.css'
import { 
  // Redirect, 
  // Link 
} from "react-router-dom"

class ListCourseMaterials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoLink: "https://drive.google.com/file/d/1pzVOXgrgbjcQE5QD_AylivSC1cxw8lOI/preview",
      pdfLink: "https://drive.google.com/file/d/1uAaa4uenKDXldRI517fhjDgvtXsXUOnq/preview",
      // expand: true,
      // loadingSubmit: false

    };
    // this.handleToggle = this.handleToggle.bind(this);
    // console.log('props in layout rsuite', this.props)
    // this.openVideo = this.openVideo.bind(this);
    // this.openPdf = this.openPdf.bind(this);
    this.openLink = this.openLink.bind(this);
    
  }

  // openVideo() {
  //   window.open(this.state.videoLink, "_blank")
  // }

  // openPdf() {
  //   window.open(this.state.pdfLink, "_blank")
  // }

  openLink(data) {
    // console.log("openLink",data)
    window.open(data.file_path, "_blank")
  }

  render() {
    console.log("this.props.dataFiles",this.props.dataFiles)

    let buttonItem = (
      <div className="itemInList">
        File Belum Ada
      </div>
    )

    if(this.props.dataFiles.length > 0) {
      buttonItem = this.props.dataFiles.map((item,index) => 
        <div className="itemInList" key={item.id}>
          <Button 
            color={item.button_color} 
            size="xs"
            onClick={() => {this.openLink(item)}}
            key={item.id}
          >
            <Icon icon={item.logo}  /> {item.file_type}
          </Button>
        </div>   
      )
    }

    return (
      <>
        {buttonItem}
        {/*
        <div className="itemInList">
          <Button 
            color="blue" 
            size="xs"
            onClick={this.openVideo}
          >
            <Icon icon="logo-video"  /> Video
          </Button>
        </div>
        <div className="itemInList">
          <Button 
            color="blue" 
            size="xs"
            onClick={this.openPdf}
          >
            <Icon icon="file"  /> PDF
          </Button>
        </div>
        */}
      </>
    )
  }
}

export default ListCourseMaterials;
