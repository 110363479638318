import React, {Component} from 'react'
import configs from '../../configs/index'
import { 
  // Loader,
  Panel,
  Breadcrumb,
  Grid,
  Row,
  Col,
  Icon,
  Alert,
  // Input,
  // Radio,
  // RadioGroup,
  // Form,
  // FormGroup,
  // ControlLabel,
  // HelpBlock,
  // FormControl,
  // Button,
  // ButtonToolbar
  // Calendar,
  // Modal,
  Button,
  ButtonToolbar,
  // SelectPicker
} from 'rsuite';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite'
import './StudentSchedules.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
import forEach from "lodash/forEach"

class StudentSchedules extends Component{
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      validToken: false,
      error: false,
      loadingPage: true,
      user: {},
      presencestatuses: [],
      userName: "",
      userId: null,

      valueNik: "",
      valueJenis: "",
      valueAlasan: "",

      scheduleSelected: "",

      paramSchedules: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
        "filter[courses_id][not]": "null",
        "filter[modules_id][not]": "null"
      },
      valueDate: new Date(),

      schedulesData: [],
      rooms: [],
      hasRoom: false,
      hasRoomMeetingId: null,
      hasAttendeePw: null,

      joinLoading: false,

      idRoomSelected: null,

      // expand: true,
      // myEmail: '',
      // myPassword: '',
      // loadingSubmit: false,
      // redirect: false,
      // servis: '',
      // user: {}
    }
      this.onlyDate = this.onlyDate.bind(this);
      this.onlyTime = this.onlyTime.bind(this);
      this.handleJoin = this.handleJoin.bind(this);
      this.joinRoom = this.joinRoom.bind(this);
      this.renderHeaderPanel = this.renderHeaderPanel.bind(this);
      // this.nikChange = this.nikChange.bind(this);
      // this.jenisChange = this.jenisChange.bind(this);
      // this.alasanChange = this.alasanChange.bind(this);
      // this.simpanHandler = this.simpanHandler.bind(this);

      // this.scheduleClean = this.scheduleClean.bind(this);
      // this.scheduleSelect = this.scheduleSelect.bind(this);
      // this.scheduleChange = this.scheduleChange.bind(this);
      // this.scheduleSearch = this.scheduleSearch.bind(this);
  }

  componentDidMount() {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/me', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        this.setState({ userId : response.data.data.attributes.id })
        this.setState({ userName : response.data.data.attributes.name })
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        console.log('error',error.response.data)
        console.log('error',error.response.status)
        console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    const date = new Date()
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    let year = date.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    // let hour = date.getHours()
    // let minute = date.getMinutes()
    // let second = date.getSeconds()

    const nowDate = [year, month, day].join('-')
    // let newTime = [hour,minute,second].join(':')
    // let dateSend = [newDate,newTime].join(' ')
    // console.log("nowDate",nowDate)

    // set date db format NOW
    // limit it to 10
    // next harusnya menggunakan query beetwen date

    axios.get(configs.baseEndpoint.baseURL+'api/schedules', {
      headers: { 'Authorization': tok },
      // params: this.state.paramSchedules
      params: {
        "page[num]": 1,
        "page[limit]": 20,
        "sort": "date_of_meeting",
        "filter[courses_id][not]": "null",
        "filter[modules_id][not]": "null",
        "filter[date_of_meeting][gte]": nowDate
      }
    })
      .then( response => {
        // console.log("response",response)
        // console.log("response.data.data",response.data.data)
        
        this.setState({ schedulesData : response.data.data })
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        console.log('error',error.response.data)
        console.log('error',error.response.status)
        console.log('error',error.response.headers)
        this.setState({ user : {} })
        this.setState({ error : true })
        this.setState({ loadingPage : true })
        this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    
  }

  getRooms(roomsSelected) {

    // console.log("roomsSelected",roomsSelected)

    // const ac_tok = localStorage.getItem("access_token")
    // const tok = "Bearer "+ac_tok

    // get all meeting
    // let hasRoom = false
    axios.get(configs.baseEndpoint.baseURL+'api/testbbb/getallmeetings')
      .then( response => {
        // console.log("response get rooms ",response)
        // console.log("this.state.conferences",this.state.conferences)
        // console.log("response.data.getallmeetings",response.data)
        
        // let hasRoom = false
        if(response.data.length === 0) {
            Alert.warning('Belum Ada Room Sama Sekali.', 5000)
            this.setState({ joinLoading: false })
        } else {
          // let arrRooms = []
          // let hasRoom = false
          forEach(response.data, item => {

            // console.log("item.attendees => ",item.attendees)
            // attendees
            // item.attendees.attendee[0...n]
            // info di dalam nya :
            // clientType: "HTML5"
            // fullName: "yogiedigital"
            // hasJoinedVoice: "false"
            // hasVideo: "false"
            // isListeningOnly: "true"
            // isPresenter: "false"
            // role: "MODERATOR"
            // userID: "w_m1cgqmj6x5tk"

            // let rooms = {
            //   "id": item.meetingID,
            //   "maxUsers": item.maxUsers,
            //   "meetingName": item.meetingName, 
            //   "attendees": item.attendees.length,
            //   "attendeePW": item.attendeePW,
            //   "moderatorPW": item.moderatorPW,
            //   "participantCount": item.participantCount,
            //   "running": item.running
            // }
            // arrRooms.push(rooms)
            
            var meetingName = item.meetingName

            // let hasRoom = false
            if(meetingName.includes(roomsSelected)) {
              // hasRoom = true
              this.setState({ hasRoom : true })
              this.setState({ hasRoomMeetingId : item.meetingID })
              this.setState({ hasAttendeePw : item.attendeePW })
              
              this.joinRoom()
            } else {
              
              Alert.warning('Ruangan Belum Di buat.', 5000)
              this.setState({ joinLoading: false })
            }
          })
        }

        // console.log("hasRoom",hasRoom)
        // this.setState({ hasRoom : true })
        // this.setState({ idRoomSelected : true })
        
        // room tidak ada yang match dengan id schedule
        // Alert.warning('Ruangan Belum Di buat.', 5000)
        // this.setState({ joinLoading: false })
        
      } )
      .catch( error => {
        console.log("error",error)

        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });

    // console.log("after axios get meeting => ", this.state.hasRoom)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  joinRoom() {
    // console.log("joinRoom this.state.hasRoom ==> ", this.state.hasRoom)
    // console.log("joinRoom this.state.hasRoomMeetingId ==> ", this.state.hasRoomMeetingId)
    // console.log("joinRoom this.state.hasAttendeePw ==> ", this.state.hasAttendeePw)
    // console.log("joinRoom this.state.userName ==> ", this.state.userName)
    // console.log("joinRoom this.state.userId ==> ", this.state.userId)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.post(configs.baseEndpoint.baseURL+'api/testbbb/tryjoin', {
      "id": this.state.hasRoomMeetingId,
      "name": this.state.userName,
      "password": this.state.hasAttendeePw
    },{
      headers: { 
        "Authorization": tok,
        "Accept": "application/json"
      },
      // params: this.state.paramSchedules
      params: {}
    })
      .then( response => {
        // console.log("response join ",response)
        window.open(response.data.url, "_blank")
        // console.log("this.state.conferences",this.state.conferences)
        // console.log("response.data.getallmeetings",response.data)
        this.setState({ joinLoading: false })
        
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
  }

  handleJoin(id) {
    // console.log("handleJoin", data)
    // console.log("handleJoin", this.state.userId)
    // console.log("handleJoin", this.state.userName)
    // const result = this.getRooms();
    
    this.setState({ joinLoading: true })

    const roomsSelected = "schedule_id_"+id

    // let result = this.getRooms(roomsSelected)
    this.getRooms(roomsSelected)
    // console.log("this.state.hasRoom",this.state.hasRoom)

    // const payload = {
    //   "user_name": this.state.userName,
    //   "user_id": this.state.userId
    // }
  }

  dateRemoveSeconds(valDate) {
    const date = new Date(valDate)
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    let year = date.getFullYear()
    // console.log("month",month)
    if (month < 10) 
        month = '0' + month;
    if (day < 10) 
        day = '0' + day;
    const formatDate = [year, month, day].join('-')
    // console.log("formatDate",formatDate)

    let hour = date.getHours()
    if(hour < 10) {
      hour = '0'+hour
    }
    let minute = date.getMinutes()
    // console.log("minute",minute)
    // console.log("minute",minute.length)
    if(minute < 10) {
      minute = '0'+minute
    }
    let newTime = [hour,minute].join(':')
    // console.log("minute",minute)
    // let second = date.getSeconds()

    // const formatDate = [year, month, day].join('-')
    // let newTime = [hour,minute].join(':')
    let newDate = [formatDate,newTime].join(' ')
    // console.log("dateRemoveSeconds",valDate)
    // console.log("newDate",newDate)
    // return valDate
    return newDate
  }

  onlyDate(value) {
    // console.log("onlyDate value",value)
    let res = value.split(" ")

    // const date = new Date(value)
    // let month = (date.getMonth() + 1)
    // let day = date.getDate()
    // let year = String(date.getFullYear())
    // // console.log("month",month, typeof month)
    // // console.log("day",day, typeof day)
    // // console.log("year",year, typeof year)
    
    // if (month < 10) {
    //   // console.log("masuk if month")
    //   month = String('0' + month)
    // } else {
    //   month = String(month)
    // }
    // if (day < 10) {
    //   // console.log("masuk if day")
    //   day = String('0' + day)
    // } else {
    //   day = String(day)
    // }

    // var dateString = String(date);
    // var d = new Date(dateString.replace(' ', 'T'));

    // console.log(year, typeof year)
    // console.log(month, typeof month)
    // console.log(day, typeof day)

    // const onlyTheDate = [year, month, day].join('-')
    const onlyTheDate = res[0]
    // console.log(onlyTheDate, typeof onlyTheDate)
    return onlyTheDate
  }

  onlyTime(value) {
    // console.log("onlyDate value",value)
    let res = value.split(" ")

    // const date = new Date(value)
    // let hour = date.getHours()
    // if(hour < 10) {
    //   hour = '0'+hour
    // }
    // let minute = date.getMinutes()
    // // console.log("minute",minute)
    // // console.log("minute",minute.length)
    // if(minute < 10) {
    //   minute = '0'+minute
    // }
    // let onlyTheTime = [hour,minute].join(':')
    let time = res[1].split(":")

    let finishTime = [time[0],time[1]].join(':')

    let onlyTheTime = finishTime

    return onlyTheTime
  }

  formatDescription(value) {
    let str = value
    let n = str.length

    if(n > 18) {
      str = str.substr(0, 18)+" ..."
    }

    return str
  }

  renderHeaderPanel(val) {
    // return (<Icon icon='star' />)
    return (
      <div>
        <div>
          <Icon icon='book2' /> {val.description}
        </div>
        <div>
          <Icon icon='calendar-o' /> {this.onlyDate(val.date_of_meeting)}
        </div>
        <div>
          <Icon icon='clock-o' /> {this.onlyTime(val.date_of_meeting)}
        </div>
      </div>
    )
    // return (
    //   <div><Icon icon='book2' /> {val}</div>
    // )
  }

  render () {

    // const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "student") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let items = (
      <Col>
        <div className="myCard">
          <Panel shaded >
            <Panel collapsible defaultExpanded={true}>
              <div>
                <p>
                  No Data
                </p>
              </div>
            </Panel>
          </Panel>
        </div>
      </Col>
    );
    if(this.state.schedulesData.length > 0) {
      // console.log("this.state.schedulesData",this.state.schedulesData)

      items = this.state.schedulesData.map((item) => 
        
        <Col xs={24} id={`schedule_id_${item.attributes.id}`} key={item.attributes.id}>
          <div className="myCard">
            <Panel shaded >
              {/*
              <Panel header={item.attributes.description} collapsible defaultExpanded={false}>
              <Panel header={this.formatDescription(item.attributes.description)} collapsible defaultExpanded={false}>
              */}
              <Panel 
                header={this.renderHeaderPanel(item.attributes)} 
                collapsible 
                defaultExpanded={false}
              >
                <div>
                  <p>
                    <small><b>Deskripsi</b></small> : <small>{item.attributes.description}</small>
                  </p>
                </div>
                
                <div>
                  <ButtonToolbar>
                    <Button 
                      loading={this.state.joinLoading}
                      color="violet" 
                      appearance='primary' 
                      block
                      onClick={() => {this.handleJoin(item.attributes.id)}}
                    >
                      <Icon icon="sign-in" /> Join Room
                    </Button>
                  </ButtonToolbar>
                </div>
              </Panel>
            </Panel>
          </div>
        </Col>
      );
    }


    return (
      <>
        <LayoutRsuite role={this.props.role}/>
        <div className="dashboardContainer">
          <div className="jumbotron">
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>Jadwal</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Jadwal Kelas Terdekat" shaded>

                    
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </div>

          <div>
            <Grid fluid>
              <Row className="">
                {items}
              </Row>
            </Grid>
          </div>

          

          {
            // loadPage
            
            // if(this.state.loadingPage) {
            // }
            // console.log("VVVV", this)
          }

          
        </div>
      </>
    );
  }
}

export default StudentSchedules;