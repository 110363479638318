import BaseService from '../BaseService/index'

class Modules extends BaseService {
  // constructor() {
  //   super()
  // }

   async getModules(params = {}) {
     // this.endPoint = 'users'
     this.endPoint = 'api/modules'
     return this.get(params)
   }

   async createModules(data: any) {
     // console.log('createUser ==> data',data)
     // this.endPoint = 'auth/register'
     this.endPoint = 'api/modules'
     return this.post(data)
   }

   async deleteModules(id) {
     // console.log(id)
     // `http://localhost:3000/movies/${movie.id}`;
     this.endPoint = 'api/modules'
     return this.delete(id)
   }

   async updateModules(id: any, data: any) {
     // this.endPoint = 'users'
     this.endPoint = 'api/modules'
     return this.putOne(id, data)
   }

   // // new
   async getOneModules(id: any, params= {}) {
     this.endPoint = `api/modules`
     return this.getOne(id,params)
   }
}

export default new Modules()
