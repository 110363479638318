import React, { Component } from 'react';
import { 
  // Container, 
  Sidebar, 
  // Header, 
  // Content, 
  // Footer, 
  Sidenav , Nav, Icon, 
  // Dropdown, 
  Navbar, 
  // IconButton, 
  // Avatar 
} from 'rsuite';

import { 
  // Redirect, 
  Link 
} from "react-router-dom";

import "./SideMenu.css"

const headerStyles = {
  // padding: 18,
  paddingTop: 4,
  fontSize: 16,
  height: 56,
  // background: '#4a148c',
  background: 'white',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}

const bodyStyles = {
  background: 'white',
}

// const iconStyles = {
//   width: 56,
//   height: 56,
//   lineHeight: '56px',
//   textAlign: 'center'
// }

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="default" className="nav-toggle">
      <Navbar.Body>
        {/*
        <Nav>
          <Dropdown
            placement="topStart"
            trigger="click"
            renderTitle={children => {
              return <Icon style={iconStyles} icon="gear" />;
            }}
          >
            <Dropdown.Item>Help</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>Sign out</Dropdown.Item>
          </Dropdown>
        </Nav>
        */}

        <Nav pullRight>
          <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
            <Icon icon={expand ? 'angle-left' : 'angle-right'} />
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

const MenuItem = (props) => {
  // console.log('props xxx',props)
  // const user_role = localStorage.getItem("role")
  // const user_role = props.role
  const user_role = localStorage.getItem("role")
  
  // console.log('user_role',user_role)

  let side = (
        <Nav>
        </Nav>
      );

  switch ( user_role ) {
    case ( 'superadmin' ):
      side = (
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/admindashboard">
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<Icon icon="group" />} className="labelSideMenu" componentClass={Link} to="/adminusers">
            Users
          </Nav.Item>
          <Nav.Item eventKey="3" icon={<Icon icon="book2" />} className="labelSideMenu" componentClass={Link} to="/adminmodules">
            Modules
          </Nav.Item>
          <Nav.Item eventKey="4" icon={<Icon icon="sitemap" />} className="labelSideMenu" componentClass={Link} to="/adminsubmodules">
            Sub Modules
          </Nav.Item>
          <Nav.Item eventKey="5" icon={<Icon icon="calendar" />} className="labelSideMenu" componentClass={Link} to="/adminschedules">
            Schedules
          </Nav.Item>
          <Nav.Item eventKey="6" icon={<Icon icon="book" />} className="labelSideMenu" componentClass={Link} to="/admincoursefile">
            Material Files
          </Nav.Item>
          <Nav.Item eventKey="7" icon={<Icon icon="film" />} className="labelSideMenu" componentClass={Link} to="/classrecords">
            Class Record
          </Nav.Item>
          <Nav.Item eventKey="8" icon={<Icon icon="tasks" />} className="labelSideMenu" componentClass={Link} to="/studenttask">
            Student Task
          </Nav.Item>
          <Nav.Item eventKey="9" icon={<Icon icon="building2" />} className="labelSideMenu" componentClass={Link} to="/adminpresences">
            Presences
          </Nav.Item>
          <Nav.Item eventKey="10" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/adminrooms">
            Rooms
          </Nav.Item>
          <Nav.Item eventKey="11" icon={<Icon icon="user-info" />} className="labelSideMenu">
            {user_role}
          </Nav.Item>
        </Nav>
      );
      return side;
      // break;
    case ( 'admin' ):
      side = (
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/dashboard">
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<Icon icon="user-info" />} className="labelSideMenu">
            {user_role}
          </Nav.Item>
        </Nav>
      );
      return side;
      // break;
    case ( 'operator' ):
      side = (
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/dashboard">
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="calendar" />} className="labelSideMenu" componentClass={Link} to="/schedules">
            Schedules
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/rooms">
            Rooms
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<Icon icon="user-info" />} className="labelSideMenu">
            {user_role}
          </Nav.Item>
        </Nav>
      );
      return side;
      // break;
    case ( 'lecture' ):
      side = (
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/dashboard">
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="calendar" />} className="labelSideMenu" componentClass={Link} to="/schedules">
            Schedules
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/joinroom">
            Join Room
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<Icon icon="user-info" />} className="labelSideMenu">
            {user_role}
          </Nav.Item>
        </Nav>
      );
      return side;
      // break;
    case ( 'student' ):
      side = (
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/dashboard">
            Home
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<Icon icon="calendar" />} className="labelSideMenu" componentClass={Link} to="/schedules">
            Schedules
          </Nav.Item>
        {/*
          <Nav.Item eventKey="3" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/joinroom">
            Join Room
          </Nav.Item>
        */}
        {/*
          <Nav.Item eventKey="4" icon={<Icon icon="upload" />} className="labelSideMenu" componentClass={Link} to="/uploadtask">
            Upload Tugas
          </Nav.Item>
          <Nav.Item eventKey="5" icon={<Icon icon="order-form" />} className="labelSideMenu" componentClass={Link} to="/permissionform">
            Form Ijin
          </Nav.Item>
        */}
          <Nav.Item eventKey="6" icon={<Icon icon="book" />} className="labelSideMenu" componentClass={Link} to="/subject">
            Materi
          </Nav.Item>

          {/*
          <Nav.Item eventKey="1" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/subject">
            Join Room
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/uploadtask">
            Join Room
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/permissionform">
            Join Room
          </Nav.Item>
          <Nav.Item eventKey="7" icon={<Icon icon="user-info" />} className="labelSideMenu" componentClass={Link} to="/dashboard">
            {user_role}
          </Nav.Item>
          */}

        </Nav>
      );
      return side;
      // break;
    default:
      side = (
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/">
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="calendar" />} className="labelSideMenu" componentClass={Link} to="/">
            Schedules
          </Nav.Item>
          <Nav.Item eventKey="1" icon={<Icon icon="peoples-map" />} className="labelSideMenu" componentClass={Link} to="/">
            Rooms
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<Icon icon="user-info" />} className="labelSideMenu">
            {user_role}
          </Nav.Item>
        </Nav>
      );
      return side;
  }
};

class SideMenu extends Component {
  constructor(props) {
    super(props);
    // console.log('props zzz',props)
    this.state = {
      expand: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  // handleLogout = () => {
  //   // console.log('logout')
  //   auth.logout();
  //   window.location.reload(true)
  //   // return <Redirect to='/login' />
  // }
  handleToggle() {
    this.setState({
      expand: !this.state.expand
    });
  }
  render() {
    // console.log('this.state',this.state);
    const { expand } = this.state;
    const user_role = localStorage.getItem("role")
    
    return (
      <Sidebar
        className="sideBarContainer"
        style={{ display: 'flex', flexDirection: 'column' }}
        width={expand ? 260 : 56}
        collapsible
      >
        <Sidenav.Header>
          <div style={headerStyles}>
            <img src="https://amithyainstitute.com/wp-content/uploads/2020/01/amithya-institute-logo-1024x263.png" alt="" width="180"/>
          </div>
        </Sidenav.Header>
        <Sidenav
          className="sideNavContainer"
          expanded={expand}
          appearance="subtle"
        >
          <Sidenav.Body style={bodyStyles}>
            <MenuItem role={user_role} />
          </Sidenav.Body>
        </Sidenav>
      {/*
      */}
        <NavToggle 
          expand={expand} 
          onChange={this.handleToggle} 
          style={bodyStyles}
        />
      </Sidebar>
    );
  }
}

export default SideMenu;
