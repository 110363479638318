import React, {Component} from 'react'
import configs from '../../configs/index'
import { 
  // Loader,
  Panel,
  Breadcrumb,
  Grid,
  Row,
  Col,
  Input,
  Radio,
  RadioGroup,
  Alert,
  // Form,
  // FormGroup,
  // ControlLabel,
  // HelpBlock,
  // FormControl,
  // Button,
  // ButtonToolbar
  // Calendar,
  // Modal,
  Button,
  // ButtonToolbar,
  SelectPicker
} from 'rsuite';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite'
import './StudentPermissionForm.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import Auth from '../../services/Auth/index'
import forEach from "lodash/forEach"

class StudentPermissionForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      validToken: false,
      error: false,
      loadingPage: true,
      user: {},
      presencestatuses: [],
      userName: "",
      userId: null,

      disabled: true,

      valueNik: "",
      valueJenis: "",
      valueAlasan: "",

      scheduleSelected: "",

      paramSchedules: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
        "filter[courses_id][not]": "null",
        "filter[modules_id][not]": "null"
      },

      schedulesData: [],

      simpanLoading: false,

      // expand: true,
      // myEmail: '',
      // myPassword: '',
      // loadingSubmit: false,
      // redirect: false,
      // servis: '',
      // user: {}
    }
      this.nikChange = this.nikChange.bind(this);
      this.jenisChange = this.jenisChange.bind(this);
      this.alasanChange = this.alasanChange.bind(this);

      this.resetHandler = this.resetHandler.bind(this);
      this.simpanHandler = this.simpanHandler.bind(this);

      this.scheduleClean = this.scheduleClean.bind(this);
      this.scheduleSelect = this.scheduleSelect.bind(this);
      this.scheduleChange = this.scheduleChange.bind(this);
      this.scheduleSearch = this.scheduleSearch.bind(this);
  }

  componentDidMount() {
    // console.log("configs",configs.baseEndpoint.baseURL)
    this._isMounted = true;

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/me', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response / me",response)
        // response.data.data.attributes.name
        this.setState({ userName : response.data.data.attributes.name })
        this.setState({ userId : response.data.data.attributes.id })

        this.setState({ user : response.data.data.attributes })
        this.setState({ error : false })
        this.setState({ loadingPage : false })
        this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        console.log('error',error.response.data)
        console.log('error',error.response.status)
        console.log('error',error.response.headers)
        this.setState({ user : {} })
        this.setState({ error : true })
        this.setState({ loadingPage : true })
        this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });

    // schedule
    axios.get(configs.baseEndpoint.baseURL+'api/schedules', {
      headers: { 'Authorization': tok },
      params: this.state.paramSchedules
    })
      .then( response => {
        // console.log("response",response)
        // console.log("schedule response.data.data",response.data.data)

        var scheArr = []
        forEach(response.data.data, item => {
          // console.log("item",item)

          const sche = {
            "label": item.attributes.description,
            "value": item.attributes.id,
          }

          scheArr.push(sche)
        })

        this.setState({ schedulesData : scheArr })

        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  nikChange(value) {
    // console.log("nikChange",value)
    this.setState( { valueNik: value } );
  }

  jenisChange(value) {
    console.log("jenisChange",value)
    this.setState( { valueJenis: value } );
  }

  alasanChange(value) {
    // console.log("alasanChange",value)
    this.setState( { valueAlasan: value } );
  }

  resetHandler() {
    this.scheduleClean()
  }

  simpanHandler() {
    this.setState({ simpanLoading: true })
    const payload = {
      "user_id": this.state.userId,
      "schedule_id": this.state.scheduleSelected,
      "nik": this.state.valueNik,
      "check_in_class": null,
      "check_out_class": null,
      "notes": this.state.valueAlasan,
      "presences_status_id": this.state.valueJenis
    }

    // console.log("payload",payload)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.post(configs.baseEndpoint.baseURL+'api/presences', 
      payload,
      { headers: { 
        "Authorization": tok,
        "Accept": "application/json"
      },
      // params: this.state.paramSchedules
      params: {}
    })
      .then( response => {
        console.log("response post ",response)
        if(response.data.success) {
          this.setState({ simpanLoading: false })
          Alert.success('Surat Ijin Terkirim.', 5000)
          this.resetHandler()
        }
        // simpanLoading
        // window.open(response.data.url, "_blank")
        // console.log("this.state.conferences",this.state.conferences)
        // console.log("response.data.getallmeetings",response.data)
        // this.setState({ joinLoading: false })
        
      } )
      .catch( error => {
        console.log("error",error)
        Alert.error('Server Error.', 5000)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
  }

  scheduleClean() {
    // console.log("scheduleClean()")
    this.setState({
      valueNik: "",
      valueJenis: "",
      valueAlasan: "",
      scheduleSelected: "",
      disabled: true,
    })
  }

  scheduleSelect(value) {
    this.setState( { 
      scheduleSelected: value, 
      disabled: false, 
    } );
  }

  scheduleChange(value) {

  }

  scheduleSearch(value) {

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    console.log("value",value)

    // schedule
    axios.get(configs.baseEndpoint.baseURL+'api/schedules', {
      headers: { 'Authorization': tok },
      params: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
        "filter[description][like]": value,
        "filter[courses_id][not]": "null",
        "filter[modules_id][not]": "null"
      }
    })
      .then( response => {
        // console.log("response",response)
        // console.log("schedule response.data.data",response.data.data)

        var scheArr = []
        forEach(response.data.data, item => {
          // console.log("item",item)

          const sche = {
            "label": item.attributes.description,
            "value": item.attributes.id,
          }

          scheArr.push(sche)
        })

        this.setState({ schedulesData : scheArr })

        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        if(error.response.status === 401) {
          Auth.logout();
          this.props.history.push('/login');
        }
      });
  }


  render () {

    // const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")

    if(user_role !== "student") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    return (
      <>
        <LayoutRsuite role={this.props.role}/>
        <div className="dashboardContainer">
          <div className="jumbotron">
            <Breadcrumb>
              <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
              <Breadcrumb.Item active>Form Izin</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Grid fluid>
              <Row className="">
                <Col xs={24}>
                  <Panel header="Form Ijin" shaded>
                    <div className="perItemInput">
                      <Grid fluid>
                        <Row className="">
                          <Col xs={24}>
                            <span>Nama</span> : <b>{this.state.userName}</b>
                          </Col>
                        </Row>
                        {/*
                        <Row className="">
                          <Col xs={24}>
                            <Input 
                              style={{ width: 200 }} 
                              placeholder="Nama" 
                              value={this.state.userName}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        */}
                      </Grid>
                    </div>
                    <div className="perItemInput">
                      <Grid fluid>
                        <Row className="">
                          <Col xs={24}>
                            <span>Pilih Jadwal</span>
                          </Col>
                        </Row>
                        <Row className="">
                          <Col xs={24}>
                            <SelectPicker
                              // loading={this.state.loadingSimpan}
                              onClean={this.scheduleClean}
                              onSelect={(value) => {this.scheduleSelect(value)}}
                              onChange={(value) => {this.scheduleChange(value)}}
                              onSearch={(value) => {this.scheduleSearch(value)}}
                              data={this.state.schedulesData}
                              style={{ width: 200, marginBottom: 10 }}
                              placeholder="Pilih Jadwal"
                              value={this.state.scheduleSelected}
                            />
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                      {/*
                    <div className="perItemInput">
                      <Grid fluid>
                        <Row className="">
                          <Col xs={24}>
                            <span>NIK</span>
                          </Col>
                        </Row>
                        <Row className="">
                          <Col xs={24}>
                            <Input 
                              style={{ width: 200 }} 
                              placeholder="NIK" 
                              value={this.state.valueNik}
                              onChange={(value) => {this.nikChange(value)}}
                              disabled={this.state.disabled}
                            />
                          </Col>
                        </Row>

                      </Grid>
                    </div>
                      */}
                    <div className="perItemInput">
                      <Grid fluid>
                        <Row className="">
                          <Col xs={24}>
                            <span>Jenis Izin</span>
                          </Col>
                        </Row>
                        <Row className="">
                          <Col xs={24}>
                            {/*
                            <Input style={{ width: 200 }} placeholder="Jenis Ijin" />
                            */}
                            <RadioGroup 
                              name="radioList"
                              onChange={(value) => {this.jenisChange(value)}}
                              value={this.state.valueJenis}
                             >
                              <Radio 
                                value="2"
                                disabled={this.state.disabled}
                              >
                                Izin
                              </Radio>
                              <Radio 
                                value="3"
                                disabled={this.state.disabled}
                              >
                                Sakit
                              </Radio>
                            </RadioGroup>
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                    <div className="perItemInput">
                      <Grid fluid>
                        <Row className="">
                          <Col xs={24}>
                            <span>Alasan</span>
                          </Col>
                        </Row>
                        <Row className="">
                          <Col xs={24}>
                            <Input
                              onChange={(value) => {this.alasanChange(value)}}
                              componentClass="textarea"
                              rows={4}
                              style={{ width: 200, minWidth: 200 }}
                              placeholder="Alasan ..."
                              value={this.state.valueAlasan}
                              disabled={this.state.disabled}
                            />
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                    <div className="perItemInput">
                      <Grid fluid>
                        <Row className="">

                          <Col xs={12}>
                            <Button 
                              appearance="primary"
                              color="blue"
                              onClick={this.resetHandler}
                              loading={this.state.simpanLoading}
                            >
                              Reset
                            </Button>
                          </Col>

                          <Col xs={12}>
                            <Button 
                              appearance="primary"
                              color="green"
                              onClick={this.simpanHandler}
                              loading={this.state.simpanLoading}
                              disabled={this.state.disabled}
                            >
                              Simpan
                            </Button>
                          </Col>
                          
                        </Row>
                      </Grid>
                    </div>
                    
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </div>

          

          {
            // loadPage
            
            // if(this.state.loadingPage) {
            // }
            // console.log("VVVV", this)
          }

          
        </div>
      </>
    );
  }
}

export default StudentPermissionForm;