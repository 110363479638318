import React, { Component } from 'react';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
// export const isAuthenticated = () => {
//   return true;
// };

class HalloPage extends Component {
  render() {
    return (
      <LayoutRsuite />
    );
  }
  // render() {
  //   return (
  //     <h1>Hallo Page</h1>
  //   );
  // }
}

export default HalloPage;