import React, { Component } from 'react';
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
// export const isAuthenticated = () => {
//   return true;
// };

class AppPage extends Component {
  render() {
    // console.log('window.localStorage',window.localStorage)
    return (
      <LayoutRsuite />
    );
  }
  // render() {
  //   console.log('window.localStorage',window.localStorage)
  //   return (
  //     <h1>App Page</h1>
  //   );
  // }
}

export default AppPage;