import React, { Component } from 'react'
// import configs from '../../configs/index'
import { 
  List,
  // Button,
  // Icon,
  // Grid,
  // Row,
  // Col,
  // Container, 
  // Sidebar, 
  // Header, 
  // Content, 
  // Footer, Sidenav , 
  // Nav, Icon, 
  // Dropdown, 
  // Navbar, 
  // IconButton, Avatar 
} from 'rsuite'
import './SubModuleList.css'
import { 
  // Redirect, 
  // Link 
} from "react-router-dom"
import ListCourseMaterials from '../ListCourseMaterials/ListCourseMaterials'

class SubModuleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoLink: "https://drive.google.com/file/d/1pzVOXgrgbjcQE5QD_AylivSC1cxw8lOI/preview",
      pdfLink: "https://drive.google.com/file/d/1uAaa4uenKDXldRI517fhjDgvtXsXUOnq/preview",
      // expand: true,
      // loadingSubmit: false

    }
    // this.handleToggle = this.handleToggle.bind(this);
    // console.log('props in layout rsuite', this.props)
    // this.openVideo = this.openVideo.bind(this);
    // this.openPdf = this.openPdf.bind(this);
  }

  

  // openVideo() {
  //   window.open(this.state.videoLink, "_blank")
  // }

  // openPdf() {
  //   window.open(this.state.pdfLink, "_blank")
  // }

  render() {
    // console.log("this.props.theData",this.props.theData)
    
    let child = (
      <List.Item>
        Tekan <i>"Lihat Materi"</i>
      </List.Item>
    )

    if(this.props.theData.length > 0) {
      child = this.props.theData.map((item,index) => 
        <List.Item
          key={item.id}
          index={index}
        >
          <div className="itemInList">

            <div>
              <b>Name :</b>
            </div>
            <div>
              <i>{item.name}</i>
            </div>
            <div>
              <b>Description :</b>
            </div>
            <div>
              <i>{item.description}</i>
            </div>
          </div>
          <div>
            <ListCourseMaterials 
              id={item.id} 
              dataFiles={item.course_materials} 
              index={index}
            />
          </div>
        {/*
          <div className="itemInList">
            <Button 
              color="blue" 
              size="xs"
              onClick={this.openVideo}
            >
              <Icon icon="logo-video"  /> Video
            </Button>
          </div>
          <div className="itemInList">
            <Button 
              color="blue" 
              size="xs"
              onClick={this.openPdf}
            >
              <Icon icon="file"  /> PDF
            </Button>
          </div>
        */}
        </List.Item>
      )
    }

    return (
      <>
        <List 
          bordered
          id={this.props.id}
          index={this.props.index}
        >
          {child}
        </List>
      </>
    )
  }
}

export default SubModuleList;
