import React, { Component } from 'react';
import configs from '../../configs/index'
import { 
  // Grid,
  // Row,
  // Col,
  Container, 
  // Sidebar, 
  Header, 
  // Content, 
  // Footer, Sidenav , 
  Nav, Icon, 
  // Dropdown, 
  Navbar, 
  // IconButton, Avatar 
} from 'rsuite';

import { 
  // Redirect, 
  // Link 
} from "react-router-dom";
// import auth from '../../services/Auth/index'

import './LayoutRsuite.css';
import axios from 'axios';

// import Home from '../../pages/Home/Home';
// import About from '../../pages/About/About';

import SideMenu from '../SideMenu/SideMenu'

// const headerStyles = {
//   // padding: 18,
//   paddingTop: 4,
//   fontSize: 16,
//   height: 56,
//   // background: '#4a148c',
//   background: 'white',
//   color: ' #fff',
//   whiteSpace: 'nowrap',
//   overflow: 'hidden'
// };

const appbarStyles = {
  background: '#4a148c',
}

// const appbarStylesLabel = {
//   color: 'white',
// }

// const iconStyles = {
//   width: 56,
//   height: 56,
//   lineHeight: '56px',
//   textAlign: 'center'
// };

// const bodyStyles = {
//   background: 'white',
// };

// const NavToggle = ({ expand, onChange }) => {
//   return (
//     <Navbar appearance="default" className="nav-toggle">
//       <Navbar.Body>
//         <Nav>
//           <Dropdown
//             placement="topStart"
//             trigger="click"
//             renderTitle={children => {
//               return <Icon style={iconStyles} icon="gear" />;
//             }}
//           >
//             <Dropdown.Item>Help</Dropdown.Item>
//             <Dropdown.Item>Settings</Dropdown.Item>
//             <Dropdown.Item>Sign out</Dropdown.Item>
//           </Dropdown>
//         </Nav>

//         <Nav pullRight>
//           <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
//             <Icon icon={expand ? 'angle-left' : 'angle-right'} />
//           </Nav.Item>
//         </Nav>
//       </Navbar.Body>
//     </Navbar>
//   );
// };

class LayoutRsuite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
      loadingSubmit: false

    };
    // this.handleToggle = this.handleToggle.bind(this);
    // console.log('props in layout rsuite', this.props)
  }
  handleLogout = () => {
    // console.log('logout')
    // auth.logout();
    // window.location.reload(true)
    // return <Redirect to='/login' />
    
    this.setState({ loadingSubmit : true })
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;
    axios.post(configs.baseEndpoint.baseURL+'api/logout', {}, {
      headers: { 
        'Authorization': tok ,
        'Accept': 'application/json'
      }
    })
      .then( response => {
        console.log("response",response)
        this.setState({ loadingSubmit : false })
        window.localStorage.clear()
        window.location.reload(true)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        this.setState({ loadingSubmit : false })
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
  }
  // handleToggle() {
  //   this.setState({
  //     expand: !this.state.expand
  //   });
  // }
  render() {
    // console.log('props in layout rsuite', this.props)
    // console.log('this.state',this.state);
    // const { expand } = this.state;
    // const user_role = localStorage.getItem("role")
    return (
      <div>
        {/*
        */}
        <Container className="">
            <SideMenu role={this.props.role}/>
          {/*
            <Sidebar
              className="ff"
              style={{ display: 'flex', flexDirection: 'column' }}
              width={expand ? 260 : 56}
              collapsible
            >
              <Sidenav.Header>
                <div style={headerStyles}>
                  <img src="https://amithyainstitute.com/wp-content/uploads/2020/01/amithya-institute-logo-1024x263.png" width="180"/>
                </div>
              </Sidenav.Header>
              <Sidenav
                className="gg"
                expanded={expand}
                appearance="subtle"
              >
                <Sidenav.Body style={bodyStyles}>
                  <Nav>
                    <Nav.Item eventKey="1"  className="labelSideMenu">
                      {user_role}
                    </Nav.Item>
                    <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} className="labelSideMenu" componentClass={Link} to="/">
                      Home
                    </Nav.Item>

                    <Nav.Item eventKey="2" icon={<Icon icon="group" />} className="labelSideMenu" componentClass={Link} to="/about">
                      About
                    </Nav.Item>

                    <Nav.Item eventKey="2" icon={<Icon icon="group" />} className="labelSideMenu">
                      Members
                    </Nav.Item>
                    
                    <Dropdown
                      eventKey="3"
                      trigger="hover"
                      title="Advanced"
                      icon={<Icon icon="magic" />}
                      placement="rightStart"
                    >
                      <Dropdown.Item eventKey="3-1">Geo</Dropdown.Item>
                      <Dropdown.Item eventKey="3-2">Devices</Dropdown.Item>
                    </Dropdown>
                    <Nav.Item eventKey="5" icon={<Icon icon="dashboard" />} className="labelSideMenu">
                      Github
                    </Nav.Item>
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
              <NavToggle expand={expand} onChange={this.handleToggle} style={bodyStyles}/>
            </Sidebar>
          */}

            <Container>
              <Header>
                <Navbar style={appbarStyles} >
                  <Navbar.Body >
                    <Nav pullRight className="appbar">
                      {/*
                      <Nav.Item 
                      >
                        {this.props.role}
                      </Nav.Item>
                      */}
                      <Nav.Item 
                        loading={this.state.loadingLogout}
                        icon={<Icon icon="off" size="lg"/>}
                        onClick={() => this.handleLogout()}
                      >
                      </Nav.Item>
                      {/* <Nav.Item icon={<Icon icon="cog" />} >Settings</Nav.Item> */}
                      
                    </Nav>
                  </Navbar.Body>
                </Navbar>
              </Header>

            {/*
              <Content>
                <div className="theMainContent">
                  <Grid fluid>
                    <Row className="show-grid">
                      <Col xs={24}>{user_role}</Col>
                    </Row>
                  </Grid>
                </div>
              </Content>
            */}


            </Container>
        </Container>
      </div>
    );
  }
}

export default LayoutRsuite;