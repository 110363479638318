import React, {Component} from 'react'
import configs from '../../configs/index'
import LayoutRsuite from '../../hoc/LayoutRsuite/LayoutRsuite';
import './Modules.css'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  Redirect,
  // useHistory,
  // useLocation
} from "react-router-dom"
import { 
  Panel,
  Button,
  Icon,
  Grid,
  Row,
  Col,
  Modal,
  Input,
  SelectPicker,
  ButtonToolbar,
  // Pagination,
  Breadcrumb,
  Alert,
} from 'rsuite';
import axios from 'axios';
import forEach from "lodash/forEach"
import modules from '../../services/Modules/index'

class Modules extends Component{
  constructor(props) {
    super(props)
    this.state = {
      // role: null,
      show: false,
      modules: [],
      modulesDataPicker: [],
      valueModules: "",
      
      coursesData: [],
      valueCourses: "",

      coursesSelectedData: [],

      coursesSelected: null,
      modulesSelected: null,

      // paramModules: {},
      paramModules: {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
      },
      actionItem: "new",

      editId: null,
      valueCourseSelected: null,
      valueName: "",
      valueDescription: "",

      loadingSimpan: false,

    }
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.searchFilter = this.searchFilter.bind(this);

    this.newModules = this.newModules.bind(this);

    this.simpanHandler = this.simpanHandler.bind(this);

    this.coursesChange = this.coursesChange.bind(this);
    this.coursesSearch = this.coursesSearch.bind(this);
    this.coursesSelect = this.coursesSelect.bind(this);

    this.modulesChange = this.modulesChange.bind(this);
    this.modulesSearch = this.modulesSearch.bind(this);
    this.modulesSelect = this.modulesSelect.bind(this);
    this.filterModulesClean = this.filterModulesClean.bind(this);

    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleCoursesSelected = this.handleCoursesSelected.bind(this);

    this.coursesSelectedChange = this.coursesSelectedChange.bind(this);
    this.coursesSelectedSearch = this.coursesSelectedSearch.bind(this);
    this.coursesSelectedSelect = this.coursesSelectedSelect.bind(this);
    this.filterCoursesClean = this.filterCoursesClean.bind(this);

    this.resetFilter = this.resetFilter.bind(this);
  }

  async componentDidMount() {
    // this._isMounted = true;
    // let yuu = await this.getTheRole();

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // check role
    axios.get(configs.baseEndpoint.baseURL+'api/me?include=roles', {
      headers: { 'Authorization': tok }
    })
      .then( response => {
        // console.log("response",response)

        const role_id = response.data.data.relationships.roles.id
        // console.log("role_id",role_id)
        // response.data.include
        const role_name = response.data.included.roles[role_id].attributes.name
        window.localStorage.setItem('role', role_name)
        
        // console.log("response",response)
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
    
    // try {
    //   this.getModules()
    // }
    // catch(error) {
    //   console.log("try catch error",error)
    // }

    // if (this._isMounted) {
      
    // }
    // await this.SchedulesServices.getSchedules()
    // let yuu = await getRole();
    // if (this._isMounted) {
    //   this.setState( { role: yuu } );
    //   // this.setState( { role: 'student' } );
    // }

    // const user_role = localStorage.getItem("role")
    // console.log('user_role',user_role)
    // if(user_role !== "superadmin") {
    //   return <Redirect to='/dashboard'/>;
    // }

    // await this.getUsers();
    // await this.getRoles();
    // await this.delUsers();
    this.getModules(this.state.paramModules)
  }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  // harus memasukkan object params
  getModules(params) {
    // console.log("getModules params",params)

    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    // get modules
    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: params
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrModules = []
        forEach(response.data.data, item => {
          let theDescription = item.attributes.description
          if(theDescription === null) {
            theDescription = "-"
          }

          let modules = {
            "id": item.attributes.id,
            "name": item.attributes.name,
            "description": theDescription, 
          }
          arrModules.push(modules)
        })
        this.setState({ modules : arrModules })
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
  }

  open(size) {
    // console.log("this.state.valueCourses",this.state.valueCourses)
    this.setState({
      size,
      show: true,
    });
  }

  close() {
    this.setState({
      show: false,
      valueCourseSelected: null,
      valueName: "",
      valueDescription: ""
    });
  }

  resetFilter() {
    this.setState({ 
      paramModules : {
        "page[num]": 1,
        "page[limit]": 10,
        "sort": "-created_at",
      },
      coursesSelected: null,
      modulesSelected: null,
      modulesDataPicker: [],
      coursesData: [],
    })
  }

  searchFilter() {
    // console.log("this.state.coursesSelected",this.state.coursesSelected)
    // console.log("this.state.modulesSelected",this.state.modulesSelected)
    let params = {
      // "page[num]": page,
      // "page[limit]": this.state.limitUserPagination,
      "page[num]": 1,
      "page[limit]": 10,
      "sort": "-created_at",
    }
    
    if(this.state.coursesSelected !== null) {
      params["filter[course_id][is]"] = this.state.coursesSelected
    }

    if(this.state.modulesSelected !== null) {
      params["filter[id][is]"] = this.state.modulesSelected
    }
    
    // console.log("searchFilter params",params)
    this.setState({ paramModules : params })

    this.getModules(params)
    // this.getModules(this.state.paramModules)
  }

  modulesSelect(value) {
    this.setState({ modulesSelected : value })
  }

  filterModulesClean() {
    this.setState({ 
      modulesSelected: null,
      modulesDataPicker: [],
      // modulesSelected: null,
    })
  }

  modulesChange(value) {
    console.log("modulesChange",value)
  }

  // only for search inside select
  modulesSearch(value) {
    
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    let params = {
      "page[num]": 1,
      // "page[num]": page,
      // "page[limit]": this.state.limitUserPagination,
      "page[limit]": 10,
      "sort": "-created_at",
      "filter[name][like]": value,
    }

    // console.log("this.state.coursesSelected",this.state.coursesSelected)
    // console.log("this.state.coursesSelected.length",this.state.coursesSelected.length)
    // console.log("this.state.coursesSelected.length > 0",this.state.coursesSelected.length > 0)
    if(this.state.coursesSelected !== null) {
      console.log("masuk if")
      params["filter[course_id][is]"] = this.state.coursesSelected
    }

    // console.log("params",params)

    axios.get(configs.baseEndpoint.baseURL+'api/modules', {
      headers: { 'Authorization': tok },
      params: params
      // params: {
      //     "page[num]": 1,
      //     // "page[num]": page,
      //     // "page[limit]": this.state.limitUserPagination,
      //     "page[limit]": 10,
      //     "sort": "-created_at",
      //     "filter[name][like]": value,
      //     "filter[course_id][is]": this.state.coursesSelected,
      //   // include: "modules"
      // }
    })
      .then( response => {

        console.log("response",response)
        // let arrItem = []
        // const theItem = {
        //   "label": item.attributes.name,
        //   "value": item.attributes.id,
        // }
        // arrItem.push(theItem)

        let arrModules = []
        forEach(response.data.data, item => {
          let modules = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrModules.push(modules)
        })
        this.setState({ modulesDataPicker : arrModules })
        // response.data.data.attributes.name
        // this.setState({ user : response.data.data.attributes })
        // this.setState({ error : false })
        // this.setState({ loadingPage : false })
        // this.setState({ validToken : true })
      } )
      .catch( error => {
        console.log("error",error)
        // console.log('error',error.response.data)
        // console.log('error',error.response.status)
        // console.log('error',error.response.headers)
        // this.setState({ user : {} })
        // this.setState({ error : true })
        // this.setState({ loadingPage : true })
        // this.setState({ validToken : false })
        // if(error.response.status === 401) {
        //   Auth.logout();
        //   this.props.history.push('/login');
        // }
      });
  }

  coursesSelect(value) {
    // console.log("coursesSelect",value)
    this.setState({ coursesSelected : value })
    this.setState({ modulesSelected : null })
    this.setState({ modulesDataPicker : [] })
  }

  filterCoursesClean() {
    // console.log("filterCoursesClean")
    this.setState({ 
      coursesSelected : null,
      coursesData : []
    })
  }

  coursesChange(value) {
    // // this.setState({ valueCourses : value })
    // console.log("coursesChange",value)
    // this.setState({ coursesSelected : value })    
  }

  // only for search inside select
  coursesSearch(value) {
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/courses', {
      headers: { 'Authorization': tok },
      params: {
          "page[num]": 1,
          // "page[num]": page,
          // "page[limit]": this.state.limitUserPagination,
          "page[limit]": 10,
          "sort": "-created_at",
          "filter[name][like]": value,
        // include: "modules"
      }
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrItem = []
        forEach(response.data.data, item => {
          const theItem = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrItem.push(theItem)
          // console.log("item",item)
        })
        this.setState({ coursesData : arrItem })
      } )
      .catch( error => {
        console.log("error",error)
      });
  }

  // dateChange(value) {
  //   this.setState({ valueDate : value }) 
  // }
  
  async handleEdit(data) {
    // console.log("handleEdit",data)
    this.setState({actionItem: "edit"})

    const opts = {
      params : {
        "include": "courses"
      }
    }
    let respOne = await modules.getOneModules(data.id, opts)
    // console.log("respOne",respOne)

    // Courses
    let arrCourse = []
    let course_id = respOne.data.data.relationships.courses[0].id
    // console.log("course_id",course_id)
    let course_data = respOne.data.included.courses[course_id].attributes
    // console.log("course_data",course_data)
    let setCourse = {
      "label": course_data.name,
      "value": course_data.id,
    }
    arrCourse.push(setCourse)

    // console.log("arrCourse",arrCourse)

    this.setState({ 
      editId: data.id,
      coursesSelectedData: arrCourse,
      valueCourseSelected: respOne.data.data.attributes.course_id,
      valueName: respOne.data.data.attributes.name,
      valueDescription: respOne.data.data.attributes.description, 
    })

    this.open("xs")
  }

  async handleDelete(data) {
    console.log("handleDelete",data)
    let delResp = await modules.deleteModules(data.id)
    console.log("delResp",delResp)
    if(delResp.data.success) {
      Alert.success('Edit Berhasil.', 5000)
      this.getModules(this.state.paramModules)
    }
    // this.close()
    // this.setState({ paramModules : params })
    // console.log("this.state.paramModules", this.state.paramModules)

  }

  newModules() {
    this.setState({actionItem: "new"})
    this.open("xs")
  }

  coursesSelectedSelect(value) {
    // console.log("coursesSelectedSelect",value)
    this.setState({ valueCourseSelected : value })
  }

  coursesSelectedChange(value) {
    // console.log("coursesSelectedChange",value)
    this.setState({ valueCourseSelected : value })
  }

  coursesSelectedSearch(value) {
    // console.log("coursesSelectedSearch",value)
    const ac_tok = localStorage.getItem("access_token");
    const tok = "Bearer "+ac_tok;

    axios.get(configs.baseEndpoint.baseURL+'api/courses', {
      headers: { 'Authorization': tok },
      params: {
          "page[num]": 1,
          "page[limit]": 10,
          "sort": "-created_at",
          "filter[name][like]": value,
      }
    })
      .then( response => {
        // console.log("response.data.data",response.data.data)
        let arrItem = []
        forEach(response.data.data, item => {
          const theItem = {
            "label": item.attributes.name,
            "value": item.attributes.id,
          }
          arrItem.push(theItem)
          // console.log("item",item)
        })
        this.setState({ coursesSelectedData : arrItem })
      } )
      .catch( error => {
        console.log("error",error)
      });
  }

  handleCoursesSelected(value) {
    // console.log("handleName",value)
    this.setState({valueName: value})
  }

  handleName(value) {
    // console.log("handleName",value)
    this.setState({valueName: value})
  }

  handleDescription(value) {
    // console.log("handleDescription",value)
    this.setState({valueDescription: value})
  }

  async simpanHandler() {
    this.setState({loadingSimpan: true})
    if(this.state.actionItem === "new") {
      console.log("new")
      // course_id
      // name
      // description
      const payload = {
        "course_id": this.state.valueCourseSelected,
        "name": this.state.valueName,
        "description": this.state.valueDescription
      }

      console.log("payload",payload)

      let responseCreate = await modules.createModules(payload)
      console.log("responseCreate",responseCreate)
      if(responseCreate.data.success) {
        Alert.success('Tambah Berhasil.', 5000)
        this.close()
        // this.setState({ paramModules : params })
        console.log("this.state.paramModules", this.state.paramModules)
        this.getModules(this.state.paramModules)
      }
      
    } else {
      console.log("edit id", this.state.editId)
      // course_id
      // name
      // description
      const payload = {
        "course_id": this.state.valueCourseSelected,
        "name": this.state.valueName,
        "description": this.state.valueDescription
      }

      console.log("payload",payload)
      let responseUpdate = await modules.updateModules(this.state.editId, payload)
      console.log("responseUpdate",responseUpdate)
      if(responseUpdate.data.success) {
        Alert.success('Edit Berhasil.', 5000)
        this.close()
        // this.setState({ paramModules : params })
        console.log("this.state.paramModules", this.state.paramModules)
        this.getModules(this.state.paramModules)
      }
      
    }
    this.setState({loadingSimpan: false})
  }

  render () {
    // console.log(this.props)
    // console.log(this.state.modulesDataPicker)
    // console.log("this.state.coursesSelectedData",this.state.coursesSelectedData)
    // console.log("this.state.valueCourseSelected",this.state.valueCourseSelected)
    
    const path = this.props.location.pathname.replace('/','')
    const user_role = localStorage.getItem("role")
    // console.log('this.state.activePage',this.state.activePage)

    if(user_role !== "superadmin") {
      // console.log('www redirect')
      return <Redirect to='/dashboard'/>;
    }

    let action = "Edit"
    if(this.state.actionItem === "new") {
      action = "Tambah"
    }

    let moduleItems = (
      <Col xs={24}>
        <div className="myCard">
          <Panel shaded >
            <Panel>
              <Grid fluid>
                <Row>
                  <Col xs={24} >
                    <h5>No data</h5>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Panel>
        </div>
      </Col>
    )

    if(this.state.modules.length > 0) {
      // console.log("modules ada isi nya",this.state.modules)

      moduleItems = this.state.modules.map((theModule) => 
        <Col xs={24} id={`module_id_${theModule.id}`} key={theModule.id}>
          <div className="myCard">
            <Panel shaded >
              <Panel 
                header={theModule.name} 
                collapsible 
                defaultExpanded={false}
              >
                <Grid fluid>
                  <Row>
                    <Col xs={24} >
                      <div>
                        <p>
                          <b>Id : </b><small>{theModule.id}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Name : </b><small>{theModule.name}</small>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Deskripsi : </b><small>{theModule.description}</small>
                        </p>
                      </div>
                      <div>
                        <ButtonToolbar>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleEdit(theModule)}}
                          >
                            <Icon icon="sign-in" /> Edit
                          </Button>
                          <Button 
                            color="violet" 
                            appearance='primary' 
                            block
                            onClick={() => {this.handleDelete(theModule)}}
                          >
                            <Icon icon="sign-in" /> Delete
                          </Button>
                        </ButtonToolbar>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel>  
            </Panel>
          </div>
        </Col>
      )
    }

    return (
      <>
        <LayoutRsuite />
        <div className="modulesContainer">
          <div className="container">
            <div className="jumbotron">
              <Breadcrumb>
                <Breadcrumb.Item>{user_role}</Breadcrumb.Item>
                <Breadcrumb.Item active>{path}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="jumbotron">

              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Action" collapsible bordered defaultExpanded={false}>
                      <Button color="green" >
                        <Icon 
                          icon="user-plus"
                          // onClick={() => {console.log('Add')}}
                          // onClick={() => this.open('xs')}
                          onClick={() => this.newModules('xs')}
                        />
                      </Button>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Grid fluid>
                <Row className="">
                  <Col xs={24}>
                    <Panel header="Filter" collapsible bordered defaultExpanded={true}>
                      <Grid fluid>
                        <Row className="">
                          <Col xs={16}>
                            <SelectPicker
                              // loading={this.state.loadingSimpan}
                              onClean={this.filterCoursesClean}
                              onSelect={(value) => {this.coursesSelect(value)}}
                              onChange={(value) => {this.coursesChange(value)}}
                              onSearch={(value) => {this.coursesSearch(value)}}
                              data={this.state.coursesData}
                              style={{ width: 224, marginBottom: 10 }}
                              placeholder="Courses"
                              value={this.state.coursesSelected}
                            />
                          </Col>
                          <Col xs={16}>
                            <SelectPicker
                              onClean={this.filterModulesClean}
                              onSelect={(value) => {this.modulesSelect(value)}}
                              onChange={(value) => {this.modulesChange(value)}}
                              onSearch={(value) => {this.modulesSearch(value)}}
                              data={this.state.modulesDataPicker}
                              style={{ width: 224, marginBottom: 10 }}
                              placeholder="Modules"
                              value={this.state.modulesSelected}
                            />
                          </Col>
                          <Col xs={16}>
                            <Button 
                              color="blue"
                              appearance="primary" 
                              size="xs"
                              onClick={this.searchFilter}
                              style={{ marginBottom: 10 }}
                            >
                              Search
                            </Button>
                          </Col>
                          <Col xs={16}>
                            <Button
                              color="green" 
                              appearance="primary" 
                              size="xs"
                              onClick={this.resetFilter}
                              style={{ marginBottom: 10 }}
                            >
                              Reset
                            </Button>
                          </Col>
                          
                          {/*
                          <Col xs={16}>
                            <Input 
                              onChange={this.handleSearchDesc}
                              style={{ marginBottom: 10 }} 
                              size="md" 
                              placeholder="Search ..."
                              value={this.state.searchDesc} 
                            />
                          </Col>
                          <Col xs={4}>
                            <IconButton
                              appearance="ghost"
                              color="violet"
                              icon={
                                <Icon
                                  className="fill-color" 
                                  icon="search" 
                                />
                              } 
                              placement="left" 
                              size="xs"
                              onClick={this.submitSearch}
                            >
                            </IconButton>
                          </Col>
                          <Col xs={4}>
                            <IconButton
                              appearance="ghost"
                              color="violet"
                              icon={
                                <Icon
                                  className="fill-color" 
                                  icon="eraser" 
                                />
                              } 
                              placement="left" 
                              size="xs"
                              onClick={this.resetSearch}
                            >
                            </IconButton>
                          </Col>
                          */}
                        </Row>
                      </Grid>
                    </Panel>
                  </Col>
                </Row>
              </Grid>

              <Modal size={this.state.size} show={this.state.show} onHide={this.close}>
                <Modal.Header>
                  <Modal.Title>{action} Modules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Grid fluid>
                    <Row className="">
                      {/*
                      courses_id
                      modules_id
                      periode_id
                      order_of_meeting
                      description
                      date_of_meeting
                      */}
                      {/*
                      <Input value={this.state.valueDate} onChange={this.handleDate} style={{ marginBottom: 10 }} size="md" placeholder="Tanggal" />
                      */}
                     {/*
                      <Col xs={18}>
                        <DatePicker
                          value={this.state.valueDate}
                          onChange={(value) => {this.dateChange(value)}}
                          style={{ marginBottom: 10 }}
                          format="YYYY-MM-DD HH:mm:ss"
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date()
                            }
                          ]}
                        />

                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.modulesChange(value)}}
                          onSearch={(value) => {this.modulesSearch(value)}}
                          data={this.state.modulesData}
                          value={this.state.valueModules}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Modules"
                        />
                      </Col>
                      <Col xs={18}>
                        <SelectPicker
                          onChange={(value) => {this.periodesChange(value)}}
                          onSearch={(value) => {this.periodesSearch(value)}}
                          data={this.state.periodesData}
                          value={this.state.valuePeriodes}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Periodes"
                        />
                      </Col>
                     */}
                      <Col xs={18}>
                        <SelectPicker
                          onSelect={(value) => {this.coursesSelectedSelect(value)}}
                          onChange={(value) => {this.coursesSelectedChange(value)}}
                          onSearch={(value) => {this.coursesSelectedSearch(value)}}
                          data={this.state.coursesSelectedData}
                          value={this.state.valueCourseSelected}
                          style={{ width: 224, marginBottom: 10 }}
                          placeholder="Courses"
                        />
                      </Col>
                      <Col xs={18}>
                        <Input 
                          value={this.state.valueName} 
                          onChange={this.handleName} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Nama Modul" 
                        />
                      </Col>

                      <Col xs={18}>
                        <Input 
                          value={this.state.valueDescription} 
                          onChange={this.handleDescription} 
                          style={{ marginBottom: 10 }} 
                          size="md" 
                          placeholder="Deskripsi" 
                        />
                      </Col>
                    </Row>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  <Button 
                    onClick={this.simpanHandler}
                    color="violet"
                    loading={this.state.loadingSimpan}
                   >
                    Simpan
                  </Button>
                  <Button onClick={this.close} appearance="subtle">
                    Batal
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>

            <div>
              <Grid fluid>
                <Row>
                  {moduleItems}
                </Row>
              </Grid>
            </div>

          </div>
        </div>
      </>
    );
  } 
}

export default Modules;

